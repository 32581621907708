import React, { useEffect, useContext, useState, useRef } from "react";
import * as Const from "../../_Const";
import BlackQuote from "../../Components/BlackQuote";
import * as MS from "../../Services/MenuService";
import * as RS from "../../Services/RenderService";
import * as VS from "../../Services/ValidatorService";
import { AppContext } from "../../Context/AppContext";
import * as InsuranceService from "../../Services/data/InsuranceService";
import SectionInfo from "../../Components/SectionInfo";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      setionInfoChildRef.current.save(false);
      await InsuranceService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_INSURANCES);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Zdravotní pojišťovny";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function validateChildComponent() {
  return setionInfoChildRef.current.validate();
}

function validateFormItem() {
  return true;
}

function selectedHandler(item, index) {
  const list = [...data.insurances];
  list[index].isSelected = !list[index].isSelected;
  setData({ ...data, insurances: list });
  setIsEditedData(true);
}

async function populateData(id) {
  let { data: item } = await InsuranceService.getItem(parseInt(id));
  item = getViewFromModel(item.insurances);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      tenantId: appContext.tenantId,
      insurances: item,
      errors: {},
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      insurances: [],
      errors: {},
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    tenantId: data.tenantId,
    insurances: data.insurances,
  };
  return vm;
}

function setIconForInsurance(item) {
  let image = null;
  if (item && item.image) {
    image = `${basePath}${item.image}`;
    // if (!item.isSelected) {
    //   image = `${basePath}${item.imageAlt}`;
    // } else {
    //   image = `${basePath}${item.image}`;
    // }
  }
  return image;
}

function getTooltipTitle(item) {
  let title = null;
  if (item) {
    title = `${item.name} (${item.code})`;
  }
  return title;
}

function getImageClass(item) {
  let css = "noselect insurance-logo";
  if (item && !item.isSelected) {
    css = "readonly-item noselect insurance-logo";
  }
  return css;
}
function getTextClass(item) {
  let css = "font-weight-bold noselect";
  if (item && !item.isSelected) {
    css = "font-weight-bold readonly-item noselect";
  }
  return css;
}

const basePath = "/assets/img/pojistovny/";
let [appContext, setAppContext] = [2];
let [data, setData] = [2];
let [isEdited, setIsEditedData] = [2];

let setionInfoChildRef;
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageInsuranceIndex() {
  setionInfoChildRef = useRef();
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  [data, setData] = useState(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  if (!data) {
    return null;
  }

  let title = "Zdravotní pojišťovny";

  return (
    <React.Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        Informujte své pacienty o <b>Zdravotních pojišťovnách</b> se kterými máte uzavřen smluvní vztah
      </BlackQuote>

      <SectionInfo
        ref={setionInfoChildRef}
        sectionCode={Const.ID_INSURANCES}
        title={title}
        showEditor={true}
        onDataChanged={() => setIsEditedData(true)}
        onDataLoaded={() => setIsEditedData(false)}
        onSectionEnabledChanged={(isDisabled) => setIsSectionDisabled(isDisabled)}
      />

      <div className="row mt-4">
        <div className="col-lg-8 col-md-12">
          <form onSubmit={submitHandler} noValidate>
            <div>
              {RS.renderTitle("Zdravotní pojišťovny")}
              {RS.renderHint("Kliknutím na logo pojišťovny vyberte Zdravotní pojišťovny se kterými máte smluvní vztah")}
            </div>
            <div className={RS.renderDisabledCss("row", isSectionDisabled)}>
              {data.insurances.map((item, i) => (
                <div className="col-md-12 ml-4">
                  <div className="row mx-1 my-1" key={item.code}>
                    <Card className="btn insurance-card row align-items-center" onClick={() => selectedHandler(item, i)}>
                      <CardContent className="col-12 mt-n3">
                        <div data-toggle="tooltip" data-placement="top" title={getTooltipTitle(item)}>
                          <div className="row">
                            <div className="pl-0">
                              <RS.PrimaryCheckbox color="primary" checked={item.isSelected} />
                            </div>
                            <div className="my-auto">
                              <img className={getImageClass(item)} src={setIconForInsurance(item)} />
                            </div>
                            <div className="col-8 pl-3 my-auto">
                              <div className={getTextClass(item)}>
                                {item.name} ({item.code})
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              ))}
            </div>

            <div className="ml-n3 mt-4 mb-4">{RS.renderSubmit(!isEdited)}</div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageInsuranceIndex;
