import React, { useState, createContext } from "react";
import * as Const from "../_Const";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [appContext, setAppContext] = useState({
    isToggleOpen: false,
    activeId: Const.ID_ACCOUNT_PROFILE,
    activeSubId: "",
    tenantId: 0,
    loggedUser: "",
    loggedUserInitial: "",
    isAdmin: false,
  });

  return <AppContext.Provider value={[appContext, setAppContext]}>{props.children}</AppContext.Provider>;
};
