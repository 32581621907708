import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBIcon } from "mdbreact";

function PageOther() {
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol md="12">
          {/* <MDBCard>
            <MDBCardBody> */}
          <form>
            <p className="h4 text-center py-4">Sign up</p>
            <div className="grey-text">
              {/* <MDBInput label="Your name" icon="user" group type="text" validate error="wrong" success="right" />
              <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong" success="right" />
              <MDBInput label="Confirm your email" icon="exclamation-triangle" group type="text" validate error="wrong"  success="right" outline />
              <MDBInput label="Your password" icon="lock" iconSize="8x" iconClass="red-text" group type="password" validate /> */}
            </div>
            <div className="text-center py-4 mt-3">
              <MDBBtn color="cyan" type="submit">
                Register
              </MDBBtn>
            </div>
          </form>
          {/* </MDBCardBody>
          </MDBCard> */}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default PageOther;
