import React, { useEffect, useContext, useState, useRef } from "react";
import BlackQuote from "../../Components/BlackQuote";
import * as RS from "../../Services/RenderService";
import * as VS from "../../Services/ValidatorService";
import * as MS from "../../Services/MenuService";
import { AppContext } from "../../Context/AppContext";
import * as ExtInfoService from "../../Services/data/ExtInfoService";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as Const from "../../_Const";
import SectionInfo from "../../Components/SectionInfo";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      setionInfoChildRef.current.save(false);
      await ExtInfoService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_EXT_INFO);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Vybavenost čekárny";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function validateChildComponent() {
  return setionInfoChildRef.current.validate();
}

function validateFormItem() {
  return true;
}

function selectedHandler(item) {
  const list = [...data.extInfo];
  const index = list.indexOf(item);

  list[index].isSelected = !list[index].isSelected;
  setData({ ...data, extInfo: list });
  setIsEditedData(true);
}

async function populateData(id) {
  let { data: item } = await ExtInfoService.getItem(parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      tenantId: appContext.tenantId,
      extInfo: item.extInfo,
      extInfoCategory: item.extInfoCategory,
      errors: {},
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      extInfo: [],
      extInfoCategory: [],
      errors: {},
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    tenantId: data.tenantId,
    extInfo: data.extInfo,
  };
  return vm;
}

function setIconFor(item) {
  let image = null;
  if (item && item.image) {
    image = `${basePath}ic_f_v2_${item.image}${sufix}`;
  }
  return image;
}

function getTooltipTitle(item) {
  let title = null;
  if (item) {
    title = item.text;
    //title = `${item.name} (${item.code})`;
  }
  return title;
}

function getImageClass(item) {
  let css = "noselect insurance-logo";
  if (item && !item.isSelected) {
    css = "readonly-item noselect insurance-logo";
  }
  return css;
}
function getTextClass(item) {
  let css = "font-weight-bold noselect";
  if (item && !item.isSelected) {
    css = "font-weight-bold readonly-item noselect";
  }
  return css;
}

function hasCategory(id) {
  const items = data.extInfo;
  const categoryData = ExtInfoService.getExtInfoByCategoryId(id, items);

  if (!items || !categoryData) {
    return false;
  }
  const dataItems = categoryData.filter((n) => items.some((n2) => n.id === n2.id));

  if (!dataItems || (dataItems && dataItems.length == 0)) {
    return false;
  } else {
    return true;
  }
}

function renderCategory(id) {
  const items = data.extInfo;
  const categoryData = ExtInfoService.getExtInfoByCategoryId(id, items);
  const dataItems = categoryData.filter((n) => items.some((n2) => n.id === n2.id));

  if (!dataItems) {
    return null;
  }
  let iconSize = 55;

  return dataItems.map((item, i) => (
    <div className="col-12">
      <div className="row mx-1" key={item.code}>
        <Card className="btn ext-info-card row align-items-center" onClick={() => selectedHandler(item, i)}>
          <CardContent className="col-12 mt-n3">
            <div data-toggle="tooltip" data-placement="top" title={getTooltipTitle(item)}>
              <div className="row">
                <div className="pl-0">
                  <RS.PrimaryCheckbox color="primary" checked={item.isSelected} />
                </div>
                <div className="my-auto">
                  <img className={getImageClass(item)} src={setIconFor(item)} width={iconSize} />
                </div>
                <div className="col-8 pl-3 my-auto">
                  <div className={getTextClass(item)}>{item.text}</div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  ));
}

function renderCategoryContainer(item) {
  if (!item) {
    return null;
  }

  let render = null;
  const hasCat = hasCategory(item.id);

  if (hasCat) {
    render = (
      <div key={item.code} className="col-12">
        {RS.renderTitle(item.text, "pt-4")}
        <ul key={item.code} className="list-unstyled row justify-content-center ml-4">
          {renderCategory(item["id"])}
        </ul>
      </div>
    );
  }

  return render;
}

function getDesc(item) {
  let desc = null;
  if (item) {
    desc = item.desc;
  }
  return desc;
}

const basePath = "/assets/img/futures/v2/";
const sufix = ".png";

let [appContext, setAppContext] = [2];
let [data, setData] = [2];
let [category, setCategory] = [2];
let [isEdited, setIsEditedData] = [2];

let setionInfoChildRef;
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageInsuranceIndex() {
  setionInfoChildRef = useRef();
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  [data, setData] = useState(null);
  [category, setCategory] = useState(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setCategory(result.extInfoCategory);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  if (!data || !category) {
    return null;
  }

  let title = "Vybavenost čekárny";

  return (
    <React.Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        Informujte své pacienty o <b>možnostech, dostupnosti a vybavenosti Vaší čekárny a ordinace</b>
      </BlackQuote>

      <SectionInfo
        ref={setionInfoChildRef}
        sectionCode={Const.ID_EXT_INFO}
        title={title}
        showEditor={true}
        onDataChanged={() => setIsEditedData(true)}
        onDataLoaded={() => setIsEditedData(false)}
        onSectionEnabledChanged={(isDisabled) => setIsSectionDisabled(isDisabled)}
      />

      <div className="row mt-4">
        <div className="col-lg-8 col-md-12">
          <form onSubmit={submitHandler} noValidate>
            <div>
              {RS.renderTitle("Vybavenost a možnosti čekárny")}
              {RS.renderHint(
                "Kliknutím na ikonu vyberte položku, která bude informovat Vaše pacienty o možnostech, dostupnosti a vybavenosti Vaší čekárny a ordinace"
              )}
            </div>
            <div className={RS.renderDisabledCss("row", isSectionDisabled)}>
              <div className="justify-content-center mx-1 mt-n2">{category.map((item) => renderCategoryContainer(item))}</div>
            </div>

            <div className="ml-n3 mt-4 mb-4">{RS.renderSubmit(!isEdited)}</div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageInsuranceIndex;
