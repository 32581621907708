import http from "../HttpService";
/*
const data = [
  {
    id: 1,
    tenantId: 6,
    question: "Jak dlouhá je čekací doba na vyšetření?",
    answer: "Čekací doba je v řádů několika týdnů.",
  },
  {
    id: 2,
    tenantId: 6,
    question: "Nullam lectus justo, vulputate eget mollis sed, tempor sed magna?",
    answer:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam egestas wisi a erat. Aliquam erat volutpat. Etiam dictum tincidunt diam. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Phasellus et lorem id felis nonummy placerat. Maecenas lorem. Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Nulla non lectus sed nisl molestie malesuada. Vestibulum fermentum tortor id mi. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam erat volutpat. Suspendisse sagittis ultrices augue. Nullam eget nisl. Aliquam erat volutpat.",
  },
  {
    id: 3,
    tenantId: 6,
    question: "Duis sapien nunc, commodo et, interdum suscipit?",
    answer:
      "Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Fusce suscipit libero eget elit. Aenean vel massa quis mauris vehicula lacinia. Nulla est. Nulla quis diam. Vivamus ac leo pretium faucibus. Nulla est. Integer imperdiet lectus quis justo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
  },
];*/

const controller = "/faq/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  if (item.id) {
    console.log("save faq put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save faq post ", item);
    return http.post(controller, item);
  }
} /*

export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}*/
