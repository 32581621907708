import http from "../HttpService";

/*
const data = [
  {
    id: 1,
    guid: "121eb464a5794f588ff7084047ee5ba1",
    titleBefore: "MUDr",
    firstName: "Kamil",
    surname: "Pacholek",
    titleAfter: "csc",
    email: "decin@ultimedion.cz",
    phone: "602885774",
    street: "28. října",
    city: "Děčín",
    zip: "40501",
    lat: "50.782615",
    lon: "14.214770",
    company: "Ultimedion s.r.o.",
    ic: "88887854",
    dic: "CZ546984545",
  },
];
*/

const controller = "/tenants/";

function getUrl(id) {
  return `${controller}${id}`;
}

export function getData() {
  return http.get(controller);
}

export function getItem(id) {
  return http.get(getUrl(id));
}

export function deleteData(id) {
  return http.delete(getUrl(id));
}

export function save(item) {
  if (item.id) {
    //const body = { ...item }; //clone movie a vymaz z nej property _id, pri put nesmi id obsoahovat protoze ho posilam jako url param
    //delete body._id;
    console.log("save tenant put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save tenant post ", item);
    return http.post(controller, item);
  }
}
