import React, { useContext } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import * as Const from "../_Const";
import { AppContext } from "../Context/AppContext";

export const routeList = [
  {
    id: Const.CREATE,
    icon: "plus",
    name: "Nový záznam",
    link: "novy-zaznam",
  },
  {
    id: Const.EDIT,
    icon: "pencil",
    name: "Editovat záznam",
    link: "editovat",
  },
];

export const menuList = [
  {
    id: Const.ID_ACCOUNT_PROFILE,
    icon: "fa-user",
    name: "Profil zákazníka",
    link: "profil-zakaznika",
    leftMenu: true,
    generalSettings: true,
  },
  {
    id: Const.ID_GENERAL_SETTINGS,
    icon: "fa-cog",
    name: "Obecná nastavení",
    link: "obecna-nastaveni",
    leftMenu: true,
    generalSettings: true,
  },
  {
    id: Const.ID_MENU,
    icon: "fa-bars",
    name: "Nastavení Menu",
    link: "menu",
    leftMenu: true,
    generalSettings: true,
  },
  {
    id: Const.ID_ACCOUNT_LOGIN,
    icon: "fa-lock",
    name: "Přihlášení do aplikace",
    link: "prihlaseni-do-aplikace",
    leftMenu: false,
  },
  {
    id: Const.ID_ACCOUNT_REGISTER,
    name: "Zaregistrovat se",
    link: "registrace-do-aplikace",
    leftMenu: false,
  },
  {
    id: Const.ID_ACCOUNT_FORGOT_PASSWORD,
    name: "Zapomenuté heslo",
    link: "zapomenute-heslo",
    leftMenu: false,
  },
  {
    id: Const.ID_ACCOUNT_PASSWORD_CHANGE,
    name: "Změba hesla",
    link: "zmena-hesla",
    leftMenu: false,
  },
  {
    id: Const.ID_ACCOUNT_LOGOUT,
    icon: "fa-sign-out-alt",
    name: "Odhlášení z aplikace",
    link: "odhlaseni-z-aplikace",
    leftMenu: false,
  },

  {
    id: Const.ID_URGENT_NOTICE,
    icon: "fa-exclamation-triangle",
    name: "Urgetní sdělení",
    link: "urgentni-sdeleni",
    leftMenu: true,
  },
  {
    id: Const.ID_ORDINATION_HOUR,
    icon: "fa-hourglass-half",
    name: "Ordinační hodiny",
    link: "ordinacni-hodiny",
    leftMenu: true,
  },

  {
    id: Const.ID_ABOUT,
    icon: "fa-info-circle",
    name: "O nás",
    link: "o-nas",
    leftMenu: true,
  },

  {
    id: Const.ID_SERVICES,
    icon: "fa-heartbeat",
    name: "Naše služby",
    link: "nase-sluzby",
    leftMenu: false,
  },

  {
    id: Const.ID_PRICING,
    icon: "fa-credit-card",
    name: "Ceník",
    link: "cenik",
    leftMenu: true,
  },
  {
    id: Const.ID_BLOG,
    icon: "fa-file-alt",
    name: "Novinky v ordinaci",
    link: "novinky",
    leftMenu: true,
  },

  {
    id: Const.ID_EXT_INFO,
    icon: "fa-thumbs-up",
    name: "Vybavenost čekárny",
    link: "vybavenost-cekarny",
    leftMenu: true,
  },

  {
    id: Const.ID_INSURANCES,
    icon: "fa-university",
    name: "Zdravotní pojišťovny",
    link: "pojistovny",
    leftMenu: true,
  },
  {
    id: Const.ID_TEAM,
    icon: "fa-users",
    name: "Náš tým",
    link: "nas-tym",
    leftMenu: true,
  },
  {
    id: Const.ID_CONTACT,
    icon: "fa-clinic-medical",
    name: "Kontakt",
    link: "kontakt",
    leftMenu: true,
  },
  {
    id: Const.ID_FAQ,
    icon: "fa-clipboard-list",
    name: "Otázky a odpovědi",
    link: "otazky-odpovedi",
    leftMenu: true,
  },

  {
    id: Const.ID_GALLERY,
    icon: "fa-camera",
    name: "Galerie obrázků",
    link: "galerie",
    leftMenu: true,
  },
  {
    id: Const.ID_FILES,
    icon: "fa-file-download",
    name: "Soubory ke stažení",
    link: "soubory-ke-stazeni",
    leftMenu: true,
  },
  {
    id: Const.ID_OTHER_PAGES,
    icon: "fa-copy",
    name: "Ostatní stránky",
    link: "ostatni-stranky",
    leftMenu: true,
  },

  // {
  //   id: Const.ID_CONTACT,
  //   icon: "fa-address-card",
  //   name: "Kontaktní údaje",
  //   link: "kontaktni-udaje",
  // },
];

export function getMenuSectionList() {
  return menuList.filter((g) => g.leftMenu && !g.generalSettings);
}

export function getMenuGeneralSettingsList() {
  return menuList.filter((g) => g.leftMenu && g.generalSettings);
}

export function getMenuIndexByID(id) {
  if (!id) {
    return null;
  }
  const data = menuList.filter((g) => g.id.toLowerCase() == id.toLowerCase());

  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

export function getRouteByID(id) {
  if (!id) {
    return null;
  }
  const data = routeList.filter((g) => g.id.toLowerCase() == id.toLowerCase());

  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

export function getLinkFromMenuId(id) {
  const menuItem = getMenuIndexByID(id);
  let link = "";
  if (menuItem) {
    link = "/" + menuItem.link;
  }
  return link;
}

export function getDetailLinkRoute(id, childId) {
  let link;
  const menuItem = getMenuIndexByID(id);
  if (menuItem) {
    link = `/${menuItem.link}/${childId}`;
  }

  return link;
}

export function getLinkRouteFromMenuIdWithMethod(id, method, withParamId = false) {
  let link;
  const menuItem = getMenuIndexByID(id);
  const methodMenuItem = getRouteByID(method);
  if (menuItem && methodMenuItem) {
    if (withParamId) {
      link = `/${menuItem.link}/:id/:paramId`;
    } else {
      link = `/${menuItem.link}/:id`;
    }
  }

  return link;
}

export function getLinkFromMenuIdWithMethod(id, method, subId, paramId = 0) {
  let link;
  const menuItem = getMenuIndexByID(id);
  const methodMenuItem = getRouteByID(method);
  if (menuItem) {
    if (method === Const.CREATE && subId > 0) {
      if (methodMenuItem) {
        link = `/${menuItem.link}/${methodMenuItem.link}/${subId}`;
      }
    } else {
      if (methodMenuItem) {
        link = `/${menuItem.link}/${methodMenuItem.link}`;
      }
      if (subId) {
        if (paramId != 0) {
          link = `/${menuItem.link}/${subId}/${paramId}`;
        } else {
          link = `/${menuItem.link}/${subId}`;
        }
      }
    }
  }
  return link;
}

export function goToEditFormPage(id, subId, paramId = 0) {
  setAppContext({ ...appContext, activeId: id, activeSubId: Const.EDIT });
  const path = getLinkFromMenuIdWithMethod(id, Const.EDIT, subId, paramId);
  history.push(path);
}

export function goToCreatePage(id, subId = 0) {
  setAppContext({ ...appContext, activeId: id, activeSubId: Const.CREATE });
  const path = getLinkFromMenuIdWithMethod(id, Const.CREATE, subId);
  history.push(path);
}

export function goBack() {
  history.goBack();
}

export function goToPage(id) {
  setAppContext({ ...appContext, activeId: id, activeSubId: null });
  const path = getLinkFromMenuId(id);
  history.push(path);
}

export function isNewItemLink(id) {
  let isNew = false;
  if (id === getRouteByID(Const.CREATE).link) {
    isNew = true;
  }
  return isNew;
}

let routeMatch;
let params;
let history;
let [appContext, setAppContext] = [2];
export function MenuService() {
  [appContext, setAppContext] = useContext(AppContext);
  history = useHistory();
  // routeMatch = useRouteMatch();
  // params = useParams();

  return <div></div>;
}
