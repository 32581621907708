import http from "../HttpService";

const controller = "/externalservice/";

function getAresUrlItem(tenantId, ico) {
  return `${controller}aresinfo/${tenantId}/${ico}`;
}

export function getAresItem(tenantId, ico) {
  return http.get(getAresUrlItem(tenantId, ico));
}
