import * as Const from "../../_Const";
import http from "../HttpService";

const controller = "/menu/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrlItemOtherPage(tenantId) {
  return `${controller}otherpagelist/${tenantId}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlOrder(tenantId) {
  return `${controller}order/${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function getOtherPageData(tenantId) {
  return http.get(getUrlItemOtherPage(tenantId));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function save(item) {
  if (item.id) {
    console.log("save menu put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save menu post ", item);
    return http.post(controller, item);
  }
}

export function saveOrder(tenantId, items) {
  console.log(tenantId, items);
  if (items) {
    console.log("save menu item order put ", items);
    return http.put(getUrlOrder(tenantId), items);
  }
}

export const sectionData = [
  {
    value: Const.ID_ORDINATION_HOUR,
    text: "Ordinační hodiny",
  },

  {
    value: Const.ID_ABOUT,
    text: "O nás",
  },

  // {
  //   value: Const.ID_SERVICES,
  //   text: "Naše služby",
  // },

  {
    value: Const.ID_PRICING,
    text: "Ceník",
  },
  {
    value: Const.ID_BLOG,
    text: "Novinky v ordinaci",
  },

  {
    value: Const.ID_EXT_INFO,
    text: "Vybavenost čekárny",
  },

  {
    value: Const.ID_INSURANCES,
    text: "Zdravotní pojišťovny",
  },
  {
    value: Const.ID_TEAM,
    text: "Náš tým",
  },
  {
    value: Const.ID_CONTACT,
    text: "Kontakt",
  },
  {
    value: Const.ID_FAQ,
    text: "Otázky a odpovědi",
  },

  {
    value: Const.ID_GALLERY,
    text: "Galerie obrázků",
  },
  {
    value: Const.ID_FILES,
    text: "Soubory ke stažení",
  },
];

export const data = [
  {
    menuId: 1,
    tenantId: 30,
    menuTitle: "O nas",
    menuLink: "#o-nas",
    menuOrder: 1,
    parentId: 0,
  },
  {
    menuId: 2,
    tenantId: 30,
    menuTitle: "Nase sluzby",
    menuLink: "#services",
    menuOrder: 2,
    parentId: 0,
  },
  {
    menuId: 3,
    tenantId: 30,
    menuTitle: "Ordinacni hodidny",
    menuLink: "#o-nas",
    menuOrder: 3,
    parentId: 0,
  },
  {
    menuId: 4,
    tenantId: 30,
    menuTitle: "Ostatni",
    menuLink: "",
    menuOrder: 4,
    parentId: 0,
  },
  {
    menuId: 41,
    tenantId: 30,
    menuTitle: "Cenik",
    menuLink: "#cenik",
    menuOrder: 1,
    parentId: 4,
  },
  {
    menuId: 42,
    tenantId: 30,
    menuTitle: "Galerie",
    menuLink: "#cenik",
    menuOrder: 2,
    parentId: 4,
  },
  {
    menuId: 43,
    tenantId: 30,
    menuTitle: "FAQ",
    menuLink: "#Faq",
    menuOrder: 3,
    parentId: 4,
  },
  {
    menuId: 5,
    tenantId: 30,
    menuTitle: "Kontakt",
    menuLink: "#o-nas",
    menuOrder: 5,
    parentId: 0,
  },
  {
    menuId: 32,
    tenantId: 30,
    menuTitle: "Odinacni hodiny 2",
    menuLink: "#o-nas",
    menuOrder: 2,
    parentId: 3,
  },
  {
    menuId: 31,
    tenantId: 30,
    menuTitle: "Odinacni hodiny 1",
    menuLink: "#o-nas",
    menuOrder: 1,
    parentId: 3,
  },
];
