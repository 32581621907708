import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as MenuItemService from "../../Services/data/MenuItemService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";
import { Link } from "react-router-dom";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await MenuItemService.saveOrder(appContext.tenantId, setViewToModel(data));
      MS.goToPage(Const.ID_MENU);
      setIsEditedData(false);
      RS.toastSave("Změna pořadí položek menu úspěšně provedena");
    } catch (ex) {
      let error = "Nastala chyba při ukládání pořadí položek Menu";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function validateChildComponent() {
  return true;
}

function validateFormItem(name, value) {}

async function populateData(id) {
  let { data: items } = await MenuItemService.getData(parseInt(id));

  if (!items) {
    items = items.map((item) => getViewFromModel(item));
  }
  //let items = MenuItemService.data;

  return items;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      title: item.title,
      link: item.link,
      order: item.order,
      parentId: item.parentId,
      linkIdOther: item.linkIdOther,
      linkIdSection: item.linkIdSection,
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      title: "",
      link: "",
      order: 0,
      parentId: 0,
      linkIdOther: 0,
      linkIdSection: "",
    };
  }
  return model;
}

function setViewToModel(data) {
  if (!data) {
    return null;
  }
  let vm = [];
  data.map((item) =>
    vm.push({
      id: item.id,
      tenantId: appContext.tenantId,
      title: item.title,
      link: item.link,
      order: item.order,
      parentId: item.parentId,
      linkTypeId: item.linkTypeId,
      linkIdOther: item.linkIdOther,
      linkIdSection: item.linkIdSection,
    })
  );

  return vm;
}

async function deleteItem(e, id) {
  e.stopPropagation();
  try {
    await MenuItemService.deleteData(parseInt(appContext.tenantId), parseInt(id));
    setData(await populateData(appContext.tenantId));
    RS.toastDelete();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

function editItem(e, id) {
  e.stopPropagation();
  MS.goToEditFormPage(Const.ID_MENU, id);
}

function addSubMenuItem(e, id) {
  e.stopPropagation();
  MS.goToCreatePage(Const.ID_MENU, id);
}

function setDragging(e) {
  //console.log("dragging", e.target.innerText);
  dragging = parseInt(e.currentTarget.id);
}

function setDraggedOver(e) {
  e.preventDefault();
  //  console.log("drag over", e.target.innerText);
  draggedOver = parseInt(e.currentTarget.id);
}

function compare(e) {
  let newState = data.sort((a, b) => a.order - b.order);
  const dragItem = data.find((x) => parseInt(x.id) === dragging);
  const dropItem = data.find((x) => parseInt(x.id) === draggedOver);
  var dragItemIndex = newState.findIndex((x) => parseInt(x.id) === dragging);
  var dropItemIndex = newState.findIndex((x) => parseInt(x.id) === draggedOver);

  //nelze zanorit sama do sebe
  if (dragItem.id === dropItem.parentId) {
    RS.toastError("Nelze přesunout položku samu do sebe");
    return;
  }

  newState.splice(dragItemIndex, 1);
  newState.splice(dropItemIndex, 0, dragItem);

  newState = newState.map((item, index) => {
    //nastaveni stejne urovne zanoreni
    if (item.id === dragItem.id) {
      item.parentId = dropItem.parentId;
    }
    item.order = index + 1;
    return item;
  });

  setData(newState);

  submitHandler(e);
}

function renderMenuItem(item, isSubMenu = false) {
  const id = item.id;
  const subMenuData = data.filter((m) => parseInt(m.parentId) === parseInt(id));
  const subMenuCss = isSubMenu ? "dragable-menu-item-secondary" : "dragable-menu-item";
  const cssClass = `btn btn-dark m-0 text-left ${subMenuCss}`;
  const icon = item.linkTypeId === Const.MENU_LINK_TYPE_DROPDOWN_MENU ? "fas fa-folder-open" : "fas fa-file";
  let link = null;
  if (item.linkTypeId === Const.MENU_LINK_TYPE_SECTION) {
    link = MS.getLinkFromMenuId(item.linkIdSection);
  } else if (item.linkTypeId === Const.MENU_LINK_TYPE_OTHER_PAGE) {
    link = MS.getLinkFromMenuIdWithMethod(Const.ID_OTHER_PAGES, Const.EDIT, item.linkIdOther, 0);
  }

  return (
    <>
      <div draggable={true} id={item.id} onDragOver={setDraggedOver} onDragStart={setDragging} onDrop={compare} className={cssClass}>
        <MDBRow>
          <MDBCol>
            <div className="mt-2">
              <i className={`ml-1 ${icon}`}></i>
              <span className="ml-2">
                {link && (
                  <Link to={link} className="link">
                    {item.title}
                  </Link>
                )}
                {!link && <>{item.title}</>}
              </span>
              {/* - {item.id} - {item.order} - {item.parentId} */}
            </div>
          </MDBCol>
          <MDBCol className="d-flex justify-content-end">
            <div className="mt-n1">
              {!isSubMenu && RS.renderAddIcon((e) => addSubMenuItem(e, id), `Přidat položku do menu ${item.title}`, "white")}
              {RS.renderEditIcon((e) => editItem(e, id), "white")}
              {RS.renderDeleteIcon((e) => deleteItem(e, id), false, "white")}
            </div>
          </MDBCol>
        </MDBRow>
      </div>

      {!isHidenSubmenu && subMenuData && (
        <MDBRow className="mt-2 ml-2">
          {subMenuData
            .sort((a, b) => a.order - b.order)
            .map((item) => (
              <MDBCol md="12" className="mb-1 no-gutters">
                <MDBCol>{renderMenuItem(item, true)}</MDBCol>
              </MDBCol>
            ))}
        </MDBRow>
      )}
    </>
  );
}

let [isEdited, setIsEditedData] = [2];
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isHidenSubmenu, setHidenSubmenu] = [2];
let dragging, draggedOver;
// let [menuItems, setMenuItems] = [2];

function PageMenuIndex(props) {
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);
  [data, setData] = useState([]);
  [isHidenSubmenu, setHidenSubmenu] = useState(false);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  if (!data || (data && !data.length > 1)) {
    return "";
  }

  let title = "Nastavení Menu";
  return (
    <Fragment>
      <BlackQuote title={title}>
        <b>Přidejte novou položku menu, smažte položku menu nebo nastavte pořadí položek v menu webových stránek</b> dle vašich představ na určené pořadí
      </BlackQuote>

      <div>
        <form onSubmit={submitHandler} noValidate>
          {RS.renderTitle("Položky v menu")}
          {RS.renderHint("Změnu pořadí položek menu provedete přetažením rámečku s názvem nahoru nebo dolu, pořadí se uloží automaticky")}
          <MDBRow className="mt-n2 mb-4">
            <MDBCol>
              <div className="mt-4 mb-4">{RS.renderAddButton(() => MS.goToCreatePage(Const.ID_MENU), "Přidat položku")}</div>

              {data
                .filter((item) => parseInt(item.parentId) === parseInt(0))
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                  <MDBCol>{renderMenuItem(item)}</MDBCol>
                ))}
            </MDBCol>
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageMenuIndex;
