import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import moment from "moment";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as UrgentNoticeService from "../../Services/data/UrgentNoticeService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      const result = await UrgentNoticeService.save(setViewToModel(data));
      //po pouzit POST nastavim nove ziskane ID do aktualniho state data
      var id = data.id ? data.id : 0;
      if (parseInt(id) === 0) {
        setData({ ...data, id: result.data.id });
      }
      setIsEditedData(false);
      MS.goToPage(Const.ID_URGENT_NOTICE);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Urgentní sdělení";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  return validateEditorError(editorContent);
}

function editorContentBlurHandler(content) {
  validateEditorError(content.target.innerText);
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  validateEditorError(content);
  setIsEditedData(true);
}

function validateEditorError(value) {
  let errors = data.errors;
  errors["text"] = VS.required(value, "Sdělení");
  setData({ ...data, errors });
  const isValid = data.errors["text"].length === 0;
  return isValid;
}

function changeCheckedHandler(event) {
  setIsEditedData(true);

  if (event.target.checked) {
    setData({ ...data, isAllTimeVisible: event.target.checked, from: RS.getDateToString(), to: RS.getDateToString() });
  } else {
    setData({ ...data, isAllTimeVisible: event.target.checked });
  }
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "title":
      errors[name] = VS.required(value, "Titulek");
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(id) {
  let { data: item } = await UrgentNoticeService.getItem(parseInt(id));
  item = getViewFromModel(item);
  return item;
}

async function deleteItem() {
  try {
    await UrgentNoticeService.deleteData(parseInt(appContext.tenantId));

    const item = getViewFromModel(null);
    setEditorContent(null);

    //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
    RS.setCustomTimeout(() => {
      setData(item);
      setIsEditedData(false);
    });

    //  setData(await populateData(appContext.tenantId));
    RS.toastDelete();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      title: item.title,
      text: item.text,
      from: RS.getDateToString(item.from),
      to: RS.getDateToString(item.to),
      isAllTimeVisible: item.from || item.to ? false : true,
      errors: {
        title: "",
        text: "",
        from: "",
        to: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      title: "",
      text: "",
      from: RS.getDateToString(),
      to: RS.getDateToString(),
      isAllTimeVisible: true,
      errors: {
        title: "",
        text: "",
        from: "",
        to: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    title: data.title,
    text: editorContent,
    from: data.isAllTimeVisible ? null : moment(data.from).unix(),
    to: data.isAllTimeVisible ? null : moment(data.to).unix(),
  };
  return vm;
}

let [isEdited, setIsEditedData] = [2];
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorContent, setEditorContent] = [2];

function PageUrgentNoticeForm(props) {
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorContent, setEditorContent] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setEditorContent(result.text);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Urgentní sdělení";
  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        <b>Upozorněte pacienty na náhlé změny ve vaší ordinaci</b> jako je <i>dovolená, nemoc, zástup, změna pracovní doby a jiné</i> pomocí jasně viditelného
        sdělení na hlavní stránce webu
      </BlackQuote>

      {RS.renderTitle("Text urgentního sdělení", "mt-4")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="mt-2">
            <MDBCol md="8">{RS.renderInputText("title", "Titulek", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="mt-2 ml-2">
            <MDBCol xl="8">
              {RS.renderSubTitle("Sdělení")}
              {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
            </MDBCol>
          </MDBRow>

          <div className="mt-4">{RS.renderTitle("Časové omezení viditelnosti sdělení na hlavní stránce")}</div>
          {RS.renderHint(
            "Chcete-li upravit viditelnost sdělení OD a DO - nastavte přepínací tlačítko (Urgentní sdělení je stále viditelné) do polohy vypnuto (poloha vlevo)"
          )}
          <MDBRow className="mt-2 ml-2">
            <MDBCol>
              <FormControlLabel
                control={<Switch checked={data.isAllTimeVisible} onChange={changeCheckedHandler} />}
                label="Urgentní sdělení je stále viditelné"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputDateText("from", "Viditelnost od", data, changeHandler, data.isAllTimeVisible)}</MDBCol>
          </MDBRow>
          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputDateText("to", "Viditelnost do", data, changeHandler, data.isAllTimeVisible)}</MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {data.id > 0 && <>{RS.renderResetData(deleteItem, "Odstranit urgentní sdělení")}</>}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageUrgentNoticeForm;
