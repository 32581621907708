import http from "../HttpService";

const controller = "/pricelist/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  if (item.id) {
    console.log("save price list put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save price list post ", item);
    return http.post(controller, item);
  }
}
/*
const data = [
  {
    id: 1,
    tenantId: 6,
    title: "Ceník zdravotnických výkonů",
    validFrom: "platný od 1.2.2019",
    items: [
      {
        title: "Výpis z dokumentace pro zaměstnavatele",
        price: "500,- Kč",
      },
      {
        title: "Vyšetření žadatele o řidičský průkaz skup. B,A,C,T",
        price: "400,- Kč",
      },
      {
        title: "Vystavení formuláře Oznámení úrazu pro pojišťovnu",
        price: "250,- Kč",
      },
      {
        title: "Administrativní úkon jakýkoliv (á 10 minut)",
        price: "150,- Kč",
      },
      {
        title: "Posudek-posouzení zdravotního stavu",
        price: "250,- Kč",
      },
      {
        title: "Zdravotní průkaz (vyšetření a vystavení)(na 3 měsíce)",
        price: "100,- Kč",
      },
      {
        title: "Posudek  pro studium VŠ, SŠ, učebního oboru ",
        price: "200,- Kč",
      },
      {
        title: "Aplikace náušnic nastřelením (obě uši)",
        price: "200,- Kč",
      },
    ],
  },
  {
    id: 2,
    tenantId: 1,
    title: "Ceny očkovacích látek (cena se může měnit průběžně)",
    validFrom: "platný od 1.1.2020",
    items: [
      {
        title: "Očkovací látka proti neisseria meningitis (Neisvac C)",
        price: "5772,- Kč",
      },
      {
        title: "Očkovací látka proti meningocoku B (BEXSERO)",
        price: "2587,- Kč",
      },
      {
        title: "Očkovací látka proti meningokoku NIMENRIX (A,C,W,Y)",
        price: "1258,- Kč",
      },
      {
        title: "Očkovací látka  Priorix Tetra (M M R V)",
        price: "1358,- Kč",
      },
      {
        title: "Očkování proti žloutence A (Havrix 720 Junior)",
        price: "789,- Kč",
      },
    ],
  },
];

export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
