import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as TeamService from "../../Services/data/TeamService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await TeamService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_TEAM);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Náš tým";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  let isChildValid = true;
  return isChildValid;
}

function editorContentBlurHandler(content) {
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  setIsEditedData(true);
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "name":
      errors[name] = VS.required(value, "Jméno a příjmení");
      break;
    case "position":
      errors[name] = VS.required(value, "Pozice");
      break;
    case "phone":
      if (value) {
        errors[name] = VS.validatePhone(value);
      } else {
        errors[name] = "";
      }
      break;
    case "email":
      if (value) {
        errors[name] = VS.validateEmail(value);
      } else {
        errors[name] = "";
      }
      break;
    default:
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }
  let { data: item } = await TeamService.getItem(parseInt(tenantId), parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    if (item.image === womanNoImage) {
      item.imageSrc = womanNoImage;
    } else if (item.image === manNoImage) {
      item.imageSrc = manNoImage;
    }

    model = {
      id: item.id,
      tenantId: item.tenantId,
      position: item.position,
      imageName: item.image,
      imageOriginal: item.imageOriginal,
      imageSrc: item.imageSrc,
      imageFile: "",
      imageShowEdit: false,
      name: item.name,
      email: item.email,
      phone: item.phone,
      bio: item.bio,
      order: item.order,
      errors: {
        name: "",
        position: "",
        phone: "",
        email: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      imageName: manNoImage,
      imageOriginal: "",
      imageSrc: "",
      imageFile: "",
      imageShowEdit: false,
      name: "",
      position: "",
      bio: "",
      phone: "",
      email: "",
      order: 0,
      errors: {
        name: "",
        position: "",
        phone: "",
        email: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("tenantId", data.tenantId);
  formData.append("name", data.name);
  formData.append("position", data.position);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("bio", editorContent);
  formData.append("order", data.order);

  formData.append("image", data.imageName);
  formData.append("imageOriginal", data.imageOriginal);
  formData.append("imageFile", data.imageFile);

  return formData;
  /*
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    imageName: data.imageName,
    imageSrc: data.imageSrc,
    imageFile: data.imageFile,
    name: data.name,
    position: data.position,
    phone: data.phone,
    email: data.email,
    bio: editorContent,
  };
  return vm;
  */
}

function showPreview(e) {
  if (e.target.files && e.target.files[0]) {
    let imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setIsEditedData(true);
      setData({
        ...data,
        imageName: null,
        imageFile: imageFile,
        imageSrc: x.target.result,
        imageShowEdit: false,
      });
    };
    reader.readAsDataURL(imageFile);
  }
}

function editPhoto(isEdit) {
  setData({
    ...data,
    imageShowEdit: isEdit,
  });
}

function setAvatar(img) {
  setIsEditedData(true);
  setData({
    ...data,
    imageName: img,
    imageShowEdit: false,
    imageSrc: img,
  });
}

const manNoImage = "/assets/img/noimage/ic_man_no_image_256.png";
const womanNoImage = "/assets/img/noimage/ic_woman_no_image_256.png";
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorContent, setEditorContent] = [2];
let [isEdited, setIsEditedData] = [2];

function PageTeamForm(props) {
  const paramId = props.match.params.id;
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorContent, setEditorContent] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, paramId);
      if (result) {
        setData(result);
        setEditorContent(result.bio);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Náš tým editace záznamu";
  let subTitle = "Pomocí této stránky editujete vytvořeného";
  if (MS.isNewItemLink(paramId)) {
    title = "Náš tým nový záznam";
    subTitle = "Pomocí této stránky přidáte nového";
  }

  let renderedImage = data.imageSrc;
  if (!renderedImage) {
    renderedImage = manNoImage;
  }

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>člena týmu</b>
      </BlackQuote>

      {RS.renderTitle("Informace o členovi týmu")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="ml-1">
            <MDBCol md="12">
              <div className="image-cropper">
                <img src={renderedImage} className="cirular-img" />
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className="ml-3 mt-1">
            <MDBCol>{RS.renderEditLink("Upravit fotografii", editPhoto)}</MDBCol>
          </MDBRow>

          {data.imageShowEdit && (
            <>
              <MDBRow className="ml-4">
                <MDBCol md="12">{RS.renderSubTitle("Vyberte si jaký obrázek vystihuje člena týmu - může vložit i vlastní fotografii")}</MDBCol>
              </MDBRow>

              <MDBRow className="ml-1 mb-4">
                <MDBCol>
                  <img src={manNoImage} onClick={() => setAvatar(manNoImage)} className="btn rounded-circle team-no-image-small" />
                  <img src={womanNoImage} onClick={() => setAvatar(womanNoImage)} className="btn rounded-circle team-no-image-small ml-1 mr-1" />

                  <label className="btn btn-outline-primary">
                    <i className="fa fa-user"></i> Vložit vlastní fotografii
                    <input type="file" name="image" accept="image/*" className="d-none" onChange={showPreview} />
                  </label>
                </MDBCol>
              </MDBRow>
            </>
          )}

          <MDBRow>
            <MDBCol md="4">{RS.renderInputText("name", "Jméno a příjmení", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("position", "Pozice (lékař, sestra, recepční,...)", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4">{RS.renderTitle("Kontaktní údaje")}</div>

          <MDBRow>
            <MDBCol md="4">{RS.renderInputText("phone", "Telefon", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("email", "E-mail", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4">
            {RS.renderTitle("Informace o členovi týmu")}
            {RS.renderHint("Napište pár informací o členovi týmu, jako je nápříklad dosažené vzdělání, předchozí praxe a jiné užitečné informace")}
          </div>
          <MDBRow className="mt-2 ml-2">
            <MDBCol md="8">
              {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
            </MDBCol>
          </MDBRow>
          <MDBRow className="justify-content-start ml-0 mt-2 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageTeamForm;
