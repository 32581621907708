import http from "../HttpService";
/*
const data = [
  {
    id: 1,
    tenantId: 7,
    text:
      "Jsme moderní stomatologické centrum poskytující široké spektrum péče v oblasti zubního lékařství. Naší filozofií je zajistit komplexní bezbolestné ošetření nejvyšší kvality. Kromě běžné stomatologické péče a dentální hygieny, zavádíme implantáty, provádíme ošetření kořenových kanálků pod mikroskopem, stomatochirurgické výkony a další nadstandardní ošetření. V ordinacích našeho centra je pro Vás připraven tým zkušených odborníků s mnohaletou praxí.",
  },
];*/

const controller = "/about/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function deleteData(tenantId) {
  return http.delete(getUrl(tenantId));
}

export function save(item) {
  if (item.id) {
    console.log("save about put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save about post ", item);
    return http.post(controller, item);
  }
}
/*
export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.tenantId === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
