import React, { Fragment } from "react";
import { Prompt } from "react-router-dom";
import moment from "moment";
import { MDBInput, MDBBtn, MDBIcon } from "mdbreact";
import JoditEditor from "jodit-react";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import ReactLoading from "react-loading";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { OutlinedInput } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";
import * as MS from "./MenuService";

const colorPrimary = "#4285F4";
const textColor = "#fff";
const colorRed = "#FF0700";
const colorDisabled = "#E9ECEF";
let loadingToastLastCallingTime;

export const DefaultEditorConfig = {
  readonly: false,
  toolbarAdaptive: false,
  toolbarStickyOffset: 55,
  language: "cs_cz",
  buttons: [
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "link",
    "table",
    "|",
    "ul",
    "ol",
    "|",
    "align",
    "outdent",
    "indent",
    "|",
    "undo",
    "redo",
    "|",
    "preview",
  ],
};

export const PrimaryRadio = withStyles({
  root: {
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const PrimaryCheckbox = withStyles({
  root: {
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function renderInputPassword(name, label, data, onChange, cssClass = null, onFocusOut = null, maxLenght = 0, customErrorNode = null) {
  if (!customErrorNode) {
    customErrorNode = data.errors[name];
  }

  return renderInput(name, label, data[name], onChange, "password", customErrorNode, true, cssClass, null, null, true, onFocusOut, maxLenght);
}

export function renderInputText(name, label, data, onChange, cssClass = null, onFocusOut = null, maxLenght = 0, customErrorNode = null) {
  if (!customErrorNode) {
    customErrorNode = data.errors[name];
  }

  return renderInput(name, label, data[name], onChange, "text", customErrorNode, true, cssClass, null, null, true, onFocusOut, maxLenght);
}

export function renderInputDateText(name, label, data, onChange, disabled = false, cssClass = null, onFocusOut = null, maxLenght = 0) {
  return renderInput(name, label, data[name], onChange, "date", data.errors[name], true, cssClass, null, null, true, onFocusOut, maxLenght, disabled);
}

export function renderInputTextNoXPadding(name, label, data, onChange) {
  return renderInput(name, label, data[name], onChange, "text", data.errors[name], true, null, null, null, true, null, null);
}

export function renderInputTextMultiline(name, label, data, onChange, lines, maxLines) {
  return renderInput(name, label, data[name], onChange, "text", data.errors[name], true, null, true, lines);
}

export function renderInput(
  name,
  label,
  value,
  onChange,
  type,
  errorNode,
  fullWidth,
  cssClass,
  multiline,
  lines,
  noContainerPadding,
  onFocusOut,
  maxLenght,
  disabled
) {
  let hasError = false;
  if (errorNode) {
    hasError = errorNode.length > 0;
  }

  let className = "";
  if (cssClass) {
    className = cssClass;
  }

  let inputProps = "";
  let inputLabelProps = "";

  if (hasError) {
    className += " is-invalid";

    //nezobrazovat ikonu chyby te cervene uz tam je moc a v pripade ze pridam inputProps maxLength tak to nefunguje s ikonou
    // inputProps = {
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <Icon style={{ color: colorRed }}>error_outlined</Icon>
    //     </InputAdornment>
    //   ),
    // };
  }

  if (type === "date") {
    inputLabelProps = {
      shrink: true,
    };
  }

  if (maxLenght > 0) {
    inputProps = {
      maxLength: maxLenght,
    };
  }

  return (
    <Fragment>
      <div className="container flex-fill">
        <TextField
          disabled={disabled}
          error={hasError}
          value={value}
          label={label}
          name={name}
          type={type}
          multiline={multiline}
          rows={lines}
          onChange={onChange}
          onBlur={onFocusOut}
          variant="outlined"
          margin="dense"
          fullWidth={fullWidth}
          className={className}
          inputProps={inputProps}
          InputLabelProps={inputLabelProps}
        />
        {hasError && <div className="error-tooltip">{errorNode} </div>}
      </div>
      {/* <MDBInput name={name} value={value} label={label} onChange={onChange} type={type} className={className} outline />
       */}
    </Fragment>
  );
}

export function renderSubmit(disabled = false, icon = null) {
  return renderSubmitText("Uložit záznam", disabled, icon);
}

export function renderSubmitText(text, disabled = false, cssClass = "", icon = "save") {
  return (
    <MDBBtn color="primary" className={cssClass} disabled={disabled} type="submit">
      {icon && (
        <>
          <MDBIcon icon={icon} className="mr-1" /> {text}
        </>
      )}
    </MDBBtn>
  );
}

export function renderSubmitTextWithoutIcon(text, disabled = false, cssClass = "") {
  return (
    <MDBBtn color="primary" className={cssClass} disabled={disabled} type="submit">
      {text}
    </MDBBtn>
  );
}

export function renderAddButton(onClick, text = "Nový záznam") {
  return (
    <MDBBtn color="primary" onClick={onClick}>
      <MDBIcon icon="plus" /> {text}
    </MDBBtn>
  );
}

export function renderTitle(text, cssClass = "") {
  return (
    <Fragment>
      <h5 className={`${cssClass}`}>{text}</h5>
    </Fragment>
  );
}

export function renderSubTitle(text, cssClass = "") {
  return (
    <Fragment>
      <h7 className={`${cssClass}`}>{text}</h7>
    </Fragment>
  );
}

export function renderHint(text) {
  return (
    <Fragment>
      <p className="hint">{text}</p>
    </Fragment>
  );
}

export function renderDisabledCss(css, isDisabled) {
  const disabledCss = isDisabled ? "disabled readonly-item" : "";
  return `${css} ${disabledCss}`;
}

export function renderResetData(onclick, text = null) {
  if (!text) {
    text = "Vymazat data";
  }
  return (
    <Fragment>
      <Button href="#" color="secondary" onClick={onclick}>
        {text}
      </Button>
    </Fragment>
  );
}

export function renderBackLink() {
  return (
    <Fragment>
      <Button href="#" color="secondary" onClick={() => MS.goBack()}>
        Zpět
      </Button>
    </Fragment>
  );
}

export function renderEditLink(text, onclick) {
  return (
    <Fragment>
      <Button href="#" color="secondary" onClick={onclick}>
        {text}
      </Button>
    </Fragment>
  );
}

export function setCustomTimeout(callback, delay = 50) {
  if (callback) {
    const timer = setTimeout(() => {
      callback();
    }, delay);
    return () => clearTimeout(timer);
  }
}

export function renderEditor(value, options, onBlur, onChange, ref, error, name = null) {
  let hasError = false;
  let className = "";
  if (error) {
    hasError = error.length > 0;
  }

  if (hasError) {
    className = "invalid-container";
  }

  return (
    <div className={className}>
      <JoditEditor
        ref={ref}
        value={value}
        config={options}
        tabIndex={1} // tabIndex of textarea
        onBlur={name ? (newContent) => onBlur(name, newContent) : (newContent) => onBlur(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={name ? (newContent) => onChange(name, newContent) : (newContent) => onChange(newContent)}
      />
      {hasError && <div className="error-tooltip-editor">{error} </div>}
    </div>
  );
}

export function renderDeleteIcon(onClick, disabled, color = colorPrimary) {
  color = disabled ? colorDisabled : color;
  return (
    <IconButton aria-label="delete" zIndex="modal" title="Vymazat" onClick={onClick} disabled={disabled}>
      <Icon fontSize="small" style={{ color: color }}>
        delete
      </Icon>
    </IconButton>
  );
}

export function renderEditIcon(onClick, color = colorPrimary) {
  return (
    <IconButton aria-label="edit" title="Editovat" onClick={onClick}>
      <Icon fontSize="small" style={{ color: color }}>
        edit
      </Icon>
    </IconButton>
  );
}

export function renderAddIcon(onClick, text = "Přidat", color = colorPrimary) {
  return (
    <IconButton aria-label="add" title={text} onClick={onClick}>
      <Icon fontSize="small" style={{ color: color }}>
        add
      </Icon>
    </IconButton>
  );
}

export function renderDownloadIcon(url) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <IconButton aria-label="download" title="Stáhnout">
        <Icon fontSize="small" style={{ color: colorPrimary }}>
          get_app
        </Icon>
      </IconButton>
    </a>
  );
}

export function getDateToString(date = null) {
  return date ? moment.unix(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
}

export function stripHtml(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function textShortener(text, len) {
  if (text && text.length > len) {
    text = text.substring(0, len) + "... (zkrácený text)";
  }
  return text;
}

export function renderToastContainer() {
  return <ToastContainer />;
}

export function toastSuccess(text) {
  toast.success(text, {
    position: "top-right",
    hideProgressBar: true,
    transition: Slide,
    autoClose: 1500,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastInfo(text, time = 3000) {
  toast.info(text, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: true,
    transition: Slide,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastError(text) {
  toast.error(text, {
    position: "top-right",
    autoClose: 12000,
    hideProgressBar: true,
    transition: Slide,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
export function toastSave(text = "Data byla úspěšně uložena") {
  toastSuccess(text);
}

export function toastDelete() {
  toastSuccess("Položka byla úspěšně vymazána");
}

export function toastDeleteAll() {
  toastSuccess("Data byla úspěšně vymazána");
}

export function checkUnsaveWork(isEdited, title) {
  return (
    <Prompt when={isEdited} message={`Na stránce ${title} byly provedeny změny, které doposud nebyly uloženy. Opravdu chcete stránku ${title} opustit?`} />
  );
}
/*
fce se stara o nastaveni loading dialogu v pripade ze akce 
trva vice nez 250ms
 */
export function setLoading(isLoading = true) {
  loadingToastLastCallingTime = Date.now();

  setTimeout(() => {
    const millis = Date.now() - loadingToastLastCallingTime;

    //za posledni X ms nebylo volana tato metoda - tudiz vykonej kod
    if (millis >= 250) {
      if (!isLoading) {
        closeAllToast("customId");
      } else {
        toast(renderLoading, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          transition: Slide,
          closeOnClick: true,
          closeButton: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          toastId: "customId",
        });
      }
    }
  }, 250);
}

export function closeAllToast(id) {
  toast.dismiss(id);
}

export function renderLoading(isLoading) {
  const color = "#e91e63";
  const type = "spin";
  const w = 35;
  const h = 35;

  if (!isLoading) {
    return null;
  }

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <ReactLoading type={type} color={color} height={h} width={w} />
    </div>
  );
}

export function renderSelect(name, label, value, menuData, onChange, error = "", labelWidth = "60") {
  if (!menuData) {
    return "";
  }
  let hasError = false;
  let className = "";
  if (error) {
    hasError = error.length > 0;
  }

  if (hasError) {
    className = "invalid-container";
  }

  return (
    <div className="container flex-fill">
      <FormControl margin="dense" fullWidth={true}>
        <InputLabel id={`${name}-label`} variant="outlined">
          {label}
        </InputLabel>
        <Select
          error={hasError}
          className="select-outlined"
          variant="outlined"
          margin="dense"
          name={name}
          input={<OutlinedInput labelId={`${name}-label`} labelWidth={labelWidth} />}
          id={name}
          value={value}
          onChange={onChange}
        >
          {menuData.map((item) => (
            <MenuItem value={item.value}>{item.text}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {hasError && <div className="error-tooltip">{error} </div>}
    </div>
  );
}
