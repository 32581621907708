import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import BlackQuote from "../../Components/BlackQuote";
import { AppContext } from "../../Context/AppContext";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import { MDBRow, MDBCol } from "mdbreact";
import * as ServicesService from "../../Services/data/ServicesService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";
import SectionInfo from "../../Components/SectionInfo";

async function submitHandler(event) {
  if (isSectionDisabled) {
    event.preventDefault();
    if (setionInfoChildRef.current.save(true)) {
      setIsEditedData(false);
    }
  } else {
    if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
      try {
        setionInfoChildRef.current.save(false);

        const result = await ServicesService.save(setViewToModel(data));
        //po pouzit POST nastavim nove ziskane ID do aktualniho state data
        const id = data.id ? data.id : 0;
        if (parseInt(id) === 0) {
          setData({ ...data, id: result.data.id });
        }
        setIsEditedData(false);
        MS.goToPage(Const.ID_SERVICES);
        RS.toastSave();
      } catch (ex) {
        let error = "Nastala chyba při ukládání dat položky Naše služby";
        console.log(ex.response);
        if (error) {
          RS.toastError(error);
        }
      }
    }
  }
}

function validateChildComponent() {
  const sectionValid = setionInfoChildRef.current.validate();
  const editorValid = validateEditorError(editorContent);
  const isValid = sectionValid && editorValid;
  return isValid;
}

function editorContentBlurHandler(content) {
  validateEditorError(content.target.innerText);
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  validateEditorError(content);
  setIsEditedData(true);
}

function validateEditorError(value) {
  let errors = data.errors;
  errors["text"] = VS.required(value, "Text");
  setData({ ...data, errors });
  const isValid = data.errors["text"].length === 0;
  return isValid;
}

function validateFormItem(name, value) {}

async function populateData(id) {
  let { data: item } = await ServicesService.getItem(parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function resetData() {
  if (!isSectionDisabled) {
    const id = data.id;
    const item = getViewFromModel(null);
    item.id = id;
    setData(item);
    setEditorContent(null);
  }

  setionInfoChildRef.current.reset();
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      text: item.text,
      errors: {
        text: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      text: "",
      errors: {
        text: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    text: editorContent,
  };
  return vm;
}

let [isEdited, setIsEditedData] = [2];
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorContent, setEditorContent] = [2];

let setionInfoChildRef;
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageServices() {
  setionInfoChildRef = useRef();
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorContent, setEditorContent] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setEditorContent(result.text);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Naše služby";

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        <b>Představte svým pacientům jaké služby poskytujete</b> jaká vyšetření u Vás mohou naleznout a jaké speciální přístroje používáte
      </BlackQuote>

      <SectionInfo
        ref={setionInfoChildRef}
        sectionCode={Const.ID_SERVICES}
        title={title}
        onDataChanged={() => setIsEditedData(true)}
        onDataLoaded={() => setIsEditedData(false)}
        onSectionEnabledChanged={(isDisabled) => setIsSectionDisabled(isDisabled)}
      />

      {RS.renderTitle("Naše služby", "mt-4")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className={RS.renderDisabledCss("mt-2 ml-2", isSectionDisabled)}>
            <MDBCol xl="10">
              {RS.renderSubTitle("Text")}
              {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
            </MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderResetData(resetData)}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageServices;
