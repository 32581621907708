import React, { Fragment, useState, useContext } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import * as MS from "../../Services/MenuService";
import * as Const from "../../_Const";
import * as AuthService from "../../Services/AuthService";
import Avatar from "@material-ui/core/Avatar";
import { MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent, false)) {
    const isValid = AuthService.changePassword(data.email);

    if (isValid) {
      setSent(true);
    } else {
      RS.toastError(`Nepodařilo se obnovit heslo. Akci opakujte později.`);
    }
  }
}

function validateChildComponent() {
  return true;
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "email":
      errors[name] = VS.validateEmail(value);
      break;
  }

  setData({ ...data, errors, [name]: value });
}

function getViewFromModel() {
  let model = {
    email: "",
    errors: {
      email: "",
    },
  };

  return model;
}

let [data, setData] = [2];
let [sent, setSent] = [2];
function PagePasswordChange(props) {
  [data, setData] = useState(getViewFromModel());
  [sent, setSent] = useState(false);

  return (
    <div class="login-container justify-content-center">
      <div className="my-auto">
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="login-control-item">
            <MDBCol md="12">
              <Avatar className="login-avatar mx-auto">
                <i class="fas fa-key"></i>
              </Avatar>
              <h1 className="text-center mt-2">WebDoOrdinace.cz</h1>
              <h5 className="text-center mt-2 mb-4 text-black-50">obnova hesla</h5>
              <div className="text-center  text-black-50">
                {!sent && (
                  <>
                    V případě, že jste zapoměli heslo, vyplňte email a klikněte na tlačítko <b>Obnovit heslo do aplikace</b>
                  </>
                )}
              </div>
            </MDBCol>
          </MDBRow>
          {sent && (
            <MDBRow className="login-control-item text-center mt-3 mb-4">
              <MDBCol md="12">Na email {data.email} byl zaslán odkaz na stránku pro zadání nového hesla. Zkontrolujte prosím emailovou schránku.</MDBCol>
            </MDBRow>
          )}
          {!sent && (
            <div>
              <MDBRow className="login-control-item">
                <MDBCol md="12">{RS.renderInputText("email", "Email", data, changeHandler)}</MDBCol>
              </MDBRow>
              <MDBRow className="mt-3 mb-2 login-control-item">
                <MDBCol md="12">
                  <div className="container flex-fill">{RS.renderSubmitTextWithoutIcon("Obnovit heslo do aplikace", false, "login-button ml-0")}</div>
                </MDBCol>
              </MDBRow>
            </div>
          )}
          <MDBRow className="mt-3  text-center">
            <MDBCol md="12">
              <div className="text-black-50 small">
                Némáte ještě vytvořený účet? <Link to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_REGISTER)}>Zaregistrujte se</Link>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-2  text-center">
            <MDBCol md="12">
              <div className="text-black-50 small">
                Již jste se k nám zaregistrovali? <Link to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGIN)}>Přihlašte se</Link>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
    </div>
  );
}

export default PagePasswordChange;
