import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as FaqService from "../../Services/data/FaqService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await FaqService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_FAQ);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Otázky a odpovědi";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  let isChildValid = true;
  let isValidQ = validateEditorError("question", editorQContent);
  let isValidA = validateEditorError("answer", editorAContent);
  if (isValidQ) {
    isChildValid = isValidA;
  }
  return isChildValid;
}

function editorContentBlurHandler(name, content) {
  validateEditorError(content.target.innerText);

  switch (name) {
    case "question":
      setEditorQContent(content.target.innerHTML);
      break;
    case "answer":
      setEditorAContent(content.target.innerHTML);
      break;
  }
}

function editorContentChangeHandler(name, content) {
  validateEditorError(name, content);
  setIsEditedData(true);
}

function validateEditorError(name, value) {
  let errors = data.errors;
  let isValid = true;
  switch (name) {
    case "question":
      errors[name] = VS.required(value, "Otázka");
      isValid = data.errors[name].length === 0;
      break;
    case "answer":
      errors[name] = VS.required(value, "Odpověď");
      isValid = data.errors[name].length === 0;
      break;
  }

  setData({ ...data, errors });

  return isValid;
}
function validateFormItem(name, value) {
  //let errors = data.errors;
  // switch (name) {
  //   case "question":
  //     errors[name] = VS.required(value, "Otázka");
  //     break;
  //   case "answer":
  //     errors[name] = VS.required(value, "Odpověď");
  //     break;
  //   default:
  //     break;
  // }
  // setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }
  let { data: item } = await FaqService.getItem(parseInt(tenantId), parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      question: item.question,
      answer: item.answer,
      errors: {
        question: "",
        answer: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      question: "",
      answer: "",
      errors: {
        question: "",
        answer: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    question: editorQContent,
    answer: editorAContent,
  };
  return vm;
}

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorQContent, setEditorQContent] = [2];
let [editorAContent, setEditorAContent] = [2];
let [isEdited, setIsEditedData] = [2];

function PageFaqForm(props) {
  const paramId = props.match.params.id;
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorAContent, setEditorAContent] = useState(null);
  [editorQContent, setEditorQContent] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, paramId);
      if (result) {
        setData(result);
        setEditorAContent(result.answer);
        setEditorQContent(result.question);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Otázky a odpovědi editace záznamu";
  let subTitle = "Pomocí této stránky editujete vytvořenou";
  if (MS.isNewItemLink(paramId)) {
    title = "Otázky a odpovědi nový záznam";
    subTitle = "Pomocí této stránky přidáte novou";
  }

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>otázku a odpověď</b>
      </BlackQuote>

      {RS.renderTitle("Otázka a odpověď")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="ml-2">
            <MDBCol md="8">
              {RS.renderSubTitle("Otázka")}
              {RS.renderEditor(
                editorQContent,
                RS.DefaultEditorConfig,
                editorContentBlurHandler,
                editorContentChangeHandler,
                editorRef,
                data.errors.question,
                "question"
              )}
            </MDBCol>
            {/* <MDBCol md="8">{RS.renderInputTextMultiline("question", "Otázka", data, changeHandler, 8)}</MDBCol> */}
          </MDBRow>
          <MDBRow className="mt-4 ml-2">
            <MDBCol md="8">
              {RS.renderSubTitle("Odpověď")}
              {RS.renderEditor(
                editorAContent,
                RS.DefaultEditorConfig,
                editorContentBlurHandler,
                editorContentChangeHandler,
                editorRef,
                data.errors.answer,
                "answer"
              )}
            </MDBCol>
            {/* <MDBCol md="8">{RS.renderInputTextMultiline("answer", "Odpověď", data, changeHandler, 8)}</MDBCol> */}
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageFaqForm;
