import http from "../HttpService";

const controller = "/insurance/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function save(item) {
  console.log("save insurances post ", item);
  return http.post(controller, item);
}
