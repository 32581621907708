import React, { Fragment, useState, useContext, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import * as Const from "../../_Const";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as GalleryService from "../../Services/data/GalleryService";
import SectionInfo from "../../Components/SectionInfo";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      data.images.forEach(async (item, index) => {
        var result = await GalleryService.save(setViewToModel(item));

        //po pouziit POST nastavim nove ziskane ID a nazev onbrazku do aktualniho state data
        var id = item.id ? item.id : 0;
        if (parseInt(id) === 0) {
          setItemAfterPost(result.data, index);
        }
      });
      setIsEditedData(false);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Galerie obrázků";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

async function submitOrderHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await GalleryService.saveOrder(appContext.tenantId, setViewToModelOrder(data));
      RS.toastSave("Změna pořadí položek Galerie obrázků a fotorgrafií úspěšně provedena");
    } catch (ex) {
      let error = "Nastala chyba při ukládání pořadí položek Galerie obrázků a fotorgrafií";
      console.log(ex, ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function setViewToModelOrder(data) {
  if (!data) {
    return null;
  }
  let vm = [];
  data.images.map((item) =>
    vm.push({
      id: item.id,
      tenantId: appContext.tenantId,
      order: item.order,
    })
  );

  return vm;
}

function changeListItemHandler(event, index) {
  const { value, name } = event.target;
  validateListItem(name, value, index);
  setIsEditedData(true);
}

function setItemAfterPost(newItem, index) {
  const list = [...data.images];
  var itemToUpdate = list[index];

  if (itemToUpdate) {
    itemToUpdate.id = newItem.id;
    itemToUpdate.image = newItem.image;
    itemToUpdate.imageOriginal = newItem.imageOriginal;
    list[index] = itemToUpdate;
  }

  setData({ ...data, images: list });
}

function validateListItem(name, value, index) {
  let isValid = true;
  const list = [...data.images];

  switch (name) {
    case "title":
      list[index].title = value;
      break;
  }

  setData({ ...data, images: list });
  return isValid;
}

function validateChildComponent() {
  let isChildValid = true;
  return isChildValid;
}

function validateFormItem(name, value) {
  let errors = data.errors;

  setData({ ...data, errors, [name]: value });
}

async function populateData(id) {
  let { data: item } = await GalleryService.getData(parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      images: item.images,
      errors: {},
    };
  } else {
    model = {
      images: [],
      errors: {},
    };
  }

  //v priapde ze je response  api prazdny tak je images null proto nastavim empty aray[]
  if (!model.images) {
    model.images = [];
  }
  return model;
}

function setViewToModel(item) {
  var id = item.id ? item.id : 0;
  //v pripade PUT posilam do save() json bez obrazku, pro POST form data s obrazkama
  if (parseInt(id) > 0) {
    let vm = {
      id: item.id,
      tenantId: appContext.tenantId,
      title: item.title,
    };
    return vm;
  } else {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("tenantId", appContext.tenantId);
    formData.append("title", item.title);
    formData.append("image", item.image);
    formData.append("imageOriginal", item.imageOriginal);
    formData.append("imageFile", item.imageFile);
    return formData;
  }
}

function deleteNonSavedItemLocally(index) {
  const list = [...data.images];
  list.splice(index, 1);
  setIsEditedData(true);
  setData({ ...data, images: list });
}

async function deleteItem(e, id, index) {
  e.stopPropagation();
  //polozka jeste neni ulozena a mazu ji lokalne
  if (!id) {
    deleteNonSavedItemLocally(index);
    return;
  }

  try {
    await GalleryService.deleteData(parseInt(appContext.tenantId), parseInt(id));
    setData(await populateData(appContext.tenantId));
    RS.toastDelete();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

async function deleteAllData() {
  try {
    await GalleryService.deleteAllData(parseInt(appContext.tenantId));
    const item = getViewFromModel(null);
    setData(item);
    RS.toastDeleteAll();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

function showPreview(e) {
  if (e.target.files) {
    for (let i = 0; i < e.target.files.length; i++) {
      let imageFile = e.target.files[i];
      const reader = new FileReader();
      reader.onload = (x) => {
        const list = [...data.images];
        list.push({ imageFile: imageFile, imageSrc: x.target.result });
        setIsEditedData(true);
        setData({ ...data, images: list });
      };
      reader.readAsDataURL(imageFile);
    }
  }
}

function setDragging(e) {
  //console.log("dragging", e.target.innerText);
  dragging = parseInt(e.currentTarget.id);
}

function setDraggedOver(e) {
  e.preventDefault();
  //  console.log("drag over", e.target.innerText);
  draggedOver = parseInt(e.currentTarget.id);
}

function compare(e) {
  let newState = data.images.sort((a, b) => a.order - b.order);
  const dragItem = data.images.find((x) => parseInt(x.id) === dragging);
  const dropItem = data.images.find((x) => parseInt(x.id) === draggedOver);
  var dragItemIndex = newState.findIndex((x) => parseInt(x.id) === dragging);
  var dropItemIndex = newState.findIndex((x) => parseInt(x.id) === draggedOver);

  //nelze zanorit sama do sebe
  if (dragItem.id === dropItem.parentId) {
    RS.toastError("Nelze přesunout položku samu do sebe");
    return;
  }

  newState.splice(dragItemIndex, 1);
  newState.splice(dropItemIndex, 0, dragItem);

  newState = newState.map((item, index) => {
    //nastaveni stejne urovne zanoreni
    if (item.id === dragItem.id) {
      item.parentId = dropItem.parentId;
    }
    item.order = index + 1;
    return item;
  });

  setData(newState);

  submitOrderHandler(e);
}

function needToSaveSectionDataHandler() {
  RS.toastInfo("Nejprve uložte titulek sekce pomocí tlačítka Uložit záznam", 5000);
  setIsSectionDisabled(true);
}

let dragging, draggedOver;
const noImage = "/assets/img/noimage/no-image-blog.png";
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageGalleryForm() {
  [isSectionDisabled, setIsSectionDisabled] = useState(false);
  [appContext, setAppContext] = useContext(AppContext);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [isEdited, setIsEditedData] = useState(false);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Galerie obrázků a fotografií";
  let subTitle = "Pomocí této stránky můžete přidat, upravit nebo vymazat";

  if (!data) {
    return "";
  }

  let isAnyImage = false;
  if (data.images && data.images.length > 0) {
    isAnyImage = true;
  }

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>obrázky a fotografie ve Vaší gelerii</b>
      </BlackQuote>

      <SectionInfo
        sectionCode={Const.ID_GALLERY}
        title={title}
        showEditor={true}
        showSaveBtn={true}
        onNeedToSaveSection={needToSaveSectionDataHandler}
        onAfterSaveSection={() => setIsSectionDisabled(false)}
      />
      <div className={RS.renderDisabledCss("", isSectionDisabled)}>
        <div className="mt-4">{RS.renderTitle("Galerie obrázků a fotografií")}</div>
        {RS.renderHint("Změnu pořadí jednotliých fotografií provedete přetažením fotografie doleva či doprava, pořadí se uloží automaticky")}
        <div>
          <form onSubmit={submitHandler} noValidate>
            <MDBRow className="ml-2 mt-2">
              <MDBCol>
                <label className="btn btn-outline-primary blog-image-btn ">
                  <i className="fa fa-user"></i> Vložit obrázky
                  <input type="file" name="image" accept="image/*" multiple className="d-none" onChange={showPreview} />
                </label>
              </MDBCol>
            </MDBRow>

            {!isAnyImage && (
              <MDBRow className="ml-2 mt-2">
                <MDBCol md="12">
                  <img src={noImage} className="gallery-thumb" />
                </MDBCol>
              </MDBRow>
            )}
            <div className="container-fluid">
              <div className="row mt-4">
                {data.images &&
                  data.images
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <>
                        <Card
                          draggable={true}
                          id={item.id}
                          onDragOver={setDraggedOver}
                          onDragStart={setDragging}
                          onDrop={compare}
                          className="gallery-card btn d-flex flex-column dragable-item"
                        >
                          <CardMedia className="media gallery-thumb mx-n4 mt-n4" image={item.imageSrc} />
                          <div className="mt-2 mx-n4">
                            {RS.renderInputText("title", "Popis", item, (e) => changeListItemHandler(e, index), null, null, null, data)}
                          </div>
                          <CardActions className="mt-auto align-self-center">{RS.renderDeleteIcon((e) => deleteItem(e, item.id, index))}</CardActions>
                        </Card>
                      </>
                    ))}
              </div>
            </div>
            <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
              {RS.renderSubmit(!isEdited)}
              {RS.renderResetData(deleteAllData, "Vymazat galerii")}
            </MDBRow>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default PageGalleryForm;
