import http from "../HttpService";

const controller = "/team/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrlOrder(tenantId) {
  return `${controller}order/${tenantId}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function saveOrder(tenantId, items) {
  console.log(tenantId, items);
  if (items) {
    console.log("save team item order put ", items);
    return http.put(getUrlOrder(tenantId), items);
  }
}

export function save(item) {
  let id = item.get("id");

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  if (parseInt(id) > 0) {
    console.log("save team put ", item);
    return http.put(getUrl(id), item, config);
  } else {
    console.log("save team post ", item);
    return http.post(controller, item, config);
  }
}

/*

const data = [
  {
    id: 1,
    tenantId: 6,
    imageSrc: "",
    imageName: "",
    imageFile: "",
    name: "MUDr. Július Török",
    position: "Lékař",
    phone: "412539280",
    email: "torok@overenylekar.cz",
    bio:
      "Jsem lékař se specializovanou způsobilostí v oboru všeobecné praktické lékařství. Pocházím z Ostravy a po studiu na gymnáziu jsem studoval na 3. lékařské fakultě Univerzity Karlovy v Praze.  Promoval jsem v roce 2013, atestaci v oboru Všeobecné praktické lékařství jsem získal v roce 2016. Jsem členem České ékařské komory, Sdružení praktických lékařů České republiky, České lékařské společnosti Jana Evangelisty Purkyně a člen Mladých praktiků",
  },
  {
    id: 2,
    tenantId: 6,
    imageSrc: "",
    imageName: "",
    imageFile: "",
    name: "Jaroslava Kratochvílová",
    position: "Zdravotní sestra",
    phone: "412539281",
    email: "kratochvilova@overenylekar.cz",
    bio: "Již léta pracuji v ordinaci, svůj profesní život jsem začínala jako sestra na chirurgické ambulanci.",
  },
];

export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  // let itemInDb = data.find(m => m.id === item.id) || {};
  // itemInDb.title = item.title;
  // itemInDb.genre = genresAPI.genres.find(g => g._id === movie.genreId);
  // itemInDb.numberInStock = movie.numberInStock;
  // itemInDb.dailyRentalRate = movie.dailyRentalRate;

  // if (!movieInDb._id) {
  //   itemInDb._id = Date.now().toString();
  //   data.push(itemInDb);
  // }

  return itemInDb;
}
*/
