import http from "../HttpService";

const controller = "/branch/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  if (item.id) {
    console.log("save branch put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save branch post ", item);
    return http.post(controller, item);
  }
}
/*
const data = [
  {
    id: 1,
    tenantId: 30,
    street: "Třída kapitána Karla Jaroše 32",
    city: "Děčín",
    zip: "40501",
    lat: "50.782699",
    lon: "14.2141883",

    phone: [
      {
        text: "602885774",
      },
      {
        text: "412544112",
      },
    ],
    email: [
      {
        text: "decin@ultimedion.cz",
      },
      {
        text: "decin-sesterna@ultimedion.cz",
      },
    ],
  },
  {
    id: 2,
    tenantId: 30,
    street: "Weberova",
    city: "Děčín",
    zip: "40502",
    lat: "50.689692",
    lon: "14.5262204",
    phone: [
      {
        text: "487555222",
      },
    ],
    email: [
      {
        text: "ceska-lipa@ultimedion.cz",
      },
    ],
  },
  {
    id: 3,
    tenantId: 2,
    street: "28. října",
    city: "Děčín",
    zip: "40502",
    lat: "50.782699",
    lon: "14.2141883",
    phone: [
      {
        text: "601727541",
      },
    ],
    email: [
      {
        text: "info@archa.cz",
      },
    ],
  },
];
*/
/*
export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
