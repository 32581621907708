import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as MenuService from "../../Services/data/MenuItemService";
import * as Const from "../../_Const";
import * as Helper from "../../_Helper";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await MenuService.save(setViewToModel(data));
      MS.goToPage(Const.ID_MENU);
      setIsEditedData(false);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Menu";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  let isChildValid = true;
  return isChildValid;
}

function getLink(title) {
  let link = null;
  if (title) {
    link = Helper.removeDiacritics(title)
      .replace(/[\s\uFEFF\xA0]/g, "-")
      .toLowerCase()
      .trim();
  }
  return link;
}

function validateFormItem(name, value) {
  let errors = data.errors;
  let isDataSavedInside = false;
  switch (name) {
    case "title":
      errors[name] = VS.required(value, "Titulek");
      break;
    case "link":
      if (parseInt(data.linkTypeId) === parseInt(Const.MENU_LINK_TYPE_DROPDOWN_MENU)) {
        errors[name] = "";
      } else {
        errors[name] = VS.required(value, "Odkaz");
      }
      break;
    case "linkTypeId":
      errors[name] = VS.selectValueRequired(value, "Typ propojení");
      break;
    case "linkIdOther":
      if (parseInt(data.linkTypeId) != parseInt(Const.MENU_LINK_TYPE_OTHER_PAGE)) {
        errors[name] = "";
      } else {
        errors[name] = VS.required(value, "Propojit se stránkou");

        //podarilo se vyplnit stranku a title je pradny vyplnit i title
        if (!errors[name] && !data.title) {
          const title = getTextByValue(data.otherPages, value);
          if (title) {
            errors["title"] = "";
            if (!data.link) {
              errors["link"] = "";
              setData({ ...data, errors, [name]: value, title: title, link: getLink(title) });
            } else {
              setData({ ...data, errors, [name]: value, title: title });
            }

            isDataSavedInside = true;
          }
        }
      }
      break;
    case "linkIdSection":
      if (parseInt(data.linkTypeId) != parseInt(Const.MENU_LINK_TYPE_SECTION)) {
        errors[name] = "";
      } else {
        errors[name] = VS.required(value, "Propojit se sekcí");

        //podarilo se vyplnit sekci a title je pradny vyplnit i title
        if (!errors[name] && !data.title) {
          const title = getTextByValue(MenuService.sectionData, value);
          if (title) {
            if (!data.link) {
              errors["link"] = "";
              setData({ ...data, errors, [name]: value, title: title, link: getLink(title) });
            } else {
              setData({ ...data, errors, [name]: value, title: title });
            }
            isDataSavedInside = true;
          }
        }
      }
      break;
    default:
      break;
  }
  if (!isDataSavedInside) {
    setData({ ...data, errors, [name]: value });
  }
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    let vm = getViewFromModel(null);
    let { data: item } = await MenuService.getOtherPageData(parseInt(tenantId));
    vm.otherPages = item;
    return vm;
  }

  let { data: item } = await MenuService.getItem(parseInt(tenantId), parseInt(id));
  return getViewFromModel(item);
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      linkTypeId: item.linkTypeId,
      parentId: item.parentId,
      linkIdOther: item.linkIdOther,
      linkIdSection: item.linkIdSection,
      title: item.title,
      link: item.link,
      otherPages: item.otherPages,
      order: item.order,
      errors: {
        title: "",
        link: "",
        linkTypeId: "",
        linkIdOther: "",
        linkIdSection: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      linkTypeId: 0,
      parentId: 0,
      linkIdOther: 0,
      linkIdSection: "",
      title: "",
      link: "",
      otherPages: [],
      order: 0,
      errors: {
        title: "",
        link: "",
        linkTypeId: "",
        linkIdOther: "",
        linkIdSection: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    linkTypeId: data.linkTypeId,
    parentId: parentId > 0 ? parentId : data.parentId,
    linkIdOther: data.linkIdOther,
    linkIdSection: data.linkIdSection,
    title: data.title,
    link: getLink(data.link),
    order: data.order,
  };
  return vm;
}

function getTextByValue(data, value) {
  let text = null;
  if (data) {
    text = data.find((x) => x.value === value).text;
  }
  return text;
}

function sectionSelectWrapper() {
  const menuDataSorted = MenuService.sectionData.sort((a, b) => a.text.localeCompare(b.text));
  return RS.renderSelect("linkIdSection", "Propojit se sekcí", data.linkIdSection, menuDataSorted, changeHandler, data.errors.linkIdSection, "120");
}

function otherPageSelectWrapper(menuData) {
  const menuDataSorted = menuData.sort((a, b) => a.text.localeCompare(b.text));
  return RS.renderSelect("linkIdOther", "Propojit se stránkou", data.linkIdOther, menuDataSorted, changeHandler, data.errors.linkIdOther, "145");
}

function menuSelectWrapper() {
  const menuData = [
    {
      value: Const.MENU_LINK_TYPE_SECTION,
      text: "Sekce stránky",
    },
    {
      value: Const.MENU_LINK_TYPE_OTHER_PAGE,
      text: "Ostatní stránky",
    },
    {
      value: Const.MENU_LINK_TYPE_URL,
      text: "URL adresa",
    },
  ];

  //pridat pro hlavni polozky bez parentiID moznost zanoreni
  if (parseInt(parentId) === 0) {
    menuData.push({
      value: Const.MENU_LINK_TYPE_DROPDOWN_MENU,
      text: "Nadřazená složka - bude obsahovat podpoložky menu",
    });
  }
  return RS.renderSelect("linkTypeId", "Typ propojení", data.linkTypeId, menuData, changeHandler, data.errors.linkTypeId, "100");
}

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];
let parentId = 0;

function PageMenuForm(props) {
  const id = props.match.params.id;
  parentId = props.match.params.paramId ? parseInt(props.match.params.paramId) : 0;

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, id);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Nastavení položky Menu";
  let subTitle = "Pomocí této stránky vyplníte";

  return (
    <Fragment>
      <BlackQuote title={title}>
        {subTitle} <b> informace o položce menu.</b> Tyto informace se zobrazí jako nabídka na Vašich webových stránkách
      </BlackQuote>
      {RS.renderTitle("Informace položky menu", "mt-4")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow>
            <MDBCol md="6">{menuSelectWrapper()}</MDBCol>
            <MDBCol md="6">
              {data.linkTypeId === Const.MENU_LINK_TYPE_SECTION && sectionSelectWrapper()}
              {data.linkTypeId === Const.MENU_LINK_TYPE_OTHER_PAGE && otherPageSelectWrapper(data.otherPages)}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">{RS.renderInputText("title", "Titulek", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">{data.linkTypeId != Const.MENU_LINK_TYPE_DROPDOWN_MENU && RS.renderInputText("link", "Odkaz", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageMenuForm;
