import React, { Fragment, useState, useContext, useEffect, useRef } from "react";

import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as OpeningHourService from "../../Services/data/OpeningHourService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";
import SectionInfo from "../../Components/SectionInfo";

async function submitHandler(event) {
  if (isSectionDisabled) {
    event.preventDefault();
    if (setionInfoChildRef.current.save(true)) {
      setIsEditedData(false);
    }
  } else {
    if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
      try {
        setionInfoChildRef.current.save(false);
        const result = await OpeningHourService.save(setViewToModel(data));
        //po pouzit POST nastavim nove ziskane ID do aktualniho state data
        var id = data.id ? data.id : 0;
        if (parseInt(id) === 0) {
          setData({ ...data, id: result.data.id });
        }
        setIsEditedData(false);
        MS.goToPage(Const.ID_ORDINATION_HOUR);
        RS.toastSave();
      } catch (ex) {
        let error = "Nastala chyba při ukládání dat položky Ordinační hodiny";
        console.log(ex.response);

        if (error) {
          RS.toastError(error);
        }
      }
    }
  }
}

function validateFormItem(name, value) {
  // let errors = data.errors;
  // setData({ ...data, errors, [name]: value });
}

function changeDayItemHandler(event, index, dayData) {
  const { value, name } = event.target;
  validateDayItem(name, value, index, dayData, false);
  setIsEditedData(true);
}

function validateDayItem(name, value, index, dayData, isFocusOut = false) {
  let isValid = true;
  const list = [...dayData.times];

  const dayIndexId = "" + dayData.dayId + index;
  const replacedName = name.replace(dayIndexId + "", "");

  switch (replacedName) {
    case "start":
      list[index].start = value;
      data.errors[name] = VS.required(value, "Od");
      if (isFocusOut) {
        data.errors[name] = VS.validateOpenHourItem(value, "Od");
      }
      isValid = data.errors[name].length === 0;
      break;
    case "end":
      list[index].end = value;
      data.errors[name] = VS.required(value, "Do");
      if (isFocusOut) {
        data.errors[name] = VS.validateOpenHourItem(value, "Do");
      }
      isValid = data.errors[name].length === 0;
      break;
    case "label":
      list[index].label = value;
      break;
  }
  saveListToDay(dayData, list);

  return isValid;
}

function saveListToDay(dayData, list) {
  switch (dayData.dayId) {
    case Const.DAY_ID_MONDAY:
      setMonData({ ...monData, times: list });
      break;
    case Const.DAY_ID_TUESDAY:
      setTueData({ ...tueData, times: list });
      break;
    case Const.DAY_ID_WEDNESDAY:
      setWedData({ ...wedData, times: list });
      break;
    case Const.DAY_ID_THURSDAY:
      setThurData({ ...thurData, times: list });
      break;
    case Const.DAY_ID_FRIDAY:
      setFriData({ ...friData, times: list });
      break;
    case Const.DAY_ID_SATURDAY:
      setSatData({ ...satData, times: list });
      break;
    case Const.DAY_ID_SUNDAY:
      setSunData({ ...sunData, times: list });
      break;
  }
}

function saveListToDayForCbx(dayData, isClosed, isOpen24) {
  switch (dayData.dayId) {
    case Const.DAY_ID_MONDAY:
      setMonData({ ...monData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_TUESDAY:
      setTueData({ ...tueData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_WEDNESDAY:
      setWedData({ ...wedData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_THURSDAY:
      setThurData({ ...thurData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_FRIDAY:
      setFriData({ ...friData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_SATURDAY:
      setSatData({ ...satData, isOpen24: isOpen24, isClosed: isClosed });
      break;
    case Const.DAY_ID_SUNDAY:
      setSunData({ ...sunData, isOpen24: isOpen24, isClosed: isClosed });
      break;
  }
}

function onFocusOutHandler(event, index, dayData) {
  let { name, value } = event.target;

  let checkHour = false;

  const dayIndexId = "" + dayData.dayId + index;
  const replacedName = name.replace(dayIndexId + "", "");

  switch (replacedName) {
    case "start":
    case "end":
      checkHour = true;
      break;
    default:
      break;
  }

  if (checkHour) {
    if (value) {
      const parsed = parseInt(value);
      if (!isNaN(parsed)) {
        if (value.length == 1) {
          value += ":00";
        } else if (value.length == 2) {
          value += ":00";
        } else if (value.length == 3 && !value.includes(":")) {
          value = value.substr(0, 1) + ":" + value.substr(1);
        } else if (value.length == 4 && !value.includes(":")) {
          value = value.substr(0, 2) + ":" + value.substr(2);
        }
      }
    }
  }

  validateDayItem(name, value, index, dayData, true);
}

function removeDayItemHandler(dayData, index) {
  const list = [...dayData.times];
  list.splice(index, 1);
  deleteDayError(dayData.dayId, index);
  saveListToDay(dayData, list);
  setIsEditedData(true);
  console.log(data.errors);
}

function addDayItemHandler(dayData) {
  const list = [...dayData.times];
  list.push({ start: "", end: "", label: "" });
  saveListToDay(dayData, list);
}

function validateChildComponent() {
  let isMonValid = true;
  let isTueValid = true;
  let isWedValid = true;
  let isThurValid = true;
  let isFriValid = true;
  let isSatValid = true;
  let isSunValid = true;

  isMonValid = validateAllChildDays(monData);
  isTueValid = validateAllChildDays(tueData);
  isWedValid = validateAllChildDays(wedData);
  isThurValid = validateAllChildDays(thurData);
  isFriValid = validateAllChildDays(friData);
  isSatValid = validateAllChildDays(satData);
  isSunValid = validateAllChildDays(sunData);

  const sectionValid = setionInfoChildRef.current.validate();

  // console.log(isMonValid, "mon");
  // console.log(isTueValid, "tue");
  // console.log(isWedValid, "wed");
  // console.log(isThurValid, "thur");
  // console.log(isFriValid, "fri");
  // console.log(isSatValid, "sat");
  // console.log(isSunValid, "sun");

  return sectionValid && isMonValid && isTueValid && isWedValid && isThurValid && isFriValid && isSatValid && isSunValid;
}

function validateAllChildDays(dayData) {
  let isChildValid = true;
  if (dayData && !dayData.isOpen24 && !dayData.isClosed) {
    Object.entries(dayData.times).forEach(([, value], index) => {
      const isValid = validateDayItem("start" + dayData.dayId + index, value.start, index, dayData, false);
      //jen kdyz je stale validni tak koroluju validitu tohoto itemu, jinak vrati valid false, ale potrebuju projet vsechny prvky
      if (isChildValid) {
        isChildValid = isValid;
      }

      const isValidEnd = validateDayItem("end" + dayData.dayId + index, value.end, index, dayData, false);
      if (isChildValid) {
        isChildValid = isValidEnd;
      }
    });
  }
  return isChildValid;
}

function deleteDayError(dayId, dayIndex = -1) {
  let errors = data.errors;
  let checkIndexOnly = dayIndex === -1 ? false : true;

  let endName = "end" + dayId;
  let startName = "start" + dayId;
  //jeli vyplnen index ruzny od -1 tak kontroluj jen radek s indexem v danem dni a ne cely den
  if (checkIndexOnly) {
    startName = "start" + dayId + dayIndex;
    endName = "end" + dayId + dayIndex;
  }

  Object.entries(errors).forEach(([key]) => {
    //jeli chyba pojmenovana ve tvaru field + dayId + index
    // a aktualni key obsahuje fiald+dayId tak tuto hodnutu chyby smaz
    if (key.indexOf(startName) === 0 || key.indexOf(endName) === 0) {
      errors[key] = "";
    }
  });

  setData({ ...data, errors });
}

async function populateData(tenantId) {
  let { data: item } = await OpeningHourService.getItem(parseInt(tenantId));
  return getViewFromModel(item);
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      openingHours: item.openingHours,
      errors: {
        start0: "",
        end0: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: 0,
      openingHours: [],
      errors: {
        start0: "",
        end0: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    openingHours: [monData, tueData, wedData, thurData, friData, satData, sunData],
  };
  return vm;
}

function handleCbxChange(event, name, dayData) {
  setIsEditedData(true);
  let isOpen24 = false;
  let isClosed = false;
  const checked = event.target.checked;
  const isCbxOpen24 = dayData.isOpen24;
  const isCbxClosed = dayData.isClosed;

  //changed cbx
  if (name === "isOpen24") {
    isOpen24 = checked;
    //provadim li zaskrtnuti isopen 24 a je zaskrtnuto isClsoed
    //nastavit isClsoed na false
    if (checked && isCbxClosed) {
      isClosed = false;
    }
  } else if (name === "isClosed") {
    isClosed = checked;
    //provadim li zaskrtnuti isopen 24 a je zaskrtnuto isClsoed
    //nastavit isClsoed na false
    if (checked && isCbxOpen24) {
      isOpen24 = false;
    }
  }

  //v pripade ze se zakrtnutl isclosed nebo isOpen24
  //tak smaz chyby child nodes
  if (isClosed || isOpen24) {
    deleteDayError(dayData.dayId);
  }

  saveListToDayForCbx(dayData, isClosed, isOpen24);
}

function renderDay(dayData, label) {
  let disabledDelete = false;
  let hasTimeArray = false;
  let rowDisabled = "";

  if (!dayData) {
    return null;
  }

  if (dayData.times && dayData.times.length === 1) {
    // disabledDelete = true;
  }

  if (dayData && dayData.times && dayData.times.length > 0) {
    hasTimeArray = true;
  }

  if (!hasTimeArray) {
    dayData.times = [];
    addDayItemHandler(dayData);
  }

  if (dayData.isOpen24 || dayData.isClosed) {
    rowDisabled = "readonly-item disabled";
  }

  return (
    <MDBRow className={RS.renderDisabledCss("mt-4", isSectionDisabled)}>
      <MDBCol md="1" className="mr-2">
        {RS.renderTitle(label)}
      </MDBCol>
      <MDBCol md="8" className="ordination-cbx-row">
        <FormControlLabel
          control={<RS.PrimaryCheckbox color="primary" onChange={(e) => handleCbxChange(e, "isClosed", dayData)} checked={dayData.isClosed} />}
          label="Zavřeno"
          className="ordination-cbx"
        />
        <FormControlLabel
          control={<RS.PrimaryCheckbox color="primary" onChange={(e) => handleCbxChange(e, "isOpen24", dayData)} checked={dayData.isOpen24} />}
          label="Otevřeno 24 hodin"
          className="ordination-cbx"
        />
      </MDBCol>
      <MDBCol md="10">
        {hasTimeArray &&
          dayData.times.map((item, i) => (
            <div className={`row ml-4 ordination-item-row ${rowDisabled}`}>
              <div className="col-6 col-sm-2 ordination-item-container">
                {RS.renderInput(
                  "start" + dayData.dayId + i,
                  "Od",
                  item.start,
                  (e) => changeDayItemHandler(e, i, dayData),
                  "text",
                  data.errors["start" + dayData.dayId + i],
                  false,
                  "ordination-item",
                  false,
                  0,
                  false,
                  (e) => onFocusOutHandler(e, i, dayData),
                  5
                )}
              </div>
              <div className="col-6 col-sm-2 ordination-item-container">
                {RS.renderInput(
                  "end" + dayData.dayId + i,
                  "Do",
                  item.end,
                  (e) => changeDayItemHandler(e, i, dayData),
                  "text",
                  data.errors["end" + dayData.dayId + i],
                  false,
                  "ordination-item",
                  false,
                  0,
                  false,
                  (e) => onFocusOutHandler(e, i, dayData),
                  5
                )}
              </div>
              <div className="col-8 col-sm-5 ordination-item-container">
                {RS.renderInput(
                  "label" + dayData.dayId + i,
                  "Poznámka",
                  item.label,
                  (e) => changeDayItemHandler(e, i, dayData),
                  "text",
                  data.errors["label" + dayData.dayId + i],
                  false,
                  "ordination-item"
                )}
              </div>

              <div className="float-left mt-1">{RS.renderDeleteIcon(() => removeDayItemHandler(dayData, i), disabledDelete)}</div>
              {dayData.times.length - 1 === i ? (
                <div className="float-left mt-1">{RS.renderAddIcon(() => addDayItemHandler(dayData))}</div>
              ) : (
                <div className="ordination-empty-item">&nbsp;</div>
              )}
            </div>
          ))}
      </MDBCol>
    </MDBRow>
  );
}

let [data, setData] = [2];
let [monData, setMonData] = [2];
let [tueData, setTueData] = [2];
let [wedData, setWedData] = [2];
let [thurData, setThurData] = [2];
let [friData, setFriData] = [2];
let [satData, setSatData] = [2];
let [sunData, setSunData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];

let setionInfoChildRef;
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageOpeningHourForm(props) {
  setionInfoChildRef = useRef();
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  [monData, setMonData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_MONDAY));
  [tueData, setTueData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_TUESDAY));
  [wedData, setWedData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_WEDNESDAY));
  [thurData, setThurData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_THURSDAY));
  [friData, setFriData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_FRIDAY));
  [satData, setSatData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_SATURDAY));
  [sunData, setSunData] = useState(OpeningHourService.getDefaultDayItem(Const.DAY_ID_SUNDAY));

  const [isFirstLoaded, setFirstLoaded] = useState(false);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);

        setMonData(OpeningHourService.getDayItem(data, Const.DAY_ID_MONDAY));
        setTueData(OpeningHourService.getDayItem(data, Const.DAY_ID_TUESDAY));
        setWedData(OpeningHourService.getDayItem(data, Const.DAY_ID_WEDNESDAY));
        setThurData(OpeningHourService.getDayItem(data, Const.DAY_ID_THURSDAY));
        setFriData(OpeningHourService.getDayItem(data, Const.DAY_ID_FRIDAY));
        setSatData(OpeningHourService.getDayItem(data, Const.DAY_ID_SATURDAY));
        setSunData(OpeningHourService.getDayItem(data, Const.DAY_ID_SUNDAY));

        setIsEditedData(false);
        setFirstLoaded(true);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Ordinační hodiny";
  let subTitle = "Pomocí této stránky vyplníte";

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>Ordinační hodiny Vaší ordinace</b>
      </BlackQuote>

      <SectionInfo
        ref={setionInfoChildRef}
        sectionCode={Const.ID_ORDINATION_HOUR}
        title={title}
        showEditor={true}
        onDataChanged={() => setIsEditedData(true)}
        onDataLoaded={() => setIsEditedData(false)}
        onSectionEnabledChanged={(isDisabled) => setIsSectionDisabled(isDisabled)}
      />

      {RS.renderTitle("Ordinační hodiny", "mt-4")}

      {isFirstLoaded && (
        <div>
          <form onSubmit={submitHandler} noValidate>
            {renderDay(monData, "Pondělí")}
            {renderDay(tueData, "Úterý")}
            {renderDay(wedData, "Středa")}
            {renderDay(thurData, "Čtvrtek")}
            {renderDay(friData, "Pátek")}
            {renderDay(satData, "Sobota")}
            {renderDay(sunData, "Neděle")}

            <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
              {RS.renderSubmit(!isEdited)}
              {RS.renderBackLink()}
            </MDBRow>
          </form>
        </div>
      )}
    </Fragment>
  );
}

export default PageOpeningHourForm;
