import http from "../HttpService";

const controller = "/image/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}
function getUrlOrder(tenantId) {
  return `${controller}order/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function deleteAllData(tenantId) {
  return http.delete(getUrl(tenantId));
}

export function saveOrder(tenantId, items) {
  console.log(tenantId, items);
  if (items) {
    console.log("save images item order put ", items);
    return http.put(getUrlOrder(tenantId), items);
  }
}

export function save(item) {
  //v pripade PUT posilam do save() json, pro POST form data s obrazkama
  var id = item.id ? item.id : 0;

  if (parseInt(id) > 0) {
    console.log("save image put ", item);
    return http.put(getUrl(id), item);
  } else {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log("save image post ", item);
    return http.post(controller, item, config);
  }
}
