import * as RS from "./RenderService";
export const validUrlLinkRegex = RegExp(
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
);
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export const validPhoneRegex = RegExp(/^(\+420|00420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/);
export const validOpenHourRegex = RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
export const validLatitudeRegex = RegExp(/^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?\s?)$/);
export const validLongitudeRegex = RegExp(/^(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$/);
export function handleSubmit(event, data, validateFormItemCallback, customValidationCallback, withToast = false) {
  if (event) {
    event.preventDefault();
  }
  let isValid = false;
  let childsAreValid = true;

  if (customValidationCallback) {
    childsAreValid = customValidationCallback();
  }

  validateFormAll(validateFormItemCallback, data);

  //data nema node errors
  if (!data.errors) {
    isValid = true;
  } else {
    if (isAllValid(data.errors)) {
      isValid = true;
    }
  }
  console.info("Valid Form ->", isValid, " Valid child ->", childsAreValid);

  if (isValid && childsAreValid && withToast) {
    RS.toastSuccess("Data uložena");
  }

  return isValid && childsAreValid;
}

export function isAllValid(errors) {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

function validateFormAll(validateFormItemCallback, data) {
  for (let [name, value] of Object.entries(data)) {
    validateFormItemCallback(name, value);
  }
}

export function checkValidFormAll(validateFormItemCallback, data) {
  let isValid = false;
  for (let [name, value] of Object.entries(data)) {
    validateFormItemCallback(name, value);
  }
  if (isAllValid(data.errors)) {
    isValid = true;
  }
  return isValid;
}

export function validateLat(value) {
  //50.47005445454
  return validLatitudeRegex.test(String(value).trim().toLowerCase()) ? "" : "Pole GPS šířka není validní";
}

export function validateLon(value) {
  //14.216406444895426
  return validLongitudeRegex.test(String(value).trim().toLowerCase()) ? "" : "Pole GPS délka není validní";
}

export function validateEmail(value) {
  return validEmailRegex.test(String(value).trim().toLowerCase()) ? "" : "Pole E-mail není validní";
}

export function validateUrl(value, label) {
  return validUrlLinkRegex.test(String(value).trim().toLowerCase()) ? "" : `Pole ${label} není validní`;
}

export function validatePhone(value) {
  return validPhoneRegex.test(String(value).trim().toLowerCase()) ? "" : "Pole Telefon není validní";
}

export function validateOpenHourItem(value, label) {
  return validOpenHourRegex.test(String(value).trim().toLowerCase()) ? "" : `Pole ${label} není validní`;
}

export function samePassword(value, value2) {
  return value && value2 && value.toString().trim() !== value2.toString().trim() ? `Hesla se neshodují` : "";
}
export function selectValueRequired(value, name) {
  return parseInt(value) === 0 ? `Vyplňte pole ${name}` : "";
}

export function required(value, name) {
  return !value || (value && value.toString().trim().length === 0) ? `Vyplňte pole ${name}` : "";
}

export function min(value, number, name) {
  return !value || (value && value.toString().trim().length < number) ? `Pole ${name} musí mít minimálně ${number} ${getNumberPostfix(number)}` : "";
}

export function exact(value, number, name) {
  return !value || (value && value.toString().trim().length !== number) ? `Pole ${name} musí mít ${number} ${getNumberPostfix(number)}` : "";
}

export function isNumeric(value, name) {
  if (typeof value != "string") return false; // we only process strings!
  const isNumeric =
    !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(value)); // ...and ensure strings of whitespace fail

  if (!isNumeric) {
    return `Pole ${name} není číslo`;
  } else {
    return "";
  }
}

export function isNumericGreatherThan(value, name, greatherThan) {
  const ret = isNumeric(value, name);
  if (!ret) {
    if (value > greatherThan) {
      return "";
    } else {
      return `Pole ${name} je musí být větší než ${greatherThan}`;
    }
  }
  return ret;
}

function getNumberPostfix(number) {
  let postfix = "znaků";

  switch (number) {
    case 1:
      postfix = "znak";
      break;
    case 2:
    case 3:
    case 4:
      postfix = "znaky";
      break;
  }
  return postfix;
}
