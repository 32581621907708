import React, { useEffect, useContext, useState } from "react";
import * as Const from "../../_Const";
import useJquery from "react-use-jquery";
import BlackQuote from "../../Components/BlackQuote";
import initDataTable from "../../Components/DataTable";
import * as MS from "../../Services/MenuService";
import * as RS from "../../Services/RenderService";
import * as VS from "../../Services/ValidatorService";
import { AppContext } from "../../Context/AppContext";
import * as TeamService from "../../Services/data/TeamService";
import SectionInfo from "../../Components/SectionInfo";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await TeamService.saveOrder(appContext.tenantId, setViewToModel(data));
      MS.goToPage(Const.ID_TEAM);
      RS.toastSave("Změna pořadí položek Náš tým úspěšně provedena");
    } catch (ex) {
      let error = "Nastala chyba při ukládání pořadí položek týmu";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function setViewToModel(data) {
  if (!data) {
    return null;
  }
  let vm = [];
  data.map((item) =>
    vm.push({
      id: item.id,
      tenantId: appContext.tenantId,
      order: item.order,
    })
  );

  return vm;
}

function validateChildComponent() {
  return true;
}

function validateFormItem(name, value) {}

async function deleteItem(e, id) {
  e.stopPropagation();
  try {
    await TeamService.deleteData(parseInt(appContext.tenantId), parseInt(id));
    setData(await populateData(appContext.tenantId));
    RS.toastDelete();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

async function populateData(id) {
  let { data: item } = await TeamService.getData(parseInt(id));

  console.log(item);
  return item;
}

function editItem(e, id) {
  e.stopPropagation();
  MS.goToEditFormPage(Const.ID_TEAM, id);
  console.log(id);
}

function getImage(item) {
  if (item) {
    if (item.image === womanNoImage) {
      item.imageSrc = womanNoImage;
    } else if (item.image === manNoImage) {
      item.imageSrc = manNoImage;
    }
  }

  let renderedImage = item.imageSrc;
  if (!renderedImage) {
    renderedImage = manNoImage;
  }
  return renderedImage;
}

function setDragging(e) {
  //console.log("dragging", e.target.innerText);
  dragging = parseInt(e.currentTarget.id);
}

function setDraggedOver(e) {
  e.preventDefault();
  //  console.log("drag over", e.target.innerText);
  draggedOver = parseInt(e.currentTarget.id);
}

function compare(e) {
  let newState = data.sort((a, b) => a.order - b.order);
  const dragItem = data.find((x) => parseInt(x.id) === dragging);
  const dropItem = data.find((x) => parseInt(x.id) === draggedOver);
  var dragItemIndex = newState.findIndex((x) => parseInt(x.id) === dragging);
  var dropItemIndex = newState.findIndex((x) => parseInt(x.id) === draggedOver);

  //nelze zanorit sama do sebe
  if (dragItem.id === dropItem.parentId) {
    RS.toastError("Nelze přesunout položku samu do sebe");
    return;
  }

  newState.splice(dragItemIndex, 1);
  newState.splice(dropItemIndex, 0, dragItem);

  newState = newState.map((item, index) => {
    //nastaveni stejne urovne zanoreni
    if (item.id === dragItem.id) {
      item.parentId = dropItem.parentId;
    }
    item.order = index + 1;
    return item;
  });

  setData(newState);

  submitHandler(e);
}

function needToSaveSectionDataHandler() {
  RS.toastInfo("Nejprve uložte titulek sekce pomocí tlačítka Uložit záznam", 5000);
  setIsSectionDisabled(true);
}

const manNoImage = "/assets/img/noimage/ic_man_no_image_256.png";
const womanNoImage = "/assets/img/noimage/ic_woman_no_image_256.png";
let [appContext, setAppContext] = [2];
let [data, setData] = [2];

let [isSectionDisabled, setIsSectionDisabled] = [2];

let dragging, draggedOver;

function PageTeamIndex() {
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  const $ = useJquery();
  [appContext, setAppContext] = useContext(AppContext);
  [data, setData] = useState(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);

        if ($) {
          initDataTable($);
          $("#myTable").DataTable();
        }
      }
    }
    scopedLoadData();
  }, []);

  if (!data) {
    return "";
  }

  let title = "Náš tým";

  return (
    <React.Fragment>
      <BlackQuote title={title}>
        Představte jednotlivé členy týmu na stránce <b>Náš tým</b>
      </BlackQuote>
      <SectionInfo
        sectionCode={Const.ID_TEAM}
        title={title}
        showEditor={true}
        showSaveBtn={true}
        onNeedToSaveSection={needToSaveSectionDataHandler}
        onAfterSaveSection={() => setIsSectionDisabled(false)}
      />
      <div className={RS.renderDisabledCss("", isSectionDisabled)}>
        <div className="mt-4">{RS.renderTitle("Členové týmu")}</div>
        <div className="mt-2 ml-3">
          {RS.renderHint("Změnu pořadí členů týmu provedete přetažením člena týmu doleva či doprava, pořadí se uloží automaticky")}
          <div className="mt-2">{RS.renderAddButton(() => MS.goToCreatePage(Const.ID_TEAM))}</div>
        </div>
        <div className="row mt-4 ml-2 mb-4">
          {data
            .sort((a, b) => a.order - b.order)
            .map((item) => (
              <>
                <Card
                  draggable={true}
                  id={item.id}
                  onDragOver={setDraggedOver}
                  onDragStart={setDragging}
                  onDrop={compare}
                  className="team-card btn d-flex flex-column dragable-item"
                  onClick={(e) => editItem(e, item.id)}
                >
                  <div className="image-cropper mx-auto mt-2">
                    <img src={getImage(item)} className="cirular-img" />
                  </div>

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.name}
                    </Typography>
                    <div className="mt-1">{RS.renderSubTitle(item.position)}</div>

                    {item.phone && (
                      <div className="phone-mail mt-2 mx-n4">
                        <i className="fas fa-phone-alt ml-0 mr-2"></i>
                        {RS.renderSubTitle(item.phone)}
                      </div>
                    )}

                    {item.email && (
                      <div className="phone-mail mx-n4">
                        <i className="fas fa-envelope ml-0 mr-2"></i>
                        {RS.renderSubTitle(item.email)}
                      </div>
                    )}
                  </CardContent>

                  <CardActions className="mt-auto align-self-center">
                    {RS.renderEditIcon((e) => editItem(e, item.id))}

                    {RS.renderDeleteIcon((e) => deleteItem(e, item.id))}
                  </CardActions>
                </Card>
                {/* <Table columns={columns} data={data} /> */}
              </>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageTeamIndex;
