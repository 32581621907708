import axios from "axios"; //axios@0.18
import * as log from "./LogService";
import * as RS from "./RenderService";
import * as Const from "./../_Const";

let unAuthorizeCallback = null;

axios.defaults.baseURL = Const.API_URL;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    RS.setLoading();
    return config;
  },
  function (error) {
    RS.setLoading(false);
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    RS.setLoading(false);
    return response;
  },
  (error) => {
    RS.setLoading(false);
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!expectedError) {
      RS.toastError("Nastala chyba při komunikaci se serverem");
      log.e("Nastala chyba při komunikaci se serverem e:", error);
    }

    console.log("httpservice check 401", error);
    if (error.response && error.response.status === 401) {
      console.log("httpservice 401");
      log.e("Při komunikaci se serverem byla zjištěna chyba autorizace: je nutné se znovu přihlásit", error);
      if (unAuthorizeCallback) {
        console.log("httpservice check 401 call calback");
        unAuthorizeCallback();
      }
    }

    return Promise.reject(error);
  }
); //null znamena intercept success, v tomto pripade nepotrbuji, mozna pro logovani v realne aplikaci

function setJwt(token) {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function setUnAuthorizeCallback(callback) {
  unAuthorizeCallback = callback;
}

export default {
  get: axios.get,
  put: axios.put,
  delete: axios.delete,
  post: axios.post,
  setJwt,
  setUnAuthorizeCallback,
};
