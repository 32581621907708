import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as BranchService from "../../Services/data/BranchService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";
import MultiItemForm from "../../Components/MultiItemForm";
import SectionInfo from "../../Components/SectionInfo";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      setionInfoChildRef.current.save(false);
      const result = await BranchService.save(setViewToModel(data));
      //po pouzit POST nastavim nove ziskane ID do aktualniho state data
      const id = data.id ? data.id : 0;
      if (parseInt(id) === 0) {
        setData({ ...data, id: result.data.id });
      }
      setIsEditedData(false);
      MS.goToPage(Const.ID_CONTACT);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Kontakt";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function changePhoneHandler(event, index) {
  const { value } = event.target;
  validatePhoneItem(value, index);
  setIsEditedData(true);
}

function changeEmailHandler(event, index) {
  const { value } = event.target;
  validateEmailItem(value, index);
  setIsEditedData(true);
}

function removeEmailItemHandler(index) {
  const list = [...data.email];
  list.splice(index, 1);
  setData({ ...data, email: list });
  setIsEditedData(true);
}

function removePhoneItemHandler(index) {
  const list = [...data.phone];
  list.splice(index, 1);
  setData({ ...data, phone: list });
  setIsEditedData(true);
}

function addEmailItemHandler() {
  const list = [...data.email];
  list.push({ text: "", error: "" });
  setData({ ...data, email: list });
  setIsEditedData(true);
}

function addPhoneItemHandler() {
  const list = [...data.phone];
  list.push({ text: "", error: "" });
  setData({ ...data, phone: list });
  setIsEditedData(true);
}

function validateChildComponent() {
  let isChildValid = true;

  const isSectionValid = setionInfoChildRef.current.validate();
  if (isChildValid) {
    isChildValid = isSectionValid;
  }

  Object.entries(data.email).forEach(([, value], index) => {
    const isValid = validateEmailItem(value.text, index);
    //jen kdyz je stale validni tak koroluju validitu tohoto itemu, jinak vrati valid false, ale potrebuju projet vsechny prvky
    if (isChildValid) {
      isChildValid = isValid;
    }
  });

  Object.entries(data.phone).forEach(([, value], index) => {
    const isValid = validatePhoneItem(value.text, index);
    //jen kdyz je stale validni tak koroluju validitu tohoto itemu, jinak vrati valid false, ale potrebuju projet vsechny prvky
    if (isChildValid) {
      isChildValid = isValid;
    }
  });

  return isChildValid;
}

function editorContentBlurHandler(content) {
  validateEditorError(content.target.innerText);
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  validateEditorError(content);
  setIsEditedData(true);
}

function validateEditorError(value) {
  //neni treba validovat text neni povinny
  // let errors = data.errors;
  // errors["text"] = VS.required(value, "Text");
  // setData({ ...data, errors });
  // const isValid = data.errors["text"].length === 0;
  return true;
}

function validateEmailItem(value, index) {
  let isValid = true;
  const list = [...data.email];

  if (value) {
    list[index].text = value;
    list[index].error = VS.validateEmail(value);
    isValid = list[index].error.length === 0;
  } else {
    list[index].error = "";
    list[index].text = value;
  }

  setData({ ...data, email: list });
  return isValid;
}

function validatePhoneItem(value, index) {
  let isValid = true;
  const list = [...data.phone];

  if (value) {
    list[index].text = value;
    list[index].error = VS.validatePhone(value);
    isValid = list[index].error.length === 0;
  } else {
    list[index].error = "";
    list[index].text = value;
  }

  setData({ ...data, phone: list });
  return isValid;
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "street":
      errors[name] = VS.required(value, "Ulice");
      break;
    case "city":
      errors[name] = VS.required(value, "Město");
      break;
    case "zip":
      errors[name] = VS.exact(value, 5, "PSČ");
      break;
    case "fbLink":
      if (value) {
        errors[name] = VS.validateUrl(value, "Facebook");
      } else {
        errors[name] = "";
      }
      break;
    case "igLink":
      if (value) {
        errors[name] = VS.validateUrl(value, "Instagram");
      } else {
        errors[name] = "";
      }
      break;
    case "ytLink":
      if (value) {
        errors[name] = VS.validateUrl(value, "YouTube");
      } else {
        errors[name] = "";
      }
      break;
    case "twLink":
      if (value) {
        errors[name] = VS.validateUrl(value, "Twitter");
      } else {
        errors[name] = "";
      }
      break;
    case "lat":
      if (value) {
        errors[name] = VS.validateLat(value);
      } else {
        errors[name] = "";
      }
      break;
    case "lon":
      if (value) {
        errors[name] = VS.validateLon(value);
      } else {
        errors[name] = "";
      }
      break;
    default:
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId) {
  /*if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }*/

  let { data: item } = await BranchService.getItem(parseInt(tenantId));
  return getViewFromModel(item);
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      street: item.street,
      city: item.city,
      zip: item.zip,
      lat: item.lat,
      lon: item.lon,
      fbLink: item.fbLink,
      ytLink: item.ytLink,
      igLink: item.igLink,
      twLink: item.twLink,
      email: item.email,
      phone: item.phone,
      text: item.text,
      errors: {
        city: "",
        zip: "",
        lat: "",
        lon: "",
        fbLink: "",
        ytLink: "",
        igLink: "",
        twLink: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      street: "",
      city: "",
      zip: "",
      lat: "",
      lon: "",
      fbLink: "",
      ytLink: "",
      igLink: "",
      twLink: "",
      text: "",
      email: [{ text: "", error: "" }],
      phone: [{ text: "", error: "" }],
      errors: {
        city: "",
        zip: "",
        lat: "",
        lon: "",
        fbLink: "",
        ytLink: "",
        igLink: "",
        twLink: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    street: data.street,
    city: data.city,
    zip: data.zip,
    lat: data.lat,
    lon: data.lon,
    phone: data.phone,
    email: data.email,
    fbLink: data.fbLink,
    ytLink: data.ytLink,
    igLink: data.igLink,
    twLink: data.twLink,
    text: editorContent,
  };
  return vm;
}

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];

let setionInfoChildRef;
let [isSectionDisabled, setIsSectionDisabled] = [2];
let [editorContent, setEditorContent] = [2];

function PageContactForm(props) {
  setionInfoChildRef = useRef();
  [isSectionDisabled, setIsSectionDisabled] = useState(false);

  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorContent, setEditorContent] = useState(null);
  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setEditorContent(result.text);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Kontakt";
  let subTitle = "Pomocí této stránky vyplníte";

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b> kontaktní informace o Vaší ordinaci.</b> Tyto informace pomůžou pacientům v případě, když Vás budou chtít kontaktovat nebo zjistit kde se
        nalézá Vaše ordinace
      </BlackQuote>

      <SectionInfo
        ref={setionInfoChildRef}
        sectionCode={Const.ID_CONTACT}
        title={title}
        showEditor={true}
        onDataChanged={() => setIsEditedData(true)}
        onDataLoaded={() => setIsEditedData(false)}
        onSectionEnabledChanged={(isDisabled) => setIsSectionDisabled(isDisabled)}
      />

      {RS.renderTitle("Informace o ordinaci", "mt-4")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <div className={RS.renderDisabledCss("", isSectionDisabled)}>
            <MDBRow>
              <MDBCol md="4">{RS.renderInputText("street", "Ulice", data, changeHandler)}</MDBCol>
              <MDBCol md="4">{RS.renderInputText("city", "Město", data, changeHandler)}</MDBCol>
              <MDBCol md="4">{RS.renderInputText("zip", "PSČ", data, changeHandler)}</MDBCol>
            </MDBRow>

            <MDBRow className="mt-2">
              <MDBCol md="4">{RS.renderInputText("lat", "GPS šířka (latitude)", data, changeHandler)}</MDBCol>
              <MDBCol md="4">{RS.renderInputText("lon", "GPS délka (longitude)", data, changeHandler)}</MDBCol>
            </MDBRow>

            <MDBRow className="mt-4 ml-2">
              <MDBCol md="8">
                {RS.renderSubTitle("Jak se k Vám pacient dostane - info o budově, patře, atd...")}
                {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
              </MDBCol>
            </MDBRow>

            {RS.renderTitle("Odkazy na sociální sítě", "mt-4")}
            <MDBRow className="mt-2">
              <MDBCol md="3">{RS.renderInputText("fbLink", "Facebook", data, changeHandler)}</MDBCol>
              <MDBCol md="3">{RS.renderInputText("igLink", "Instagram", data, changeHandler)}</MDBCol>
              <MDBCol md="3">{RS.renderInputText("twLink", "Twitter", data, changeHandler)}</MDBCol>
              <MDBCol md="3">{RS.renderInputText("ytLink", "YouTube", data, changeHandler)}</MDBCol>
            </MDBRow>

            <MDBRow className="mt-4">
              <MDBCol md="12">{RS.renderTitle("Email do ordinace")}</MDBCol>
              <MDBCol md="12">
                <MultiItemForm
                  label="E-mail"
                  name="email"
                  data={data.email}
                  onAddItem={addEmailItemHandler}
                  onRemoveItem={removeEmailItemHandler}
                  onItemChange={changeEmailHandler}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-4">
              <MDBCol md="12">{RS.renderTitle("Telefonní číslo do ordinace")}</MDBCol>
              <MDBCol md="12">
                <MultiItemForm
                  label="Telefon"
                  name="phone"
                  data={data.phone}
                  onAddItem={addPhoneItemHandler}
                  onRemoveItem={removePhoneItemHandler}
                  onItemChange={changePhoneHandler}
                />
              </MDBCol>
            </MDBRow>
          </div>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageContactForm;
