import React, { Fragment, useState, useContext, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as PriceListService from "../../Services/data/PriceListService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await PriceListService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_PRICING);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Ceník";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function validateChildComponent() {
  let isChildValid = true;

  Object.entries(data.items).forEach(([, value], index) => {
    const isValid = validateListItem("title" + index, value.title, index);
    //jen kdyz je stale validni tak koroluju validitu tohoto itemu, jinak vrati valid false, ale potrebuju projet vsechny prvky
    if (isChildValid) {
      isChildValid = isValid;
    }
    const isValidPrice = validateListItem("price" + index, value.price, index);
    if (isChildValid) {
      isChildValid = isValidPrice;
    }
  });

  return isChildValid;
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function changeListItemHandler(event, index) {
  const { value, name } = event.target;
  validateListItem(name, value, index);
  setIsEditedData(true);
}

function validateListItem(name, value, index) {
  let isValid = true;
  const list = [...data.items];

  const replacedName = name.replace(index + "", "");

  switch (replacedName) {
    case "title":
      list[index].title = value;
      data.errors[name] = VS.required(value, "Položka");
      break;
    case "price":
      list[index].price = value;
      data.errors[name] = VS.required(value, "Cena");
      break;
  }

  isValid = data.errors[name].length === 0;

  setData({ ...data, items: list });

  return isValid;
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "title":
      errors[name] = VS.required(value, "Název ceníku");
      break;
    default:
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }

  let { data: item } = await PriceListService.getItem(parseInt(tenantId), parseInt(id));
  return getViewFromModel(item);
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      validFrom: item.validFrom,
      title: item.title,
      items: item.items,
      errors: {
        defaultErrorObject,
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      title: "",
      validFrom: "",
      items: [{ title: "", price: "" }],
      errors: {
        defaultErrorObject,
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    validFrom: data.validFrom,
    title: data.title,
    items: data.items,
  };
  return vm;
}

function removeItemHandler(index) {
  const list = [...data.items];
  list.splice(index, 1);
  setData({ ...data, items: list });
  setIsEditedData(true);
}

function addItemHandler() {
  const list = [...data.items];
  list.push({ title: "", price: "" });
  setData({ ...data, items: list });
  setIsEditedData(true);
}

function renderListItem(localData) {
  let disabledDelete = false;
  if (!localData) {
    return null;
  }

  if (localData.length === 1) {
    disabledDelete = true;
  }

  return (
    <>
      {localData.map((x, i) => {
        return (
          <MDBRow className="mt-2 ml-3" key={i}>
            <MDBCol md="6" sm="6" className="price-item-container">
              {RS.renderInput("title" + i, "Položka", x.title, (e) => changeListItemHandler(e, i), "text", data.errors["title" + i], false, "price-item")}
            </MDBCol>
            <MDBCol md="3" sm="2" className="price-item-container">
              {RS.renderInput("price" + i, "Cena", x.price, (e) => changeListItemHandler(e, i), "text", data.errors["price" + i], false, "price-item")}
            </MDBCol>
            <MDBCol md="2" sm="2">
              <div className="float-left mt-1">{RS.renderDeleteIcon(() => removeItemHandler(i), disabledDelete)}</div>
              {localData.length - 1 === i && <div className="float-left mt-1">{RS.renderAddIcon(() => addItemHandler())}</div>}
            </MDBCol>
          </MDBRow>
        );
      })}
    </>
  );
}

const defaultErrorObject = { title0: "", price0: "", title: "" };
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];
function PagePriceListForm(props) {
  const paramId = props.match.params.id;
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, paramId);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Ceník editace záznamu";
  let subTitle = "Pomocí této stránky editujete vytvořený";
  if (MS.isNewItemLink(paramId)) {
    title = "Ceník nový záznam";
    subTitle = "Pomocí této stránky přidáte nový";
  }

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>ceník služeb a výkonů</b>
      </BlackQuote>

      {RS.renderTitle("Ceník")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="mt-1">
            <MDBCol md="6">{RS.renderInputText("title", "Název ceníku", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="4">{RS.renderInputText("validFrom", "Platnost ceníku od", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4">{RS.renderTitle("Položky ceníku")}</div>
          {renderListItem(data.items)}

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PagePriceListForm;
