import React from "react";

function BlackQuote(props) {
  const { title, children } = props;

  return (
    <blockquote className="blockquote bq-info grey lighten-5 mt-4">
      <p className="bq-title">{title}</p>
      <p>{children}</p>
    </blockquote>
  );
}

export default BlackQuote;
