import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as FilesService from "../../Services/data/FilesService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await FilesService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_FILES);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Soubor ke stažení";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  return true;
}

function validateFormItem(name, value) {
  let errors = data.errors;
  // switch (name) {
  //   case "name":
  //     errors[name] = VS.required(value, "Pojmenování souboru");
  //     break;
  //   default:
  //     break;
  // }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }

  let { data: item } = await FilesService.getItem(parseInt(tenantId), parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      title: item.title,
      fileName: item.fileName,
      fileNameOriginal: item.fileNameOriginal,
      fileOriginal: item.fileOriginal,
      fileSrc: item.fileSrc,
      file: "",
      errors: {
        title: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      title: "",
      fileName: "",
      fileNameOriginal: "",
      fileOriginal: "",
      fileSrc: "",
      file: "",
      errors: {
        title: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("tenantId", data.tenantId);
  formData.append("title", data.title);
  formData.append("fileName", data.fileName);
  formData.append("fileNameOriginal", data.fileNameOriginal);
  formData.append("fileOriginal", data.fileOriginal);
  formData.append("file", data.file);

  return formData;
}

function deleteImage() {
  if (data.fileSrc) {
    setData({
      ...data,
      fileName: null,
      fileNameOriginal: null,
      file: null,
      fileSrc: null,
      title: null,
    });
    setIsEditedData(false);
  }
}

function showPreview(e) {
  if (e.target.files && e.target.files[0]) {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setData({
        ...data,
        fileName: null,
        fileNameOriginal: file.name,
        file: file,
        fileSrc: x.target.result,
      });
      setIsEditedData(true);
    };
    reader.readAsDataURL(file);
  }
}

function getRenderedImageOfFile(fileName) {
  let image = noFile;
  if (fileName) {
    const suffix = "_small.png";
    const ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    switch (ext) {
      case "xls":
      case "xlsx":
      case "gsheet":
        image = basePath + "xls" + suffix;
        break;
      case "doc":
      case "docx":
      case "gdoc":
        image = basePath + "doc" + suffix;
        break;
      case "txt":
      case "text":
        image = basePath + "txt" + suffix;
        break;
      case "zip":
        image = basePath + "zip" + suffix;
        break;
      case "pdf":
        image = basePath + "pdf" + suffix;
        break;
      default:
        break;
    }
  }

  return image;
}

const noFile = "/assets/img/files/ic_file_unkonwn_small.png";
const basePath = "/assets/img/files/ic_file_";

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];

function PageFilesForm(props) {
  const paramId = props.match.params.id;
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, paramId);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Soubory ke stažení editace záznamu";
  let subTitle = "Pomocí této stránky editujete vytvořený";
  if (MS.isNewItemLink(paramId)) {
    title = "Soubory ke stažení nový záznam";
    subTitle = "Pomocí této stránky přidáte nový";
  }
  /*
  let fileNameOriginal = data.fileNameOriginal;
  if (data.fileSrc) {
    fileNameOriginal = data.file.name;
  }*/
  //console.log(fileNameOriginal, data);
  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>soubor</b>
      </BlackQuote>

      {RS.renderTitle("Soubor ke stažení")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          {!data.fileSrc && (
            <MDBRow className="ml-2 mt-2">
              <MDBCol>
                <label className="btn btn-outline-primary blog-image-btn ">
                  <i className="fa fa-user"></i> Vložit soubor
                  <input type="file" name="image" accept=".zip,.rar,.xlsx,.xls,.gsheet,.doc,.docx,.gdoc,.txt,.pdf" className="d-none" onChange={showPreview} />
                </label>
              </MDBCol>
            </MDBRow>
          )}

          {data.fileSrc && (
            <>
              <MDBRow className="ml-2 mt-4">
                <MDBCol md="6">
                  <img src={getRenderedImageOfFile(data.fileNameOriginal)} className="mx-2" />
                  {data.fileNameOriginal}
                </MDBCol>

                <MDBCol>
                  <label className="btn btn-outline-danger blog-image-btn mt-n2 " onClick={() => deleteImage()}>
                    <i className="fa fa-trash"></i> Odebrat soubor
                  </label>
                </MDBCol>
              </MDBRow>

              <MDBRow className="mt-2">
                <MDBCol md="8">{RS.renderInputText("title", "Pojmenování souboru", data, changeHandler)}</MDBCol>
              </MDBRow>
              <MDBRow className="ml-2">
                <MDBCol md="8">
                  <div className="mt-3">
                    {RS.renderHint(
                      "Jedná se o text který uvidí uživatel v seznamu souborů ke stažení. Napřiklad fyzicky se soubor jmenuje souhlas-ockovani.doc ale soubor může být z důvodu lepší čitelnosti pojmenován jako Souhlas k očkování."
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          )}

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageFilesForm;
