import http from "../HttpService";

const controller = "/openinghour/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function deleteData(tenantId) {
  return http.delete(getUrl(tenantId));
}

export function save(item) {
  if (item.id) {
    console.log("save opening hour put ", item);
    return http.put(controller, item);
  } else {
    console.log("save opening hour post ", item);
    return http.post(controller, item);
  }
}

export function getDayItem(localData, dayId) {
  if (localData && localData.openingHours && localData.openingHours.length > 0) {
    return localData.openingHours.find((p) => p.dayId === dayId);
  } else {
    return getDefaultDayItem(dayId);
  }
}

export function getDefaultDayItem(dayId) {
  return {
    dayId: dayId,
    isClosed: true,
    isOpen24: false,
    times: null,
  };
}

/*
const data = [
  {
    id: 1,
    tenantId: 30,
    openingHours: [
      {
        dayId: 1,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: null,
            start: "7:00",
            end: "15:00",
          },
        ],
      },
      {
        dayId: 2,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: null,
            start: "7:00",
            end: "12:00",
          },
          {
            label: "Pro objednané",
            start: "13:00",
            end: "17:00",
          },
        ],
      },
      {
        dayId: 3,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: "Odběry",
            start: "7:00",
            end: "7:30",
          },
          {
            label: null,
            start: "7:30",
            end: "12:00",
          },
          {
            label: "Objednaní pacienti",
            start: "12:30",
            end: "15:00",
          },
        ],
      },
      {
        dayId: 4,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: "Odběry",
            start: "7:00",
            end: "7:30",
          },
          {
            label: null,
            start: "7:30",
            end: "12:00",
          },
          {
            label: "Sudé týdny - Objednaní pacienti",
            start: "12:30",
            end: "15:00",
          },
          {
            label: "Liché týdny - Poradna diabetologie",
            start: "12:30",
            end: "15:00",
          },
          {
            label: "Závodní péče",
            start: "17:30",
            end: "19:00",
          },
        ],
      },
      {
        dayId: 5,
        isClosed: false,
        isOpen24: false,
        times: [
          {
            label: "Akutní případy",
            start: "7:00",
            end: "12:00",
          },
          {
            label: "Pro objednané",
            start: "13:00",
            end: "17:00",
          },
        ],
      }, 
      {
        dayId: 6,
        isClosed: true,
        isOpen24: false,
        times: null,
      },
      {
        dayId: 7,
        isClosed: true,
        isOpen24: false,
        times: null,
      },
    ],
  },
];

export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.tenantId === id);
}

export function getDayItem(localData, dayId) {
  if (localData && localData.openingHours) {
    return localData.openingHours.find((p) => p.dayId === dayId);
  }
  return null;
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
