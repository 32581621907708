import React, { useEffect, useContext, useState } from "react";
import * as Const from "../../_Const";
import useJquery from "react-use-jquery";
import BlackQuote from "../../Components/BlackQuote";
import initDataTable from "../../Components/DataTable";
import Table from "../../Components/Table";
import * as MS from "../../Services/MenuService";
import * as RS from "../../Services/RenderService";
import { AppContext } from "../../Context/AppContext";
import * as FaqService from "../../Services/data/FaqService";
import SectionInfo from "../../Components/SectionInfo";

const columns = [
  // { path: "id", label: "ID" },
  { path: "question", label: "Otázka", content: (item) => RS.textShortener(RS.stripHtml(item.question), 150) },
  { path: "answer", label: "Odpověď", content: (item) => RS.textShortener(RS.stripHtml(item.answer), 150) },

  {
    path: "id",
    label: "Akce",
    content: (item) => (
      <React.Fragment>
        {RS.renderEditIcon(() => editItem(item.id))}
        {RS.renderDeleteIcon(() => deleteItem(item.id))}
      </React.Fragment>
    ),
  },
];

async function deleteItem(id) {
  try {
    await FaqService.deleteData(parseInt(appContext.tenantId), parseInt(id));
    setData(await populateData(appContext.tenantId));
    RS.toastDelete();
  } catch (ex) {
    let error = "Nastala chyba při mazání dat";
    console.log(ex.response);

    if (error) {
      RS.toastError(error);
    }
  }
}

async function populateData(id) {
  let { data: item } = await FaqService.getData(parseInt(id));
  return item;
}

function editItem(id) {
  MS.goToEditFormPage(Const.ID_FAQ, id);
  //console.log(id);
}

function needToSaveSectionDataHandler() {
  RS.toastInfo("Nejprve uložte titulek sekce pomocí tlačítka Uložit záznam", 5000);
  setIsSectionDisabled(true);
}

let [appContext, setAppContext] = [2];
let [data, setData] = [2];
let [isSectionDisabled, setIsSectionDisabled] = [2];

function PageFaqIndex() {
  [isSectionDisabled, setIsSectionDisabled] = useState(false);
  const $ = useJquery();
  [appContext, setAppContext] = useContext(AppContext);
  [data, setData] = useState(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);

        if ($) {
          initDataTable($);
          $("#myTable").DataTable();
        }
      }
    }
    scopedLoadData();
  }, []);

  let title = "Otázky a odpovědi";

  return (
    <React.Fragment>
      <BlackQuote title={title}>
        <b>Otázky a odpovědi</b> jsou prvkem webových stránek, kterým je umožněno zodpovědět časté otázky pacientů Vaší ordinace
      </BlackQuote>
      <SectionInfo
        sectionCode={Const.ID_FAQ}
        title={title}
        showSaveBtn={true}
        showEditor={true}
        onNeedToSaveSection={needToSaveSectionDataHandler}
        onAfterSaveSection={() => setIsSectionDisabled(false)}
      />

      <div className={RS.renderDisabledCss("", isSectionDisabled)}>
        <div className="mt-4">{RS.renderAddButton(() => MS.goToCreatePage(Const.ID_FAQ))}</div>

        <div className="row">
          <div className="col-12">
            <Table columns={columns} data={data} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageFaqIndex;
