import http from "../HttpService";

const controller = "/blog/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  let id = item.get("id");

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  if (parseInt(id) > 0) {
    console.log("save blog put ", item);
    return http.put(getUrl(id), item, config);
  } else {
    console.log("save blog post ", item);
    return http.post(controller, item, config);
  }
} /*


/*import * as Helper from "../../_Helper";

const data = [
  {
    id: 4,
    tenantId: 6,
    pinned: true,
    date: "2019-09-30",
    title: "Omezení provozu ordinace",
    text:
      "Z technických důvodů je od 11.9. prozatímně ukončen pondělní a středeční dopolední provoz, ambulance v pondělí je až od 13 hod a ve středu od 11 hod. Dne 18.9. je ordinace pouze od 8 - 13 hod. Ve dnech 19. - 20.9. je ambulance uzavřena.",
    imageSrc: "https://picsum.photos/id/250/250/250",
  },
  {
    id: 3,
    tenantId: 6,
    pinned: false,
    date: "2019-09-20",
    title: "Registrace nových pacientů je zatím pozastavena",
    text: "V současné době nepřijímáme nové pacienty.",
    imageSrc: "https://picsum.photos/id/2/250/250",
  },
  {
    id: 2,
    tenantId: 6,
    pinned: false,
    date: "2019-09-10",
    title: "Nový pořadový systém do čekárny",
    text:
      "Vážení paienti, tímto vám chceme oznámit, že od 10.9.2019 se v čekárně nachází nový pořadový systém do čekárny VložteKartičku.cz. Tento systém zrychluje a zkvalitňuje naši práci a zkracuje dobu čekání na vyšetření.",
    imageSrc: null,
  },
  {
    id: 1,
    tenantId: 6,
    pinned: false,
    date: "2019-08-08",
    title: "Možnost objednat se do ordinace na konkrétní čas",
    text:
      "Vážení pacienti, ke zkvalitnění Vám od 1.9.2019 nábízíme možnost telefonické objednávky. Telefonem (sms nebo telefonicky) či emailem si domluvte schůzku na čas, který Vám vyhovuje. Nejvhodnější způsob je zaslat sms nebo email s důvodem návštěvy (pro minimalizaci kontaktu zdravých a nemocných) a s časovým rozmezím, které by Vám vyhovovalo. Následně Vám bude čas zpětně potvrzen nebo se domluvíme k upřesnění termínu. ",
    imageSrc: "https://picsum.photos/id/160/250/250",
  },
];

export function getData(tenantId) {
  return data.filter((m) => m.tenantId === tenantId).sort((a, b) => b.id - a.id);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
