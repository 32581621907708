import React from "react";
import * as Helper from "../_Helper";

function THead(props) {
  const { data } = props;

  return (
    <thead>
      <tr key={Helper.createKey()}>{data && data.map((th) => <th key={Helper.createKey()}>{th.label}</th>)}</tr>
    </thead>
  );
}

export default THead;
