import jwtDecode from "jwt-decode";
import http from "./HttpService";
import * as MS from "./MenuService";
import * as Const from "../_Const";

const controller = "/auth/";
const tokenKey = "token";

function getRegisterUrl() {
  return `${controller}register`;
}

function getLoginUrl() {
  return `${controller}login`;
}

//server secret bude zatim "kral-karel-4-jel-do-velke-bitvy-na-svem-koni"
//bidirectional dependency
http.setJwt(getJwt());

http.setUnAuthorizeCallback(handle401);

function handle401() {
  console.log("handle401");
  logout();
  window.location = MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGIN);
}

export async function changePassword(email) {
  let isValid = true;

  return isValid;
}

export async function register(firstName, surname, email, password, phone, ic) {
  const { data } = await http.post(getRegisterUrl(), {
    firstName,
    surname,
    email,
    password,
    phone,
    ic,
  });
  if (data.token) {
    //save jwt token
    localStorage.setItem(tokenKey, data.token);
  }
}

export async function login(email, password) {
  const { data } = await http.post(getLoginUrl(), {
    email,
    password,
  });
  if (data.token) {
    //save jwt token
    localStorage.setItem(tokenKey, data.token);
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  //console.log("getCurrentUser");
  try {
    const jwt = localStorage.getItem(tokenKey);
    var decodedToken = jwtDecode(jwt);
    if (decodedToken.isExpired) {
      console.error("Token vyprsel");
      return null;
    }
    return decodedToken;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  const jwt = localStorage.getItem(tokenKey);
  //console.log("AuthService.getJwt", jwt);
  return jwt;
}

export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
};
