import React, { useContext } from "react";
import { AppContext } from "./Context/AppContext";
import * as MS from "./Services/MenuService";
import { Link } from "react-router-dom";
import * as Const from "./_Const";

function getClassActive(id) {
  if (appContext.activeId === id) {
    return "nav-link active";
  } else {
    return "nav-link";
  }
}

function handleClick(activeId) {
  document.body.classList.remove("sb-sidenav-toggled");
  setAppContext({ ...appContext, activeId, isToggleOpen: false });
}

// function closeSideNavBtnToggle() {
//   // if (appContext.isToggleOpen) {
//   //   document.body.classList.add("sb-sidenav-toggled");
//   // } else {
//     document.body.classList.remove("sb-sidenav-toggled");
//   //}

//   setAppContext({ ...appContext, isToggleOpen: false });
// }

function renderMenuItems(data) {
  if (!data) {
    return "";
  }
  return (
    <React.Fragment>
      {data.map((item) => (
        <Link key={item.id} className={getClassActive(item.id)} onClick={() => handleClick(item.id)} to={MS.getLinkFromMenuId(item.id)}>
          <div className="sb-nav-link-icon">
            <i className={"fas " + item.icon}></i>
          </div>
          {item.name}
        </Link>
      ))}
    </React.Fragment>
  );
}
let [appContext, setAppContext] = [2];
function LeftMenu() {
  [appContext, setAppContext] = useContext(AppContext);
  const dataSections = MS.getMenuSectionList();
  const dataGeneral = MS.getMenuGeneralSettingsList();

  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav sb-sidenav-light accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link
              key={Const.ID_ACCOUNT_LOGOUT}
              className="btn btn-primary d-block d-sm-none mb-0"
              onClick={() => handleClick(Const.ID_ACCOUNT_LOGOUT)}
              to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGOUT)}
            >
              <i class="fas fa-sign-out-alt mr-2"></i> Odhlásit
            </Link>

            <div className="sb-sidenav-menu-heading">Obecná nastavení</div>
            {renderMenuItems(dataGeneral)}
          </div>
          <div className="nav">
            <div className="sb-sidenav-menu-heading">Editace údajů sekcí</div>
            {renderMenuItems(dataSections)}
          </div>
        </div>
        {appContext.loggedUser && (
          <div className="sb-sidenav-footer">
            <div className="small">Přihlášen jako:</div>
            {appContext.loggedUser}
            {/* {appContext.isAdmin && <div className="small">Administrátor</div>} */}
          </div>
        )}
      </nav>
    </div>
  );
}

export default LeftMenu;
