import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as GeneralSettingsService from "../../Services/data/GeneralSettingsService";
import * as ExternalService from "../../Services/data/ExternalServicesService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";
import * as Helper from "../../_Helper";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      const result = await GeneralSettingsService.save(setViewToModel(data));
      //po pouzit POST nastavim nove ziskane ID do aktualniho state data
      const id = data.id ? data.id : 0;
      if (parseInt(id) === 0) {
        setData({ ...data, id: result.data.id });
      }
      setIsEditedData(false);
      MS.goToPage(Const.ID_GENERAL_SETTINGS);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Obecná nastavení";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  let isChildValid = true;
  return isChildValid;
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "logoTitle":
      errors[name] = VS.required(value, "Titulek");
      break;
    case "heroTitle":
      errors[name] = VS.required(value, "Titulek");
      break;
    case "footerTitle":
      errors[name] = VS.required(value, "Titulek");
      break;
    case "companyName":
      errors[name] = VS.required(value, "Název společnosti");
      break;
    case "street":
      errors[name] = VS.required(value, "Ulice");
      break;
    case "city":
      errors[name] = VS.required(value, "Město");
      break;
    case "companyIc":
      errors[name] = VS.exact(value, 8, "IČ");
      break;
    case "companyDic":
      if (value) {
        errors[name] = VS.exact(value, 12, "DIČ");
      } else {
        errors[name] = "";
      }
      break;
    case "zip":
      errors[name] = VS.exact(value, 5, "PSČ");
      break;
    case "courtText":
      errors[name] = VS.required(value, "Informace o zápisu do resjtříku");
      break;
    default:
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId) {
  let { data: item } = await GeneralSettingsService.getItem(parseInt(tenantId));
  return getViewFromModel(item);
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      logoTitlePrefix: item.logoTitlePrefix,
      logoTitle: item.logoTitle,
      //logoIcon: item.logoIcon,
      heroTitle: item.heroTitle,
      heroSubtitle: item.heroSubtitle,
      //heroImage: item.heroImage,
      footerTitle: item.footerTitle,
      metaTitle: item.metaTitle,
      metaKeyWord: item.metaKeyWord,
      metaDesc: item.metaDesc,
      companyName: item.companyName,
      companyIc: item.companyIc,
      companyDic: item.companyDic,
      street: item.street,
      city: item.city,
      zip: item.zip,
      courtText: item.courtText,
      taxTypeId: 0,
      aresIco: "",
      errors: {
        logoTitle: "",
        heroTitle: "",
        footerTitle: "",
        taxTypeId: "",
        aresIco: "",
        companyName: "",
        companyIc: "",
        companyDic: "",
        street: "",
        city: "",
        zip: "",
        courtText: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      logoTitlePrefix: "",
      logoTitle: "",
      logoIcon: "",
      heroTitle: "",
      heroSubtitle: "",
      heroImage: "",
      footerTitle: "",
      metaTitle: "",
      metaKeyWord: "",
      metaDesc: "",
      companyName: "",
      companyIc: "",
      companyDic: "",
      street: "",
      city: "",
      zip: "",
      courtText: "",
      taxTypeId: 0,
      aresIco: "",
      errors: {
        logoTitle: "",
        heroTitle: "",
        footerTitle: "",
        taxTypeId: "",
        aresIco: "",
        companyName: "",
        companyIc: "",
        companyDic: "",
        street: "",
        city: "",
        zip: "",
        courtText: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    logoTitlePrefix: data.logoTitlePrefix,
    logoTitle: data.logoTitle,
    //logoIcon: data.logoIcon,
    heroTitle: data.heroTitle,
    heroSubtitle: data.heroSubtitle,
    // heroImage: data.heroImage,
    footerTitle: data.footerTitle,
    metaTitle: data.metaTitle,
    metaKeyWord: data.metaKeyWord,
    metaDesc: data.metaDesc,
    companyName: data.companyName,
    companyIc: data.companyIc,
    companyDic: data.companyDic,
    street: data.street,
    city: data.city,
    zip: data.zip,
    courtText: data.courtText,
  };
  return vm;
}

function changeAresHandler(event) {
  const { name, value } = event.target;
  validateAresFormItem(name, value);
}

function validateAresFormItem(name, value) {
  let errors = data.errors;
  let isValid = true;
  switch (name) {
    case "aresIco":
      errors[name] = VS.exact(value, 8, "IČ");
      if (errors[name]) {
        isValid = false;
      }
      break;
  }

  setData({ ...data, errors, [name]: value });
  return isValid;
}

async function getDataFromAres() {
  //nacist z API service
  //validace pred spustenim requestu

  const isValid = validateAresFormItem("aresIco", data.aresIco);

  if (isValid) {
    const result = await ExternalService.getAresItem(appContext.tenantId, data.aresIco);
    if (result && result.data) {
      if (result.data.isError) {
        RS.toastError(`Chyba při načítání dat ze systému ARES ${result.data.errorText}`);
      } else {
        let ad = {};
        RS.toastSuccess("Načtení dat ze systému ARES proběhlo úspěšně");

        ad.companyName = Helper.setEmptyOrGetVal(result.data.nazevFirmy);
        ad.companyIc = Helper.setEmptyOrGetVal(result.data.ic);
        ad.companyDic = Helper.setEmptyOrGetVal(result.data.dic);

        let cislo = `${result.data.sidloCisloDomovni}/${result.data.sidloCisloOrientacni}`;
        if (result.data.sidloCisloDoAdresy) {
          cislo = result.data.sidloCisloDoAdresy;
        }
        ad.street = `${result.data.sidloUlice} ${cislo}`;
        ad.city = Helper.setEmptyOrGetVal(result.data.sidloMesto);
        ad.zip = Helper.setEmptyOrGetVal(result.data.sidloPsc);
        if (result.data.zdroj === "rzp") {
          ad.courtText = `Fyzická osoba zapsaná v živnostenském rejstříku - ${result.data.rzpMagistrat} od ${result.data.datumZapisuVal}`;
        } else {
          ad.courtText = `Právnická osoba zapsaná v obchodním rejstříku - ${result.data.orSoud} pod spisovou značkou ${result.data.orVlozka} od ${result.data.datumZapisuVal}`;
        }

        setData({
          ...data,
          errors: {
            companyName: "",
            companyIc: "",
            companyDic: "",
            street: "",
            city: "",
            zip: "",
            courtText: "",
          },
          companyName: ad.companyName,
          companyIc: ad.companyIc,
          companyDic: ad.companyDic,
          street: ad.street,
          city: ad.city,
          zip: ad.zip,
          courtText: ad.courtText,
        });
        setIsEditedData(true);
      }
    } else {
      RS.toastError("Chyba při načítání dat ze systému ARES");
    }
  }
}

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];

function PageGeneralSettingsForm() {
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
        setIsEditedData(false);
      }
    }
    scopedLoadData();
  }, []);

  let title = "Obecná nastavení";
  let subTitle = "Pomocí této stránky mimo jiné vyplníte";

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b> titulky na hlavní stránce, zákonné informace o vlastníkovi webu</b> a jiné
      </BlackQuote>

      <div>
        <form onSubmit={submitHandler} noValidate>
          {RS.renderTitle("Hlavička a logo", "mt-4")}
          <MDBRow>
            <MDBCol md="4">{RS.renderInputText("logoTitlePrefix", "Prefix titulku", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("logoTitle", "Titulek", data, changeHandler)}</MDBCol>
            {/* <MDBCol md="4">{RS.renderInputText("logoIcon", "Ikona", data, changeHandler)}</MDBCol> */}
          </MDBRow>

          {RS.renderTitle("SEO meta tagy", "mt-4")}
          <MDBRow>
            <MDBCol md="4">{RS.renderInputText("metaTitle", "Titulek stránky", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("metaDesc", "Popis stránky", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("metaKeyWord", "Klíčová slova", data, changeHandler)}</MDBCol>
          </MDBRow>

          {RS.renderTitle("Hlavní sekce s obrázkem", "mt-4")}
          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputText("heroTitle", "Titulek", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("heroSubtitle", "Podtitulek", data, changeHandler)}</MDBCol>
            {/* <MDBCol md="4">{RS.renderInputText("heroImage", "Obrázek", data, changeHandler)}</MDBCol> */}
          </MDBRow>

          {RS.renderTitle("Patička", "mt-4")}
          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputText("footerTitle", "Titulek", data, changeHandler)}</MDBCol>
          </MDBRow>

          {RS.renderTitle("Zákonné informace o vlastníkovi webu", "mt-4")}

          <p className="hint">
            Tyto informace vyžaduje zákon více{" "}
            <a href="https://www.inizio.cz/blog/povinne-udaje-webu/" target="_blank">
              v tomto čláknu
            </a>
          </p>

          <div className="mt-4 ml-4">{RS.renderSubTitle("Načtení dat o společnosti z rejstříku ARES dle IČ")}</div>
          <MDBRow className="mt-2">
            <div className="col-12 col-md-6 col-xl-3">{RS.renderInputText("aresIco", "IČ", data, changeAresHandler)}</div>
            <div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0 text-left text-sm-center text-md-left">
              <label className="btn btn-outline-primary copy-image-btn ml-4" onClick={() => getDataFromAres()}>
                <i className="fa fa-download mr-1"></i> Načíst data o společnosti
              </label>
            </div>
          </MDBRow>

          <div className="mt-4 ml-4">{RS.renderSubTitle("Informace o společnosti")}</div>
          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputText("companyName", "Název společnosti", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("companyIc", "IČ", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("companyDic", "DIČ", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4 ml-4">{RS.renderSubTitle("Sídlo společnosti")}</div>
          <MDBRow className="mt-1">
            <MDBCol md="4">{RS.renderInputText("street", "Ulice", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("city", "Město", data, changeHandler)}</MDBCol>
            <MDBCol md="4">{RS.renderInputText("zip", "PSČ", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4 ml-4">{RS.renderSubTitle("Informace o zápisu do rejstříku")}</div>
          <MDBRow className="mt-1">
            <MDBCol md="12">{RS.renderInputText("courtText", "Informace o zápisu do rejstříku", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageGeneralSettingsForm;
