//datatables.net initialization
export default function initDataTable(context) {
  context.extend(true, context.fn.dataTable.defaults, {
    searching: true,
    ordering: true,
    paging: true,
    lengthChange: false,
    info: true,
    pageLength: 10,
    language: {
      url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Czech.json",
    },
    order: [],
  });
}
