import http from "../HttpService";

const controller = "/generalsettings/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId) {
  return http.get(getUrlItem(tenantId));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  if (item.id) {
    console.log("save general settings put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save general settings post ", item);
    return http.post(controller, item);
  }
}
