import React, { Fragment, useState, useContext, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import * as Helper from "../../_Helper";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as AccountProfileService from "../../Services/data/AccountProfileService";
import * as ExternalService from "../../Services/data/ExternalServicesService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await AccountProfileService.save(data);
      setLoggedUserData();
      RS.toastSave();
      setIsEditedData(false);
    } catch (error) {
      // if (error.response && error.response.status === 404) {
      //   RS.toastError("This movie has already been deleted");
      // }
      // this.setState({
      //   movies: origin
      // });
    }
  }
}

function setLoggedUserData() {
  const loggedUser = `${data.firstName} ${data.surname}`;
  const nameI = data.firstName.substring(0, 1);
  const surnameI = data.surname.substring(0, 1);
  const loggedUserInitial = `${nameI}${surnameI}`;
  setAppContext({ ...appContext, loggedUser, loggedUserInitial });
}

function generateNewGuid() {
  setData({ ...data, guid: Helper.uuid() });
}

function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(function () {
      RS.toastInfo(`Identifikátor webu byl úspěšně zkopírován do schránky`);
      // alert("yeah!"); // success
    })
    .catch(function () {
      // alert("err"); // error
    });
}

function validateChildComponent() {
  return true;
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "firstName":
      errors[name] = VS.required(value, "Jméno");
      break;
    case "surname":
      errors[name] = VS.required(value, "Příjmení");
      break;
    case "company":
      errors[name] = VS.required(value, "Název společnosti");
      break;
    case "street":
      errors[name] = VS.required(value, "Ulice");
      break;
    case "city":
      errors[name] = VS.required(value, "Město");
      break;
    case "ic":
      errors[name] = VS.exact(value, 8, "IČ");
      break;
    case "dic":
      if (value) {
        errors[name] = VS.exact(value, 12, "DIČ");
      } else {
        errors[name] = "";
      }
      break;
    case "zip":
      errors[name] = VS.exact(value, 5, "PSČ");
      break;
    case "phone":
      errors[name] = VS.validatePhone(value);
      break;
    case "email":
      errors[name] = VS.validateEmail(value);
      break;
    case "lat":
      if (value) {
        errors[name] = VS.validateLat(value);
      } else {
        errors[name] = "";
      }
      break;
    case "lon":
      if (value) {
        errors[name] = VS.validateLon(value);
      } else {
        errors[name] = "";
      }
      break;
    default:
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(id) {
  let { data: item } = await AccountProfileService.getItem(parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      titleBefore: item.titleBefore,
      titleAfter: item.titleAfter,
      firstName: item.firstName,
      surname: item.surname,
      street: item.street,
      city: item.city,
      zip: item.zip,
      company: item.company,
      ic: item.ic,
      dic: item.dic,
      email: item.email,
      phone: item.phone,
      lat: item.lat,
      lon: item.lon,
      guid: item.guid,
      aresIco: item.ic,
      errors: {
        firstName: "",
        surname: "",
        phone: "",
        email: "",
        company: "",
        city: "",
        zip: "",
        ic: "",
        lat: "",
        lon: "",
        aresIco: "",
      },
    };
  } else {
    model = {
      id: appContext.tenantId,
      titleBefore: "",
      titleAfter: "",
      firstName: "",
      surname: "",
      street: "",
      city: "",
      zip: "",
      company: "",
      ic: "",
      dic: "",
      email: "",
      phone: "",
      lat: "",
      lon: "",
      guid: "",
      aresIco: "",
      errors: {
        firstName: "",
        surname: "",
        company: "",
        phone: "",
        email: "",
        city: "",
        zip: "",
        ic: "",
        lat: "",
        lon: "",
        aresIco: "",
      },
    };
  }
  return model;
}

function resetData() {
  //guid je readonly a nemsi se smazat
  const guid = data.guid;
  const item = getViewFromModel(null);
  item.guid = guid;
  setData(item);
  setIsEditedData(true);
}

function changeAresHandler(event) {
  const { name, value } = event.target;
  validateAresFormItem(name, value);
}

function validateAresFormItem(name, value) {
  let errors = data.errors;
  let isValid = true;
  switch (name) {
    case "aresIco":
      errors[name] = VS.exact(value, 8, "IČ");
      if (errors[name]) {
        isValid = false;
      }
      break;
  }

  setData({ ...data, errors, [name]: value });
  return isValid;
}

async function getDataFromAres() {
  //nacist z API service
  //validace pred spustenim requestu

  const isValid = validateAresFormItem("aresIco", data.aresIco);

  if (isValid) {
    const result = await ExternalService.getAresItem(appContext.tenantId, data.aresIco);
    if (result && result.data) {
      if (result.data.isError) {
        RS.toastError(`Chyba při načítání dat ze systému ARES ${result.data.errorText}`);
      } else {
        let ad = {};
        RS.toastSuccess("Načtení dat ze systému ARES proběhlo úspěšně");

        ad.company = Helper.setEmptyOrGetVal(result.data.nazevFirmy);
        ad.ic = Helper.setEmptyOrGetVal(result.data.ic);
        ad.dic = Helper.setEmptyOrGetVal(result.data.dic);

        let cislo = `${result.data.sidloCisloDomovni}/${result.data.sidloCisloOrientacni}`;
        if (result.data.sidloCisloDoAdresy) {
          cislo = result.data.sidloCisloDoAdresy;
        }
        ad.street = `${result.data.sidloUlice} ${cislo}`;
        ad.city = Helper.setEmptyOrGetVal(result.data.sidloMesto);
        ad.zip = Helper.setEmptyOrGetVal(result.data.sidloPsc);

        setData({
          ...data,
          errors: {
            company: "",
            ic: "",
            dic: "",
            street: "",
            city: "",
            zip: "",
          },
          company: ad.company,
          ic: ad.ic,
          dic: ad.dic,
          street: ad.street,
          city: ad.city,
          zip: ad.zip,
        });
        setIsEditedData(true);
      }
    } else {
      RS.toastError("Chyba při načítání dat ze systému ARES");
    }
  }
}

let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [isEdited, setIsEditedData] = [2];
function PageProfileForm(props) {
  [appContext, setAppContext] = useContext(AppContext);
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [isEdited, setIsEditedData] = useState(false);

  useEffect(() => {
    //useEffect ma problem s volanim async functions , mela by se volat lokalne vytvorena fce
    // Create an scoped async function in the hook
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId);
      if (result) {
        setData(result);
      }
    }
    // Execute the created function directly
    scopedLoadData();
  }, []);

  let title = "Profil zákazníka";

  return (
    <Fragment>
      <BlackQuote title={title}>
        <b>Vyplňte informace o vaší společnosti.</b> Údaje zákazníka poslouží jako fakturační a kontaktní údaje pro interní účely při komunikaci s pracovníky
        <b> WebDoOrdinace.cz</b>. Tyto údaje nebudou na Vámi vytvořených webových stránkách nikde zveřejněny.
      </BlackQuote>

      {RS.renderTitle("Informace o společnosti")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <div className="mt-4 ml-4">{RS.renderSubTitle("Načtení dat o společnosti z rejstříku ARES dle IČ")}</div>
          <MDBRow className="mt-2">
            <div className="col-12 col-md-6 col-xl-3">{RS.renderInputText("aresIco", "IČ", data, changeAresHandler)}</div>
            <div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0 text-left text-sm-center text-md-left">
              <label className="btn btn-outline-primary copy-image-btn ml-4" onClick={() => getDataFromAres()}>
                <i className="fa fa-download mr-1"></i> Načíst data o společnosti
              </label>
            </div>
          </MDBRow>

          <MDBRow className="mt-4">
            <MDBCol md="3">{RS.renderInputText("company", "Název společnosti", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("ic", "IČ", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("dic", "DIČ", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="mt-2">
            <MDBCol md="3">{RS.renderInputText("titleBefore", "Titul před jménem", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("firstName", "Jméno", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("surname", "Příjmeni", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("titleAfter", "Titul za jménem", data, changeHandler)}</MDBCol>
          </MDBRow>
          {appContext.isAdmin && (
            <MDBRow className="mt-2">
              <MDBCol md="3">{RS.renderInputText("guid", "Identifikátor webu", data, changeHandler, "readonly-item-copy disabled")}</MDBCol>
              {/* <MDBCol md="3">
                <label className="btn btn-outline-primary copy-image-btn ml-4" onClick={() => generateNewGuid()}>
                  <i className="far fa-file"></i> Generovat nový Identifikátor
                </label>
              </MDBCol> */}
              <MDBCol md="3">
                <label className="btn btn-outline-primary copy-image-btn ml-4" onClick={() => copyToClipboard(data.guid)}>
                  <i className="far fa-copy"></i> Kopírovat Identifikátor
                </label>
              </MDBCol>
            </MDBRow>
          )}

          <div className="mt-4">{RS.renderTitle("Adresa společnosti")}</div>

          <MDBRow>
            <MDBCol md="3">{RS.renderInputText("street", "Ulice", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("city", "Město", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("zip", "PSČ", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="mt-2">
            <MDBCol md="3">{RS.renderInputText("lat", "GPS šířka (latitude)", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("lon", "GPS délka (longitude)", data, changeHandler)}</MDBCol>
          </MDBRow>

          <div className="mt-4">{RS.renderTitle("Kontaktní údaje")}</div>

          <MDBRow>
            <MDBCol md="3">{RS.renderInputText("phone", "Telefon", data, changeHandler)}</MDBCol>
            <MDBCol md="3">{RS.renderInputText("email", "E-mail", data, changeHandler)}</MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderResetData(resetData)}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageProfileForm;
