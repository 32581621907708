import React, { Fragment, useState, useContext } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import * as MS from "../../Services/MenuService";
import * as AuthService from "../../Services/AuthService";
import * as Const from "../../_Const";
import Avatar from "@material-ui/core/Avatar";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import { Link } from "react-router-dom";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent, false)) {
    try {
      await AuthService.register(data.name, data.surname, data.email, data.password, data.phone, data.ic);
      window.location = "/";
    } catch (ex) {
      let error = "Nastala chyba při registraci";
      console.log(ex.response);
      if (ex.response) {
        if (ex.response.status == 400) {
          error = "Nastala chyba při registraci:\nZaslaná data osahují chybu.";
        } else if (ex.response.status == 409) {
          error = "Nastala chyba při registraci:\nUživatel s tímto emailem již existuje.\nVyplňte jiný email a akci opakujte.";
        } else if (ex.response.status == 500) {
          error = "Nastala chyba při registraci";
        }
      }

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function validateChildComponent() {
  return true;
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "name":
      errors[name] = VS.required(value, "Jméno");
      break;
    case "surname":
      errors[name] = VS.required(value, "Příjmení");
      break;
    case "ic":
      errors[name] = VS.exact(value, 8, "IČ");
      break;
    case "phone":
      errors[name] = VS.validatePhone(value);
      break;
    case "password":
      errors[name] = VS.min(value, 8, "Heslo");
      break;
    case "password2":
      errors[name] = VS.required(value, "Heslo pro kontrolu");
      if (!errors[name]) {
        errors[name] = VS.samePassword(data.password, value);
      }
      break;
    case "email":
      errors[name] = VS.validateEmail(value);
      break;
  }

  setData({ ...data, errors, [name]: value });
}

function getViewFromModel() {
  let model = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    ic: "",
    password: "",
    password2: "",
    errors: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      ic: "",
      password: "",
      password2: "",
    },
  };

  return model;
}

let [data, setData] = [2];
function PageRegister(props) {
  [data, setData] = useState(getViewFromModel());

  return (
    <div class="login-container justify-content-center">
      <div className="my-auto">
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="login-control-item">
            <MDBCol md="12">
              <Avatar className="login-avatar mx-auto">
                <i class="fas fa-user-plus"></i>
              </Avatar>
              <h1 className="text-center mt-2">WebDoOrdinace.cz</h1>
              <h5 className="text-center mt-2 mb-4 text-black-50">registrace nového uživatele</h5>
            </MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item">
            <MDBCol md="12">{RS.renderInputText("name", "Jméno", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item mt-2">
            <MDBCol md="12">{RS.renderInputText("surname", "Příjmení", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item mt-2">
            <MDBCol md="12">{RS.renderInputText("ic", "IČ", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item mt-2">
            <MDBCol md="12">{RS.renderInputText("phone", "Telefon", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item  mt-2">
            <MDBCol md="12">{RS.renderInputText("email", "Email", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item mt-2">
            <MDBCol md="12">{RS.renderInputPassword("password", "Heslo", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="login-control-item mt-2">
            <MDBCol md="12">{RS.renderInputPassword("password2", "Heslo pro kontrolu", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="mt-3 mb-2 login-control-item">
            <MDBCol md="12">
              <div className="container flex-fill">{RS.renderSubmitTextWithoutIcon("Registrace nového uživatele", false, "login-button ml-0")}</div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3  text-center">
            <MDBCol md="12">
              <div className="text-black-50 small">
                Již jste se k nám zaregistrovali? <Link to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGIN)}>Přihlašte se</Link>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
    </div>
  );
}

export default PageRegister;
