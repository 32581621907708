import http from "../HttpService";

const controller = "/file/";

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlAll(tenantId) {
  return `${controller}all/${tenantId}`;
}

export function getData(tenantId) {
  return http.get(getUrlAll(tenantId));
}

export function getItem(tenantId, id) {
  return http.get(getUrlItem(tenantId, id));
}

export function deleteData(tenantId, id) {
  return http.delete(getUrlItem(tenantId, id));
}

export function save(item) {
  let id = item.get("id");
  console.log(id);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  if (parseInt(id) > 0) {
    console.log("save files put ", item);
    return http.put(getUrl(id), item);
  } else {
    console.log("save files post ", item);
    return http.post(controller, item);
  }
}
