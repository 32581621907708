import http from "../HttpService";

const controller = "/urgentnotice/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function deleteData(tenantId) {
  return http.delete(getUrl(tenantId));
}

export function save(item) {
  if (item.id) {
    console.log("save urgent notice put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save urgent notice post ", item);
    return http.post(controller, item);
  }
}

/*
const data = [
  {
    id: 1,
    tenantId: 6,
    title: "Důležité sdělení pro pacienty",
    text: "Vážení pacienti, od 5.8.2019 je naše ordinace přemístěna na novou adresu <b>Weberova č.p. 1537/7</b>. <br /> Ordinační hodiny zůstavají stejné.",
    visible: {
      from: null,
      to: null,
    },
  },
];

export function getData(tenantId) {
  return data.find((m) => m.tenantId === tenantId);
}

export function deleteData(id) {
  let item = data.find((m) => m.id === id);
  data.splice(data.indexOf(item), 1);
  return item;
}

export function getItem(id) {
  return data.find((m) => m.id === id);
}

export function save(item) {
  let itemInDb = data.find((m) => m.id === item.id) || {};
  //update
  if (itemInDb.id > 0) {
    deleteData(item.id);
    itemInDb = item;
  } else {
    //insert
    itemInDb = item;
    itemInDb.id = Helper.createId();
  }

  data.push(itemInDb);

  return itemInDb;
}
*/
