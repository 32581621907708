import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import * as VS from "../../Services/ValidatorService";
import * as RS from "../../Services/RenderService";
import BlackQuote from "../../Components/BlackQuote";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../../Context/AppContext";
import * as OtherPagesService from "../../Services/data/OtherPagesService";
import * as Const from "../../_Const";
import * as MS from "../../Services/MenuService";

async function submitHandler(event) {
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      await OtherPagesService.save(setViewToModel(data));
      setIsEditedData(false);
      MS.goToPage(Const.ID_OTHER_PAGES);
      RS.toastSave();
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Ostatní stránky";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
    }
  }
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  setIsEditedData(true);
}

function validateChildComponent() {
  return validateEditorError(editorContent);
}

function editorContentBlurHandler(content) {
  validateEditorError(content.target.innerText);
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  validateEditorError(content);
  setIsEditedData(true);
}

function validateEditorError(value) {
  let errors = data.errors;
  errors["text"] = VS.required(value, "Text");
  setData({ ...data, errors });
  const isValid = data.errors["text"].length === 0;
  return isValid;
}
function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "title":
      errors[name] = VS.required(value, "Titulek");
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, id) {
  if (MS.isNewItemLink(id)) {
    return getViewFromModel(null);
  }

  let { data: item } = await OtherPagesService.getItem(parseInt(tenantId), parseInt(id));
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      title: item.title,
      text: item.text,
      imageName: item.image,
      imageOriginal: item.imageOriginal,
      imageSrc: item.imageSrc,
      imageFile: "",

      errors: {
        title: "",
        text: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      title: "",
      text: "",
      imageName: "",
      imageOriginal: "",
      imageSrc: "",
      imageFile: "",

      errors: {
        title: "",
        text: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("tenantId", data.tenantId);
  formData.append("title", data.title);
  formData.append("text", editorContent);
  formData.append("image", data.imageName);
  formData.append("imageOriginal", data.imageOriginal);
  formData.append("imageFile", data.imageFile);

  return formData;

  /*
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    title: data.title,
    text: editorContent,
    imageSrc: data.imageSrc,*/
  /*menu: { id: data.menuId, order: data.menuOrder, icon: data.menuIcon, name: data.menuName, link: data.menuLink },*/
  /*};
  return vm;*/
}

function deleteImage() {
  if (data.imageSrc) {
    setData({
      ...data,
      imageName: null,
      imageFile: null,
      imageSrc: null,
    });
    setIsEditedData(true);
  }
}

function showPreview(e) {
  if (e.target.files && e.target.files[0]) {
    let imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setData({
        ...data,
        imageName: null,
        imageFile: imageFile,
        imageSrc: x.target.result,
      });
      setIsEditedData(true);
    };
    reader.readAsDataURL(imageFile);
  }
}

const noImage = "/assets/img/noimage/no-image-blog.png";
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorContent, setEditorContent] = [2];
let [isEdited, setIsEditedData] = [2];

function PageOtherPagesForm(props) {
  const paramId = props.match.params.id;
  [appContext, setAppContext] = useContext(AppContext);
  [isEdited, setIsEditedData] = useState(false);

  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [editorContent, setEditorContent] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    async function scopedLoadData() {
      const result = await populateData(appContext.tenantId, paramId);
      if (result) {
        setData(result);
        setEditorContent(result.text);
        //po nahrani dat editoru pockat par ms a dat setIsEditedData(false)
        RS.setCustomTimeout(() => setIsEditedData(false));
      }
    }
    scopedLoadData();
  }, []);

  let title = "Ostatní stránky editace záznamu";
  let subTitle = "Pomocí této stránky editujete vytvořený";
  if (MS.isNewItemLink(paramId)) {
    title = "Ostatní stránky nový záznam";
    subTitle = "Pomocí této stránky přidáte nový";
  }

  let renderedImage = data.imageSrc;
  if (!renderedImage) {
    renderedImage = noImage;
  }

  return (
    <Fragment>
      {RS.checkUnsaveWork(isEdited, title)}
      <BlackQuote title={title}>
        {subTitle} <b>záznam v sekci Ostatní stránky</b>
      </BlackQuote>

      <div>
        <form onSubmit={submitHandler} noValidate>
          <div className="mt-4">{RS.renderTitle("Obsah stránky")}</div>
          <MDBRow className="mt-2">
            <MDBCol md="4">{RS.renderInputText("title", "Titulek", data, changeHandler)}</MDBCol>
          </MDBRow>
          <MDBRow className="mt-2 ml-2">
            <MDBCol md="8">
              {RS.renderSubTitle("Text")}
              {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
            </MDBCol>
          </MDBRow>

          <div className="mt-4">{RS.renderTitle("Obrázek")}</div>

          <MDBRow className="ml-2 mt-2">
            <MDBCol>
              <img src={renderedImage} className="blog-form-thumb" />
            </MDBCol>
          </MDBRow>

          <MDBRow className="ml-2 mt-2">
            <MDBCol>
              <label className="btn btn-outline-primary blog-image-btn ">
                <i className="fa fa-user"></i> Vložit obrázek
                <input type="file" name="image" accept="image/*" className="d-none" onChange={showPreview} />
              </label>
            </MDBCol>
          </MDBRow>

          {data.imageSrc && (
            <MDBRow className="ml-2 mt-0">
              <MDBCol>
                <label className="btn btn-outline-danger blog-image-btn " onClick={() => deleteImage()}>
                  <i className="fa fa-trash"></i> Odebrat obrázek
                </label>
              </MDBCol>
            </MDBRow>
          )}

          <MDBRow className="justify-content-start ml-0 mt-4 mb-4">
            {RS.renderSubmit(!isEdited)}
            {RS.renderBackLink()}
          </MDBRow>
        </form>
      </div>
    </Fragment>
  );
}

export default PageOtherPagesForm;
