import http from "../HttpService";

const controller = "/extinfo/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

export function getItem(tenantId) {
  return http.get(getUrl(tenantId));
}

export function save(item) {
  console.log("save extinfo post ", item);
  return http.post(controller, item);
}

export function getExtInfoCategory(passedData) {
  return passedData.filter((g) => g);
}

export function getExtInfoByCategoryId(id, data) {
  if (!id) {
    return null;
  }

  return data.filter((g) => g.extInfoCategoryId === id);
}
