import React from "react";
import _ from "lodash";
import * as Helper from "../_Helper";

function TRow(props) {
  function renderCell(item, column) {
    if (column.content) {
      return column.content(item);
    } else {
      return _.get(item, column.path);
    }
  }

  const { data, columns } = props;

  return (
    <tr key={Helper.createKey()}>
      {columns.map((column) => (
        <td key={Helper.createKey()}>{renderCell(data, column)}</td>
      ))}
    </tr>
  );
}

export default TRow;
