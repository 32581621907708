import React from "react";
import THead from "./THead";
import TRow from "./TRow";
import * as Helper from "../_Helper";
function Table(props) {
  const { data, columns } = props;

  return (
    <div className="table-responsive px-2">
      <table id="myTable" className="table table-sma table-hover table-bordereda styled-table">
        <THead data={columns} />

        <tbody>{data && data.map((item) => <TRow data={item} columns={columns} key={Helper.createKey()} />)}</tbody>
      </table>
    </div>
  );
}

export default Table;
