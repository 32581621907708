import http from "../HttpService";

const controller = "/section/";

function getUrl(tenantId) {
  return `${controller}${tenantId}`;
}

function getUrlItem(tenantId, id) {
  return `${controller}${tenantId}/${id}`;
}

export function getItem(tenantId, sectionCode) {
  return http.get(getUrlItem(tenantId, sectionCode));
}

export function deleteData(tenantId) {
  return http.delete(getUrl(tenantId));
}

export function save(item) {
  if (item.id) {
    console.log("save section put ", item);
    return http.put(getUrl(item.id), item);
  } else {
    console.log("save section post ", item);
    return http.post(controller, item);
  }
}
