import React, { Fragment } from "react";
import * as RS from "../Services/RenderService";

function MultiItemForm(props) {
  const { data, onItemChange, onAddItem, onRemoveItem, label, name } = props;

  function changeHandler(event, index) {
    onItemChange(event, index);
  }

  function onAddClick() {
    onAddItem();
  }

  function onRemoveClick(index) {
    onRemoveItem(index);
  }

  let disabledDelete = false;
  if (!data) {
    return null;
  }

  if (data.length === 1) {
    disabledDelete = true;
  }

  return (
    <Fragment>
      {data.map((x, i) => {
        return (
          <div className="row mt-2" key={i}>
            <div className="col-6">
              {RS.renderInput(name, label, x.text, (e) => changeHandler(e, i), "text", x.error, false, "float-left email-control")}
              <div className="float-left mt-1">{RS.renderDeleteIcon(() => onRemoveClick(i), disabledDelete)}</div>
              {data.length - 1 === i && <div className="float-left mt-1">{RS.renderAddIcon(() => onAddClick())}</div>}
            </div>
          </div>
        );
      })}

      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(data)}</div> */}
    </Fragment>
  );
}

export default MultiItemForm;
