import React, { useState, useContext, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import * as VS from "../Services/ValidatorService";
import * as RS from "../Services/RenderService";
import { MDBRow, MDBCol } from "mdbreact";
import { AppContext } from "../Context/AppContext";
import * as SectionInfoService from "../Services/data/SectionInfoService";
import * as Const from "../_Const";
import * as Helper from "../_Helper";

async function submitHandler(event) {
  let isValid = true;
  if (VS.handleSubmit(event, data, validateFormItem, validateChildComponent)) {
    try {
      var result = await SectionInfoService.save(setViewToModel(data));

      //po pouziit POST nastavim nove ziskane ID do aktualniho state data
      var id = data.id ? data.id : 0;
      if (parseInt(id) === 0) {
        setItemAfterPost(result.data);
      }
      if (showNotification || showSaveBtn) {
        RS.toastSave();
      }
      if (afterSaveSectionHandler) {
        afterSaveSectionHandler();
      }
      setIsEditedData(false);

      console.log("sectioninfo submitHandler");
    } catch (ex) {
      let error = "Nastala chyba při ukládání dat položky Informace o sekci";
      console.log(ex.response);

      if (error) {
        RS.toastError(error);
      }
      isValid = false;
    }
  }
  return isValid;
}

function setItemAfterPost(newItem) {
  setData({ ...data, id: newItem.id, menuId: newItem.menuId, sectionId: newItem.sectionId });
}

function changeHandler(event) {
  const { name, value } = event.target;
  validateFormItem(name, value);
  if (dataChangedHandler) {
    dataChangedHandler();
  }
  setIsEditedData(true);
}

function validateChildComponent() {
  return true;
}

function changeCheckedHandler(event) {
  setIsEditedData(true);
  if (dataChangedHandler) {
    dataChangedHandler();
  }
  if (sectionDisabledChangedHandler) {
    sectionDisabledChangedHandler(!event.target.checked);
  }
  setData({ ...data, isEnabled: event.target.checked });
}

function validateFormItem(name, value) {
  let errors = data.errors;
  switch (name) {
    case "title":
      errors[name] = VS.required(value, "Titulek sekce");
      break;
  }

  setData({ ...data, errors, [name]: value });
}

async function populateData(tenantId, sectionCode) {
  let { data: item } = await SectionInfoService.getItem(parseInt(tenantId), sectionCode);
  item = getViewFromModel(item);
  return item;
}

function getViewFromModel(item) {
  let model;
  if (item) {
    model = {
      id: item.id,
      tenantId: item.tenantId,
      sectionId: item.sectionId,
      sectionCode: sectionCode,
      title: item.title,
      subtitle: item.subtitle,
      text: item.text,
      errors: {
        title: "",
      },
    };
  } else {
    model = {
      id: 0,
      tenantId: appContext.tenantId,
      sectionId: 0,
      sectionCode: "",
      text: "",
      title: getDefaultTitle(),
      subtitle: getDefaultSubtitle(),
      errors: {
        title: "",
      },
    };
  }
  return model;
}

function setViewToModel(data) {
  let vm = {
    id: data.id,
    tenantId: appContext.tenantId,
    sectionId: data.sectionId,
    code: sectionCode,
    title: data.title,
    subtitle: data.subtitle,
    text: editorContent,
  };
  return vm;
}

function getDefaultSubtitle() {
  let title = "";
  switch (sectionCode) {
    case Const.ID_ORDINATION_HOUR:
      title = "zjistěte kdy máme otevřeno";
      break;
    case Const.ID_ABOUT:
      title = "pár informací o naší ordinaci";
      break;
    case Const.ID_SERVICES:
      title = "informujte se jaké služby v naší ordinaci nabízíme";
      break;
    case Const.ID_PRICING:
      title = "prohlédněte si ceník poskytovaných služeb";
      break;
    case Const.ID_BLOG:
      title = "buďte informování a přečtěte si co se u nás děje";
      break;
    case Const.ID_EXT_INFO:
      title = "zjistěte co Vám naše lékařské zařízení nabízí";
      break;
    case Const.ID_INSURANCES:
      title = "přehled zdravotních pojišťoven se kterými máme uzařenou smlouvu";
      break;
    case Const.ID_TEAM:
      title = "prohlédněte si kdo se o Vás postará a kdo Vám pomůže bude-li to třeba";
      break;
    case Const.ID_CONTACT:
      title = "neváhejte nás kontaktovat na níže uvedených kontaktech";
      break;
    case Const.ID_FAQ:
      title = "přečtěte si odpovědi na časté otázky od našich pacientů";
      break;
    case Const.ID_GALLERY:
      title = "prohlédněte si fotografie z našeho lékařského zařízení";
      break;
    case Const.ID_FILES:
      title = "stáhněte si často potřebné formuláře a soubory";
      break;
  }
  return title;
}

function getDefaultTitle() {
  let title = "";
  switch (sectionCode) {
    case Const.ID_ORDINATION_HOUR:
      title = "Ordinační hodiny";
      break;
    case Const.ID_ABOUT:
      title = "O nás";
      break;
    case Const.ID_SERVICES:
      title = "Naše služby";
      break;
    case Const.ID_PRICING:
      title = "Ceník služeb";
      break;
    case Const.ID_BLOG:
      title = "Novinky v naší ordinaci";
      break;
    case Const.ID_EXT_INFO:
      title = "Vybavenost čekárny";
      break;
    case Const.ID_INSURANCES:
      title = "Zdravotní pojišťovny";
      break;
    case Const.ID_TEAM:
      title = "Náš tým";
      break;
    case Const.ID_CONTACT:
      title = "Kontakt";
      break;
    case Const.ID_FAQ:
      title = "Otázky a odpovědi";
      break;
    case Const.ID_GALLERY:
      title = "Galerie obrázků";
      break;
    case Const.ID_FILES:
      title = "Soubory ke stažení";
      break;
  }
  return title;
}

function editorContentBlurHandler(content) {
  validateEditorError(content.target.innerText);
  setEditorContent(content.target.innerHTML);
}

function editorContentChangeHandler(content) {
  validateEditorError(content);
  if (dataChangedHandler) {
    dataChangedHandler();
  }
  setIsEditedData(true);
}

function validateEditorError(value) {
  //neni treba validovat text neni povinny
  // let errors = data.errors;
  // errors["text"] = VS.required(value, "Text");
  // setData({ ...data, errors });
  // const isValid = data.errors["text"].length === 0;
  return true;
}

let [isEdited, setIsEditedData] = [2];
let [showNotification, setShowNotification] = [2];
let [data, setData] = [2];
let [appContext, setAppContext] = [2];
let [editorContent, setEditorContent] = [2];
let [isEditorOpen, setIsEditorOpen] = [2];
let sectionCode;
let dataChangedHandler;
let dataLoadedHandler;
let needToSaveSectionHandler;
let sectionDisabledChangedHandler;
let afterSaveSectionHandler;
let showSaveBtn;
let showEditor;
const SectionInfo = forwardRef((props, ref) => {
  sectionCode = props.sectionCode;
  dataChangedHandler = props.onDataChanged;
  dataLoadedHandler = props.onDataLoaded;
  needToSaveSectionHandler = props.onNeedToSaveSection;
  afterSaveSectionHandler = props.onAfterSaveSection;
  //sectionDisabledChangedHandler = props.onSectionEnabledChanged;
  showSaveBtn = props.showSaveBtn;
  showEditor = props.showEditor;

  [isEdited, setIsEditedData] = useState(false);

  useImperativeHandle(ref, () => ({
    validate() {
      const isValid = VS.checkValidFormAll(validateFormItem, data);
      return isValid;
    },
    save(showNotifacation) {
      setShowNotification(showNotifacation);
      return submitHandler();
    },
    reset() {
      const id = data.id;
      const item = getViewFromModel(null);
      item.id = id;

      setData(item);

      // if (sectionDisabledChangedHandler) {
      //   sectionDisabledChangedHandler(false);
      // }
    },
  }));
  [isEditorOpen, setIsEditorOpen] = useState(false);
  [appContext, setAppContext] = useContext(AppContext);
  const item = getViewFromModel(null);
  [data, setData] = useState(item);
  [showNotification, setShowNotification] = useState(false);
  [editorContent, setEditorContent] = useState(null);
  const editorRef = useRef(null);
  useEffect(() => {
    async function scopedLoadData() {
      try {
        const result = await populateData(appContext.tenantId, sectionCode);
        if (result) {
          setData(result);
          setEditorContent(result.text);
          if (!Helper.isNullOrEmpty(result.text)) {
            setIsEditorOpen(true);
          }

          // if (sectionDisabledChangedHandler) {
          //   sectionDisabledChangedHandler(!data.isEnabled);
          // }
          if (dataLoadedHandler) {
            //po nahrani dat editoru pockat par ms a dat dataLoadedHandler
            RS.setCustomTimeout(() => dataLoadedHandler());
          }
          //po nahrani dat editoru pockat par ms a dat setIsEditedData
          RS.setCustomTimeout(() => setIsEditedData(false));
        }
      } catch (ex) {
        console.log(ex.response);
        //data sekce nejsou vuyplnena a potrebuji ulozit
        if (ex.response.status === 400 && needToSaveSectionHandler) {
          needToSaveSectionHandler();
          setIsEditedData(true);
        }
      }
    }
    scopedLoadData();
  }, []);

  const hint = isEditorOpen ? "Chcete-li zavřít editor, klikněte na tlačítko Schovat editor" : "Chcete-li otevřít editor, klikněte na tlačítko Zobrazit editor";
  let editorRowClassName = isEditorOpen ? "mt-2 no-gutters d-block" : "d-none";
  return (
    <div className="mt-4">
      {/* {RS.renderTitle(`${title} - nastavení`)} */}
      {RS.renderTitle("Titulek a podtitulek sekce")}
      {RS.renderHint("Titulek je hlavní nadpis sekce, podtitulek doplňuje nadpis sekce o rozšiřující informace - může být i prázdný ")}

      <div>
        <form onSubmit={submitHandler} noValidate>
          <MDBRow className="mt-0">
            <MDBCol md="4">{RS.renderInputText("title", "Titulek sekce", data, changeHandler)}</MDBCol>
            <MDBCol md="8">{RS.renderInputText("subtitle", "Podtitulek sekce", data, changeHandler)}</MDBCol>
          </MDBRow>
          {showEditor && (
            <>
              <div className="row mt-4 no-gutters pl-4 pr-4 accordination-itema">
                <div className="col-12">
                  <div className="row noselect">
                    <div className="col-12 col-sm-7 d-none d-sm-block mt-1">
                      {RS.renderSubTitle("Další informace o sekci")}
                      {RS.renderHint(hint)}
                    </div>
                    <div className="col-12 col-sm-5 float-left float-sm-right text-left text-sm-right mt-n0">
                      {!isEditorOpen && (
                        <span className="btn btn-outline-primary" onClick={() => setIsEditorOpen(!isEditorOpen)}>
                          Zobrazit editor<i class="fa fa-caret-down ml-2" aria-hidden="true"></i>
                        </span>
                      )}
                      {isEditorOpen && (
                        <span className="btn btn-outline-primary" onClick={() => setIsEditorOpen(!isEditorOpen)}>
                          Schovat editor<i class="fa fa-caret-up ml-2" aria-hidden="true"></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <MDBRow className={editorRowClassName}>
                <MDBCol md="12" className="pl-4 pr-4">
                  {RS.renderEditor(editorContent, RS.DefaultEditorConfig, editorContentBlurHandler, editorContentChangeHandler, editorRef, data.errors.text)}
                </MDBCol>
              </MDBRow>
            </>
          )}

          {showSaveBtn && <MDBRow className="justify-content-start ml-0 mt-3 mb-0">{RS.renderSubmit(!isEdited)}</MDBRow>}
        </form>
      </div>
    </div>
  );
});

export default SectionInfo;
