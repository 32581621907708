import React, { useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Switch, Route, Link } from "react-router-dom";
import * as Const from "./_Const";
import { AppContext } from "./Context/AppContext";
import BreadCrumb from "./BreadCrumb";
import LeftMenu from "./LeftMenu";
import PageOther from "./PageOther";
import * as MS from "./Services/MenuService";
import * as RS from "./Services/RenderService";
import PageInsuranceIndex from "./Pages/Insurance/PageInsuranceIndex";
import PageProfileForm from "./Pages/Account/PageProfileForm";
import PageExtInfoIndex from "./Pages/ExtInfo/PageExtInfoIndex";
import DebugInfo from "./DebugInfo";
import PageFaqIndex from "./Pages/FAQ/PageFaqIndex";
import PageFaqForm from "./Pages/FAQ/PageFaqForm";
import PageOpeningHourForm from "./Pages/OpeningHour/PageOpeningHourForm";
import PageTeamIndex from "./Pages/Team/PageTeamIndex";
import PageTeamForm from "./Pages/Team/PageTeamForm";
import PageBlogIndex from "./Pages/Blog/PageBlogIndex";
import PageBlogForm from "./Pages/Blog/PageBlogForm";
import PageUrgentNoticeForm from "./Pages/UrgentNotice/PageUrgentNoticeForm";
import PageAboutForm from "./Pages/About/PageAboutForm";
import PageServices from "./Pages/Services/PageServices";
import PagePriceListForm from "./Pages/PriceList/PagePriceListForm";
import PagePriceListIndex from "./Pages/PriceList/PagePriceListIndex";
import PageGalleryForm from "./Pages/Gallery/PageGalleryForm";
import Avatar from "@material-ui/core/Avatar";
import PageLogin from "./Pages/Account/PageLogin";
import AuthService from "./Services/AuthService";
import PageOtherPagesIndex from "./Pages/OtherPages/PageOtherPagesIndex";
import PageOtherPagesForm from "./Pages/OtherPages/PageOtherPagesForm";
import PageRegister from "./Pages/Account/PageRegister";
import PagePasswordChange from "./Pages/Account/PagePasswordChange";
import PageFilesIndex from "./Pages/Files/PageFilesIndex";
import PageFilesForm from "./Pages/Files/PageFilesForm";
import PageContactForm from "./Pages/Contact/PageContactForm";
import PageMenuIndex from "./Pages/Menu/PageMenuIndex";
import PageMenuForm from "./Pages/Menu/PageMenuForm";
import PageGeneralSettingsForm from "./Pages/GeneralSettings/PageGeneralSettingsForm";
import packageJson from "./../package.json";
function handleClick(activeId) {
  setAppContext({ ...appContext, activeId });
  switch (activeId) {
    case Const.ID_ACCOUNT_LOGOUT:
      AuthService.logout();
      window.location = MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGIN);
      break;
    case Const.ID_ACCOUNT_PROFILE:
      break;
  }
}

function handleSideNavBtnToggle() {
  let isOpen = false;
  if (!appContext.isToggleOpen) {
    document.body.classList.add("sb-sidenav-toggled");
    isOpen = true;
  } else {
    document.body.classList.remove("sb-sidenav-toggled");
    isOpen = false;
  }

  setAppContext({ ...appContext, isToggleOpen: isOpen });
}

function writeVersionTag(version) {
  const link = document.createElement("meta");
  link.setAttribute("name", "app:version");
  link.content = version;
  document.getElementsByTagName("head")[0].appendChild(link);
}

let [appContext, setAppContext] = [2];
let user = null;
function App() {
  [appContext, setAppContext] = useContext(AppContext);
  const year = new Date().getFullYear();
  const appVersion = packageJson.version;
  //po loginu ulozit info do contextu
  useEffect(() => {
    user = AuthService.getCurrentUser();
    if (user) {
      setAppContext({ ...appContext, tenantId: parseInt(user.tenantId), loggedUser: user.displayName, loggedUserInitial: user.initial, isAdmin: user.isAdmin });
    }
    writeVersionTag(appVersion);
  }, []);
  return (
    <React.Fragment>
      {/* musim vyrenderovat alespon div abych mel pristup ke vsem funkcim ktere pouzivatji react hooks napr useState a history v menu service */}
      <MS.MenuService />
      {user && (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
          <span className="navbar-brand">
            <img className="navbar__logo" src="/assets/img/logo_small_white.png" />
            <span className="debug-info ml-2">
              <DebugInfo />
            </span>
          </span>
          <button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#" onClick={handleSideNavBtnToggle}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Navbar */}
          <ul className="navbar-nav ml-auto d-flex ">
            <li className="nav-item dropdown">
              {/* <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              > */}
              <Link
                key={Const.ID_ACCOUNT_LOGOUT}
                className="btn btn-primary d-none d-sm-block"
                onClick={() => handleClick(Const.ID_ACCOUNT_LOGOUT)}
                to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGOUT)}
              >
                <i class="fas fa-sign-out-alt mr-2"></i> Odhlásit
              </Link>
              {/* <Avatar className="avatar">{appContext.loggedUserInitial}</Avatar> */}
              {/* </a> */}
              <div className="dropdown-menu dropdown-menu-right-custom" aria-labelledby="userDropdown">
                {/* <Link
                  key={Const.ID_ACCOUNT_PROFILE}
                  className="dropdown-item"
                  onClick={() => handleClick(Const.ID_ACCOUNT_PROFILE)}
                  to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_PROFILE)}
                >
                  <i className="fas fa-user mr-2"></i> Profil zákazníka
                </Link> */}
                {/* <a className="dropdown-item" href="#"> */}
                {/* <i className="fas fa-user mr-2"></i> Profil zákazníka */}
                {/* </a> */}
                {/* <a className="dropdown-item" href="#">
                  <i className="fas fa-lock mr-2"></i> Změna hesla
                </a>
                <a className="dropdown-item" href="#">
                  <i class="far fa-credit-card mr-2"></i> Platby
                </a> */}
                <div className="dropdown-divider"></div>

                {/* <Link
                  key={Const.ID_ACCOUNT_LOGOUT}
                  className="dropdown-item"
                  onClick={() => handleClick(Const.ID_ACCOUNT_LOGOUT)}
                  to={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGOUT)}
                >
                  <i class="fas fa-sign-out-alt mr-2"></i> Odhlásit
                </Link> */}
              </div>
            </li>
          </ul>
        </nav>
      )}
      <div id="layoutSidenav">
        {!user && (
          <>
            {RS.renderToastContainer()}
            <Switch>
              <Route key={Const.ID_ACCOUNT_REGISTER} path={MS.getLinkFromMenuId(Const.ID_ACCOUNT_REGISTER)} component={PageRegister} />
              <Route key={Const.ID_ACCOUNT_PASSWORD_CHANGE} path={MS.getLinkFromMenuId(Const.ID_ACCOUNT_PASSWORD_CHANGE)} component={PagePasswordChange} />
              <Route key={Const.ID_ACCOUNT_LOGIN} path={MS.getLinkFromMenuId(Const.ID_ACCOUNT_LOGIN)} component={PageLogin} />
              <Route key={Const.HOME} path={Const.HOME} component={PageLogin} />
            </Switch>
          </>
        )}

        {user && <LeftMenu />}

        {user && (
          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid">
                {RS.renderToastContainer()}
                {user && <BreadCrumb />}
                <Switch>
                  <Route key={Const.ID_EXT_INFO} path={MS.getLinkFromMenuId(Const.ID_EXT_INFO)} component={PageExtInfoIndex} />
                  <Route key={Const.ID_INSURANCES} path={MS.getLinkFromMenuId(Const.ID_INSURANCES)} component={PageInsuranceIndex} />
                  <Route key={Const.ID_ACCOUNT_PROFILE} path={MS.getLinkFromMenuId(Const.ID_ACCOUNT_PROFILE)} component={PageProfileForm} />
                  {/* <Route key={Const.ID_BRANCH_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_BRANCH, Const.CREATE)} component={PageBranchForm} /> */}
                  <Route key={Const.ID_CONTACT} path={MS.getLinkFromMenuId(Const.ID_CONTACT)} component={PageContactForm} />
                  <Route key={Const.ID_FAQ_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_FAQ, Const.CREATE)} component={PageFaqForm} />
                  <Route key={Const.ID_FAQ} path={MS.getLinkFromMenuId(Const.ID_FAQ)} component={PageFaqIndex} />
                  <Route key={Const.ID_ORDINATION_HOUR} path={MS.getLinkFromMenuId(Const.ID_ORDINATION_HOUR)} component={PageOpeningHourForm} />
                  <Route key={Const.ID_TEAM_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_TEAM, Const.CREATE)} component={PageTeamForm} />
                  <Route key={Const.ID_TEAM} path={MS.getLinkFromMenuId(Const.ID_TEAM)} component={PageTeamIndex} />
                  <Route key={Const.ID_BLOG_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_BLOG, Const.CREATE)} component={PageBlogForm} />
                  <Route key={Const.ID_BLOG} path={MS.getLinkFromMenuId(Const.ID_BLOG)} component={PageBlogIndex} />
                  <Route key={Const.ID_URGENT_NOTICE} path={MS.getLinkFromMenuId(Const.ID_URGENT_NOTICE)} component={PageUrgentNoticeForm} />
                  <Route key={Const.ID_ABOUT} path={MS.getLinkFromMenuId(Const.ID_ABOUT)} component={PageAboutForm} />
                  <Route key={Const.ID_SERVICES} path={MS.getLinkFromMenuId(Const.ID_SERVICES)} component={PageServices} />
                  <Route key={Const.ID_GALLERY} path={MS.getLinkFromMenuId(Const.ID_GALLERY)} component={PageGalleryForm} />
                  <Route key={Const.ID_FILES_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_FILES, Const.CREATE)} component={PageFilesForm} />
                  <Route key={Const.ID_FILES} path={MS.getLinkFromMenuId(Const.ID_FILES)} component={PageFilesIndex} />
                  <Route
                    key={Const.ID_OTHER_PAGES_FORM}
                    path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_OTHER_PAGES, Const.CREATE)}
                    component={PageOtherPagesForm}
                  />
                  <Route key={Const.ID_OTHER_PAGES} path={MS.getLinkFromMenuId(Const.ID_OTHER_PAGES)} component={PageOtherPagesIndex} />
                  <Route key={Const.ID_PRICING_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_PRICING, Const.CREATE)} component={PagePriceListForm} />
                  <Route key={Const.ID_PRICING} path={MS.getLinkFromMenuId(Const.ID_PRICING)} component={PagePriceListIndex} />

                  {/* stejne komponenyt pro jine routy jenda pro editaci submenu druha pro menu */}
                  <Route
                    key={Const.ID_MENU_FORM_ADD_SUB_MENU}
                    path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_MENU, Const.CREATE, true)}
                    component={PageMenuForm}
                  />
                  <Route key={Const.ID_MENU_FORM} path={MS.getLinkRouteFromMenuIdWithMethod(Const.ID_MENU, Const.CREATE, false)} component={PageMenuForm} />
                  <Route key={Const.ID_MENU} path={MS.getLinkFromMenuId(Const.ID_MENU)} component={PageMenuIndex} />

                  <Route key={Const.ID_GENERAL_SETTINGS} path={MS.getLinkFromMenuId(Const.ID_GENERAL_SETTINGS)} component={PageGeneralSettingsForm} />

                  <Route key={Const.HOME} path={Const.HOME} component={PageProfileForm} />
                  <Route component={PageOther} />
                </Switch>
              </div>
            </main>

            <footer className="py-4 bg-light mt-auto">
              <div className="container-fluid">
                <div className="text-center">
                  <div className="text-muted">
                    &copy; WebDoOrdinace.cz - {year} <small>verze: {packageJson.version}</small>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default App;
