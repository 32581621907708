import React, { useContext } from "react";
import { AppContext } from "./Context/AppContext";
import * as MS from "./Services/MenuService";
import { Link } from "react-router-dom";

function BreadCrumb(props) {
  const [appContext] = useContext(AppContext);

  const menuItem = MS.getMenuIndexByID(appContext.activeId);
  const subMenuItem = MS.getRouteByID(appContext.activeSubId);
  let hasSubMenu = false;
  let nameText = "";
  let subMenuText;
  if (menuItem) {
    nameText = menuItem.name;
  }

  if (subMenuItem) {
    hasSubMenu = true;
    subMenuText = subMenuItem.name;
  }

  return (
    <ol className="breadcrumb mb-4">
      <li className="breadcrumb-item">
        <Link to="/">Administrace</Link>
      </li>

      {hasSubMenu ? (
        <li className="breadcrumb-item" onClick={() => MS.goToPage(menuItem.id)}>
          <Link to={menuItem.link}>{nameText}</Link>
        </li>
      ) : (
        <li className="breadcrumb-item active">{nameText}</li>
      )}

      {hasSubMenu ? <li className="breadcrumb-item active">{subMenuText}</li> : ""}
    </ol>
  );
}

export default BreadCrumb;
