export const HOME = "/";
export const CREATE = "create";
export const EDIT = "edit";

export const NOT_FOUND = "/not-found";

// export const PARAM_ID = ":id";
// export const PARAM_CITY = ":city";
// export const PARAM_EXPERTISE = ":expertise";

export const ID_ACCOUNT_LOGIN = "account_login";
export const ID_ACCOUNT_REGISTER = "account_register";
export const ID_ACCOUNT_FORGOT_PASSWORD = "account_forgot_password";
export const ID_ACCOUNT_LOGOUT = "account_logont";
export const ID_ACCOUNT_PROFILE = "account_profile";
export const ID_ACCOUNT_PAYMENTS = "account_payments";
export const ID_ACCOUNT_PASSWORD_CHANGE = "account_password_change";

export const ID_BRANCH = "branch";
export const ID_BRANCH_FORM = "branch_create_edit";
export const ID_MAIN_INFO_FORM = "main_info_create_edit";
export const ID_ORDINATION_HOUR = "ordination_hour";
export const ID_URGENT_NOTICE = "urgent_notice";
export const ID_BLOG = "blog";
export const ID_BLOG_FORM = "blog_create_edit";
export const ID_ABOUT = "about";
export const ID_EXT_INFO = "ext_info";
export const ID_TEAM = "team";
export const ID_TEAM_FORM = "team_create_edit";
export const ID_FAQ = "faq";
export const ID_FAQ_FORM = "faq_create_edit";
export const ID_SERVICES = "services";
export const ID_PRICING = "pricing";
export const ID_PRICING_FORM = "pricing_create_edit";

export const ID_FILES = "files";
export const ID_FILES_FORM = "files_create_edit";

export const ID_GALLERY = "gallery";
export const ID_INSURANCES = "insurances";
export const ID_CONTACT = "contact";
export const ID_OTHER_PAGES = "other_pages";
export const ID_OTHER_PAGES_FORM = "other_pages_create_edit";

export const ID_GENERAL_SETTINGS = "general_settings";
export const ID_MENU = "menu";
export const ID_MENU_FORM = "menu_create_edit";
export const ID_MENU_FORM_ADD_SUB_MENU = "menu_create_edit_submenu";

export const REJSTRIK_OBCHODNI = "or";
export const REJSTRIK_ZIVNOSTENSKY = "rzp";

export const DAY_ID_MONDAY = 1;
export const DAY_ID_TUESDAY = 2;
export const DAY_ID_WEDNESDAY = 3;
export const DAY_ID_THURSDAY = 4;
export const DAY_ID_FRIDAY = 5;
export const DAY_ID_SATURDAY = 6;
export const DAY_ID_SUNDAY = 7;

export const MENU_FYZICKA_OSOBA = 1;
export const MENU_PRAVNICKA_OSOBA = 2;

export const MENU_LINK_TYPE_SECTION = 1;
export const MENU_LINK_TYPE_OTHER_PAGE = 2;
export const MENU_LINK_TYPE_URL = 3;
export const MENU_LINK_TYPE_DROPDOWN_MENU = 4;

export function isProduction() {
  return process.env.NODE_ENV === "production" ? true : false;
}

const LOCAL_API_URL = "https://localhost:44394/api";
const PRODUCTION_API_URL = "https://api.webdoordinace.cz/api";
export const API_URL = isProduction() ? PRODUCTION_API_URL : PRODUCTION_API_URL;
